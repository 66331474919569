import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import BrentoBackButton from "../../../../Components/UI/BrentoBackButton";
import CreatePromoCodeForm from "../../../../Components/Admin/CreatePromoCodeForm/CreatePromoCodeForm";
import { createPromoCodeRequest } from "./utils/createPromoCodeRequest";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../../actions/alert";

const CreatePromoCode = () => {
  const [promoCodeCreationSuccessful, setPromoCodeCreationSuccessful] =
    useState(false);
  const [promoCodeData, setPromoCodeData] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const finalizePromoCodeCreation = () => {
    setPromoCodeCreationSuccessful(true);
  };

  useEffect(() => {
    if (promoCodeCreationSuccessful) {
      dispatch(
        setAlert(
          "Promo code created successfully",
          `Promo code ${promoCodeData.discountCode} has been created successfully`,
          "success"
        )
      );
      history.push("/admin/discount");
    }
  });

  return (
    <div className="admin-create-promo-code">
      <div class="admin-create-promo-code__header">
        <BrentoBackButton />
        <h1 className="header-3">Create promo code</h1>
        <CreatePromoCodeForm
          createPromoCodeRequest={createPromoCodeRequest}
          promoCodeCreationSuccessful={promoCodeCreationSuccessful}
          setPromoCodeCreationSuccessful={setPromoCodeCreationSuccessful}
          finalizePromoCodeCreation={finalizePromoCodeCreation}
          promoCodeData={promoCodeData}
          setPromoCodeData={setPromoCodeData}
        />
      </div>
      {/* <CreateGiftCardForm
        createAdminDiscountRequest={createAdminDiscountRequest}
        discountCreationSuccessful={discountCreationSuccessful}
        finalizeDiscountCreation={finalizeDiscountCreation}
        giftCardData={giftCardData}
        setGiftCardData={setGiftCardData}
      /> */}
    </div>
  );
};

export default CreatePromoCode;
