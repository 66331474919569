import React, { useState } from "react";
import { IconButton } from "@mui/material";
import { deleteZipCode } from "../../../../actions/zipCodes";
import { useDispatch } from "react-redux";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import "./ZipCodeTable.scss";

const ZipCodeTable = ({ zipCodes }) => {
  const [textFilter, setTextFilter] = useState(null);
  const [selectedResult, setSelectedResult] = useState(null);

  let zipCodeResults;
  if (textFilter && textFilter.length > 0) {
    zipCodeResults = zipCodes.filter((zipCode) =>
      zipCode.zipCode.includes(textFilter)
    );
  } else {
    zipCodeResults = zipCodes;
  }
  return (
    <div className="zip-code-table">
      <ZipCodeFilter textFilter={textFilter} setTextFilter={setTextFilter} />
      <div className="zip-code-table__results">
        {zipCodes ? (
          <ul>
            {zipCodeResults.map((zipCode) => (
              <ZipCodeResult
                zipCode={zipCode.zipCode}
                id={zipCode._id}
                isSelected={zipCode._id === selectedResult}
                setSelectedResult={setSelectedResult}
              />
            ))}
          </ul>
        ) : (
          <div className="zip-code-table__empty-state">No zip codes found</div>
        )}
      </div>
      <p className="zip-code-table__length">
        Showing <b>{zipCodeResults.length}</b> zip codes
      </p>
    </div>
  );
};

const ZipCodeFilter = ({ textFilter, setTextFilter }) => {
  const handleTextChange = (e) => {
    setTextFilter(e.target.value);
  };
  return (
    <div className="zip-code-table__filter">
      <input
        type="text"
        className="zip-code-table__text-filter"
        placeholder="Search"
        value={textFilter}
        onChange={handleTextChange}
      ></input>
    </div>
  );
};

const ZipCodeResult = ({ zipCode, id, isSelected, setSelectedResult }) => {
  return (
    <li
      onClick={() => setSelectedResult(id)}
      className={`zip-code-table__result zip-code-table__result${
        isSelected ? "--selected" : "--default"
      }`}
    >
      <p>{zipCode}</p>
      {isSelected && <ZipCodeResultControls zipCode={zipCode} id={id} />}
    </li>
  );
};

const ZipCodeResultControls = ({ zipCode, id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  return (
    <div className="zip-clode-list__result-controls">
      {!isOpen ? (
        <IconButton
          onClick={() => setIsOpen(true)}
          aria-label="Delete"
          size="small"
        >
          <DeleteOutlineIcon />
        </IconButton>
      ) : (
        <>
          <IconButton
            onClick={() => dispatch(deleteZipCode(zipCode, id))}
            aria-label="Delete"
            key={`Delete ${id}`}
            size="small"
          >
            <CheckIcon />
          </IconButton>
          <IconButton
            onClick={() => setIsOpen(false)}
            aria-label="Delete"
            size="small"
          >
            <CloseIcon />
          </IconButton>
        </>
      )}
    </div>
  );
};

export default ZipCodeTable;
