import * as yup from "yup";
// import { zipCodeWhitelist } from "../../../../utils/isZipInZone";

const today = new Date();
today.setHours(0, 0, 0, 0);

const customNameSchema = yup.object({
  customDiscountCode: yup
    .string("Enter a unique, custom code for the discount")
    .max(255)
    .required("Custom code required"),
  giftCardAmount: yup
    .number()
    .min(10, "Dollar amount must be at least $10")
    .max(500, "Dollar amount cannot be greater than $500"),
  recipientEmail: yup
    .string("Enter the email address where you want the gift card sent to")
    .email("Must be a valid email")
    .max(255)
    .required("Recipient email address is required"),
  specialMessage: yup
    .string("Enter a special message to be included in the email")
    .max(250, "Special message contains too many characters")
    .required("Special message is required"),
});

const defaultSchema = yup.object({
  customDiscountCode: yup
    .string("Enter a unique, custom code for the discount")
    .max(255),
  giftCardAmount: yup
    .number()
    .min(10, "Dollar amount must be at least $10")
    .max(500, "Dollar amount cannot be greater than $500"),
  recipientEmail: yup
    .string("Enter the email address where you want the gift card sent to")
    .email("Must be a valid email")
    .max(255)
    .required("Recipient email address is required"),
  specialMessage: yup
    .string("Enter a special message to be included in the email")
    .max(250, "Special message contains too many characters")
    .required("Special message is required"),
});

export const validationGenerator = (autoGenerateCode) => {
  console.log("Updating validation schema");
  if (autoGenerateCode) {
    console.log("Autogenerate name");
    return defaultSchema;
  } else {
    console.log("Add custom name");
    return customNameSchema;
  }
};

// export const validationGenerator = (autoGenerateCode) => {
//   if (autoGenerateCode) {
//     return customNameSchema;
//   } else {
//     return defaultSchema;
//   }
// };

// const paymentSchema = yup.object({
//   name: yup
//     .string("Enter your first and last name")
//     .min(4, "Name should be at least 4 characters")
//     .required("Name is required"),
//   email: yup
//     .string("Enter your email address")
//     .email("Must be a valid email")
//     .max(255)
//     .required("Email is required"),
//   phone: yup
//     .string("Enter your phone number")
//     .matches(phoneRegEx, "Invalid phone number")
//     .required("Phone number is required"),
//   addressLine1: yup
//     .string("Enter line one of your address")
//     .required("Address Line 1 is required"),
//   addressLine2: yup.string("Enter line two of your address"),
//   city: yup.string("Enter your city").required("City is required"),
//   state: yup
//     .string("Enter your state")
//     .matches("AZ", "Outside of supported state")
//     .required("State is required"),
//   zipCode: yup
//     .mixed()
//     .oneOf(zipCodeWhitelist, "Outside of delivery zone")
//     .required("ZIP Code is required"),
//   specialInstructions: yup.string("Enter special delivery instructions"),
// });

// const paymentSchema = yup.object({
//   useDifferentBillingAddress: yup.string("Select billing option"),
// });

// const paymentWithBillingSchema = yup.object({
//   billingAddressLine1: yup
//     .string("Enter line one of your address")
//     .required("Address Line 1 is required"),
//   billingAddressLine2: yup.string("Enter line two of your address"),
//   billingCity: yup.string("Enter your city").required("City is required"),
//   billingState: yup.string("Enter your state").required("State is required"),
//   billingZipCode: yup.mixed().required("ZIP Code is required"),
//   specialInstructions: yup.string("Enter special delivery instructions"),
// });
