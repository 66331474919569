import React from "react";

const facebook = () => {
  return (
    <svg className="social-icon--facebook" x="0px" y="0px" viewBox="0 0 72 72">
      <circle cx="36" cy="36" r="36" />
      <path
        d="M35.9,72L35.9,72L35.9,72C35.9,72,35.9,72,35.9,72z M36.1,72L36.1,72L36.1,72L36.1,72z M35.8,72L35.8,72
	L35.8,72L35.8,72z M36.2,72L36.2,72L36.2,72L36.2,72z M35.6,72L35.6,72L35.6,72C35.7,72,35.6,72,35.6,72z M36.3,72L36.3,72L36.3,72
	C36.4,72,36.3,72,36.3,72z M36.5,72L36.5,72L36.5,72C36.5,72,36.5,72,36.5,72z M35.5,72L35.5,72L35.5,72C35.5,72,35.5,72,35.5,72z
	 M36.6,72L36.6,72L36.6,72C36.6,72,36.6,72,36.6,72z M35.4,72L35.4,72L35.4,72C35.4,72,35.4,72,35.4,72z M36.7,72L36.7,72L36.7,72
	L36.7,72z M35.3,72L35.3,72L35.3,72C35.3,72,35.3,72,35.3,72z M35.2,72L35.2,72L35.2,72C35.2,72,35.2,72,35.2,72z M36.8,72L36.8,72
	L36.8,72L36.8,72z M35,72L35,72L35,72L35,72z M36.9,72L36.9,72L36.9,72L36.9,72z M37,72L37,72L37,72C37.1,72,37,72,37,72z M34.9,72
	L34.9,72L34.9,72C35,72,34.9,72,34.9,72z M34.8,72L34.8,72L34.8,72L34.8,72L34.8,72z M37.2,72L37.2,72L37.2,72L37.2,72L37.2,72z
	 M37.3,72L37.3,72L37.3,72L37.3,72z M34.7,72L34.7,72L34.7,72L34.7,72z M34.6,72L34.6,72L34.6,72L34.6,72z M37.4,72L37.4,72L37.4,72
	L37.4,72z M34.5,72L34.5,72L34.5,72C34.5,72,34.5,72,34.5,72z M37.5,72L37.5,72L37.5,72C37.5,72,37.5,72,37.5,72z M34.3,72L34.3,72
	L34.3,72L34.3,72z M37.6,72L37.6,72L37.6,72C37.7,72,37.6,72,37.6,72z M34.2,72L34.2,72L34.2,72L34.2,72z M37.7,72L37.7,72L37.7,72
	L37.7,72z M34.1,72L34.1,72L34.1,72L34.1,72z M37.9,72L37.9,72L37.9,72L37.9,72z M38,71.9L38,71.9L38,71.9L38,71.9
	C38,71.9,38,71.9,38,71.9z M34,71.9L34,71.9L34,71.9L34,71.9C34,71.9,34,71.9,34,71.9z M38.1,71.9L38.1,71.9L38.1,71.9L38.1,71.9z
	 M33.9,71.9L33.9,71.9L33.9,71.9L33.9,71.9z M33.8,71.9L33.8,71.9L33.8,71.9C33.8,71.9,33.8,71.9,33.8,71.9z M38.2,71.9L38.2,71.9
	L38.2,71.9C38.2,71.9,38.2,71.9,38.2,71.9z M33.7,71.9L33.7,71.9L33.7,71.9L33.7,71.9C33.6,71.9,33.7,71.9,33.7,71.9z M38.3,71.9
	L38.3,71.9L38.3,71.9L38.3,71.9C38.4,71.9,38.3,71.9,38.3,71.9z M38.4,71.9L38.4,71.9L38.4,71.9C38.5,71.9,38.4,71.9,38.4,71.9z
	 M33.5,71.9L33.5,71.9L33.5,71.9L33.5,71.9z M33.4,71.9L33.4,71.9L33.4,71.9C33.5,71.9,33.4,71.9,33.4,71.9z M38.5,71.9L38.5,71.9
	L38.5,71.9L38.5,71.9z M33.3,71.9L33.3,71.9L33.3,71.9C33.3,71.9,33.3,71.9,33.3,71.9z M38.7,71.9L38.7,71.9L38.7,71.9
	C38.7,71.9,38.7,71.9,38.7,71.9z M33.2,71.9L33.2,71.9L33.2,71.9L33.2,71.9z M38.8,71.9L38.8,71.9L38.8,71.9L38.8,71.9z M38.9,71.9
	L38.9,71.9L38.9,71.9L38.9,71.9z M33.1,71.9L33.1,71.9L33.1,71.9C33.1,71.9,33.1,71.9,33.1,71.9z M33,71.9L33,71.9L33,71.9L33,71.9z
	 M39,71.9L39,71.9L39,71.9L39,71.9z M32.9,71.9L32.9,71.9L32.9,71.9C32.9,71.9,32.9,71.9,32.9,71.9z M39.1,71.9L39.1,71.9L39.1,71.9
	C39.1,71.9,39.1,71.9,39.1,71.9z M39.2,71.9L39.2,71.9L39.2,71.9C39.2,71.9,39.2,71.9,39.2,71.9z M32.8,71.9L32.8,71.9L32.8,71.9
	C32.8,71.9,32.8,71.9,32.8,71.9z M32.7,71.8L32.7,71.8L32.7,71.8L32.7,71.8z M39.3,71.8L39.3,71.8L39.3,71.8L39.3,71.8z M30.4,71.6
	C13.2,68.9,0,54,0,36C0,16.1,16.1,0,36,0s36,16.1,36,36c0,18-13.2,32.9-30.4,35.6V46.4H50L51.6,36h-10v-6.8c0-2.8,1.4-5.6,5.9-5.6
	H52v-8.9c0,0-4.1-0.7-8.1-0.7c-8.2,0-13.6,5-13.6,14V36h-9.1v10.4h9.1V71.6z"
      />
    </svg>
  );
};

export default facebook;
