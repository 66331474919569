import React from "react";
import { useDispatch } from "react-redux";
import BrentoSelect from "../../../UI/BrentoSelect/BrentoSelect";
import {
  setAnalyticsFilterBy,
  setAnalyticsBreakdownBy,
} from "../../../../actions/analytics";
import moment from "moment";
import "./AnalyticsFilter.scss";

const AnalyticsFilter = ({ filterBy, analyticsData, dateValues }) => {
  return (
    <div className="analytics-filter">
      <FilterControls filterBy={filterBy} analyticsData={analyticsData} />
      <FilterHeader filterBy={filterBy} dateValues={dateValues} />
    </div>
  );
};

const FilterHeader = ({ filterBy, dateValues }) => {
  return (
    <div className="analytics-filter__header">
      {filterBy === "all-time" ? (
        <h2 className="header-4">All time</h2>
      ) : filterBy === "this-year" ? (
        <h2 className="header-4">{dateValues.current.year}</h2>
      ) : filterBy === "this-quarter" ? (
        <h2 className="header-4">{`${dateValues.current.quarter} - ${dateValues.current.year}`}</h2>
      ) : (
        <h2 className="header-4">
          {`${moment(dateValues.current.month, "MM").format("MMMM")} - ${
            dateValues.current.year
          }`}
        </h2>
      )}
    </div>
  );
};

const FilterControls = ({ filterBy, setBreakdownBy }) => {
  const dispatch = useDispatch();

  const dropdownOptions = [
    { label: "All Time", value: "all-time" },
    { label: "This Year", value: "this-year" },
    { label: "This Quarter", value: "this-quarter" },
    { label: "This Month", value: "this-month" },
  ];

  const handleFilterBySelect = (event) => {
    if (event.target.value === "all-time") {
      dispatch(setAnalyticsBreakdownBy("year"));
    } else if (event.target.value === "this-year") {
      dispatch(setAnalyticsBreakdownBy("quarter"));
    } else if (event.target.value === "this-quarter") {
      dispatch(setAnalyticsBreakdownBy("month"));
    } else {
      dispatch(setAnalyticsBreakdownBy("window"));
    }
    dispatch(setAnalyticsFilterBy(event.target.value));
  };

  return (
    <div className="analytics-filter__controls">
      <BrentoSelect
        value={filterBy}
        label="Show reporting for"
        variant="filled"
        options={dropdownOptions}
        onChange={handleFilterBySelect}
      />
    </div>
  );
};

export default AnalyticsFilter;
