import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPromoCodeAdmin } from "../../../actions/promoCodes";
import { SET_PROMO_CODE_DATA_LOADING } from "../../../actions/types";
import BrentoBackButton from "../../../Components/UI/BrentoBackButton";
import PromoCodeDetailsHero from "../../../Components/Admin/PromoCodeDetails/PromoCodeDetailsHero/PromoCodeDetailsHero";
import PromoCodeDetailsTable from "../../../Components/Admin/PromoCodeDetails/PromoCodeDetailsTable/PromoCodeDetailsTable";
import PromoCodeEmailList from "../../../Components/Admin/PromoCodeDetails/PromoCodeEmailList/PromoCodeEmailList";
import { variables } from "../../../variables/variables";
import "./PromoCodeDetails.scss";

const PromoCodeDetails = (props) => {
  const dispatch = useDispatch();
  const testMode = useSelector((state) => state.admin.testMode);
  const promoCodeData = useSelector((state) => state.promoCodes.promoCodeData);
  const promoCodeDataLoading = useSelector(
    (state) => state.promoCodes.promoCodeDataLoading
  );
  const isMobile = useSelector(
    (state) => state.layout.displayWidth < variables.breakpoints.lrg
  );

  const {
    match: { params },
  } = props;

  useEffect(() => {
    dispatch({ type: SET_PROMO_CODE_DATA_LOADING, payload: true });
    dispatch(getPromoCodeAdmin(testMode, params.id));
  }, [dispatch, params.id, testMode]);

  return (
    <div>
      <BrentoBackButton />
      {promoCodeDataLoading ? (
        <p>Loading...</p>
      ) : promoCodeData ? (
        <>
          <PromoCodeDetailsHero
            discountCode={promoCodeData.discountCode}
            promoCodeId={promoCodeData._id}
          />
          <PromoCodeDetailsTable promoCodeData={promoCodeData} />
          <PromoCodeEmailList
            emailList={promoCodeData.emailList}
            tableHeader={
              promoCodeData.discountAudience === "team-member-vendor"
                ? "Approved Email List"
                : "Customer emails"
            }
            isMobile={isMobile}
          />
        </>
      ) : (
        <p>No promo code found</p>
      )}
    </div>
  );
};

export default PromoCodeDetails;
