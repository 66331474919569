import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 1,
    letterSpacing: 2,
    padding: 8,
    paddingLeft: 32,
    marginBottom: 16,

    "&::before": {
      content: "' '",
      width: 8,
      height: 8,
      borderLeftWidth: 2,
      borderLeftStyle: "solid",
      borderLeftColor: theme.primary,
      borderBottomWidth: 2,
      borderBottomStyle: "solid",
      borderBottomColor: theme.primary,
      position: "absolute",
      transition: "left .125s ease-in-out",
      transform: "rotate(45deg)",
      top: 15,
      left: 16,
      opacity: "60%",
    },
    "&:hover": {
      "&::before": {
        left: 12,
        opacity: "100%",
      },
    },
  },
  outlined: {
    borderWidth: 2,
    "&:hover": {
      borderWidth: 2,
      backgroundColor: "transparent",
    },
  },
}));

const BrentoBackButton = () => {
  const classes = useStyles();
  let history = useHistory();
  return (
    <Button
      classes={{ root: classes.root, outlined: classes.outlined }}
      onClick={() => history.goBack()}
      variant="outlined"
      color="primary"
    >
      Back
    </Button>
  );
};

export default BrentoBackButton;
