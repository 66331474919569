import {
  GET_DISPLAY_WIDTH,
  SET_DRAWER_OPEN,
  SET_DRAWER_COMPONENT,
  SET_MODAL_OPEN,
  SET_MODAL_COMPONENT,
} from "./types";

// Get the full menu from the database
export const getDisplayWidth = () => (dispatch) => {
  try {
    const displayWidth = window.innerWidth;
    dispatch({
      type: GET_DISPLAY_WIDTH,
      payload: displayWidth,
    });
  } catch (err) {
    console.log(err);
  }
};

// Get the full menu from the database
export const setDrawerOpen = (drawerIsOpen) => (dispatch) => {
  try {
    dispatch({
      type: SET_DRAWER_OPEN,
      payload: drawerIsOpen,
    });
  } catch (err) {
    console.log(err);
  }
};

// Get the full menu from the database
export const setDrawerComponent = (drawerComponentId) => (dispatch) => {
  try {
    dispatch({
      type: SET_DRAWER_COMPONENT,
      payload: drawerComponentId,
    });
  } catch (err) {
    console.log(err);
  }
};

// Get the full menu from the database
export const setModalOpen = (modalIsOpen) => (dispatch) => {
  try {
    dispatch({
      type: SET_MODAL_OPEN,
      payload: modalIsOpen,
    });
  } catch (err) {
    console.log(err);
  }
};

// Get the full menu from the database
export const setModalComponent =
  (modalComponentId, modalComponentData) => (dispatch) => {
    try {
      dispatch({
        type: SET_MODAL_COMPONENT,
        payload: { id: modalComponentId, data: modalComponentData },
      });
    } catch (err) {
      console.log(err);
    }
  };
