const mapCategories = (meal, itemObject) => {
  let categories = [];
  if (meal.mealIsNew) {
    categories.push("New");
  }
  if (meal.mealIsVegetarian) {
    categories.push("Vegetarian");
  }
  if (meal.mealIsGlutenFree) {
    categories.push("Gluten-Free");
  }
  categories.forEach((category, index) => {
    itemObject[`item_category${index + 2}`] = category;
  });
};

export const formatLineItem = (meal, qty, index) => {
  let itemObject = {
    item_id: meal._id,
    item_name: meal.mealName,
    index: index,
    item_category: meal.category,
    // item_list_id: "related_products",
    // item_list_name: "Related Products",
    // item_variant: "green",
    price: meal.price,
    quantity: qty,
  };

  mapCategories(meal, itemObject);

  return itemObject;
};
