import React from "react";
import "../CheckoutForm/CheckoutForm.scss";

const CheckoutFieldset = ({ children, label }) => {
  return (
    <div className="checkout-form__fieldset">
      {label ? (
        <h2 className="checkout-form__fieldset-label">{label}</h2>
      ) : null}
      {children}
    </div>
  );
};

export default CheckoutFieldset;
