import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../../actions/auth";
import logo from "../../../img/logo.svg";
import logoGlyph from "../../../img/logo-glyph.svg";
import Container from "../Container";
import { Drawer, IconButton } from "@mui/material";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ShoppingCartFilledIcon from "@mui/icons-material/ShoppingCartTwoTone";
import { variables } from "../../../variables/variables";
import "./Navbar.scss";

const Navbar = () => {
  const displayWidth = useSelector((state) => state.layout.displayWidth);
  const cartIndicatorActive = useSelector(
    (state) => state.cart.cartIndicatorActive
  );
  const isMobile = displayWidth < variables.breakpoints.med;
  const cartItems = useSelector((state) => state.cart.cartItems);

  return (
    <header className="navbar">
      <Container>
        <nav>
          <Link to="/">
            <img src={logo} alt="Brento Box Logo" className="logo" />
            <img src={logoGlyph} alt="Brento Box Logo" className="logo-glyph" />
          </Link>
          {isMobile ? (
            <MobileNav
              cartItems={cartItems}
              isMobile={isMobile}
              cartIndicatorActive={cartIndicatorActive}
            />
          ) : (
            <DesktopNav
              cartItems={cartItems}
              isMobile={isMobile}
              cartIndicatorActive={cartIndicatorActive}
            />
          )}
        </nav>
      </Container>
    </header>
  );
};

const DesktopNav = ({ cartItems, isMobile, cartIndicatorActive }) => {
  return (
    <div className="navbar__nav-links--desktop">
      <NavLink to="/menu" className="navbar__nav-link">
        Menu
      </NavLink>
      <NavLink to="/about" className="navbar__nav-link">
        About
      </NavLink>
      <NavLink to="/faqs" className="navbar__nav-link">
        FAQs
      </NavLink>
      <NavLink to="/contact" className="navbar__nav-link">
        Contact
      </NavLink>
      <ShoppingCartLink
        isMobile={isMobile}
        cartItemCount={cartItems.length}
        cartIndicatorActive={cartIndicatorActive}
      />
    </div>
  );
};

const MobileNav = ({
  cartItems,
  isMobile,
  cartIndicatorActive,
  forceClose = false,
}) => {
  const [isOpen, setOpen] = useState(false);

  const handleToggleDrawer = () => {
    setOpen(!isOpen);
  };

  if (forceClose) {
    setOpen(false);
  }

  return (
    <>
      <div className="navbar__mobile-controls">
        <ShoppingCartLink
          isMobile={isMobile}
          cartItemCount={cartItems.length}
          setOpen={setOpen}
          cartIndicatorActive={cartIndicatorActive}
        />
        <button
          className="navbar__mobile-toggle"
          onClick={() => handleToggleDrawer()}
        >
          {isOpen ? <CloseIcon /> : <MenuIcon />}
        </button>
      </div>
      <Drawer
        anchor={"right"}
        open={isOpen}
        onClose={handleToggleDrawer}
        style={{ zIndex: 1250 }}
      >
        <nav className="navbar__nav-links--mobile">
          <NavLink
            to="/"
            className="navbar__nav-link"
            activeClassName="navbar__nav-link--active"
            onClick={() => handleToggleDrawer()}
            exact
          >
            Home
          </NavLink>
          <NavLink
            to="/menu"
            className="navbar__nav-link"
            activeClassName="navbar__nav-link--active"
            onClick={() => handleToggleDrawer()}
          >
            Menu
          </NavLink>
          <NavLink
            to="/about"
            className="navbar__nav-link"
            activeClassName="navbar__nav-link--active"
            onClick={() => handleToggleDrawer()}
            exact
          >
            About
          </NavLink>
          <NavLink
            to="/faqs"
            className="navbar__nav-link"
            activeClassName="navbar__nav-link--active"
            onClick={() => handleToggleDrawer()}
            exact
          >
            FAQs
          </NavLink>
          <NavLink
            to="/contact"
            className="navbar__nav-link"
            activeClassName="navbar__nav-link--active"
            onClick={() => handleToggleDrawer()}
            exact
          >
            Contact
          </NavLink>
        </nav>
      </Drawer>
    </>
  );
};

const ShoppingCartLink = ({
  isMobile,
  cartItemCount,
  setOpen,
  cartIndicatorActive,
}) => {
  return (
    <NavLink
      to="/cart"
      className="navbar__nav-link-cart"
      activeClassName="navbar__nav-link-cart--active"
      onClick={() => (isMobile ? setOpen(false) : null)}
      exact
    >
      <div
        className={`navbar__nav-link-cart-indicator navbar__nav-link-cart-indicator--${
          cartItemCount <= 0
            ? "empty"
            : cartIndicatorActive
            ? "active"
            : "inactive"
        }`}
      >
        <p className="navbar__nav-link-cart-indicator__value">
          {cartItemCount}
        </p>
      </div>

      <IconButton size="large">
        {cartItemCount <= 0 ? (
          <ShoppingCartOutlinedIcon
            fontSize={isMobile ? "default" : "large"}
            color="primary"
          />
        ) : (
          <ShoppingCartFilledIcon
            fontSize={isMobile ? "default" : "large"}
            color="primary"
          />
        )}
      </IconButton>
    </NavLink>
  );
};

const MenuIcon = () => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 40 40">
      <g>
        <rect x="5" y="18" width="30" height="4" />
      </g>
      <g>
        <rect x="5" y="8" width="30" height="4" />
      </g>
      <g>
        <rect x="5" y="28" width="30" height="4" />
      </g>
    </svg>
  );
};

const CloseIcon = () => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 40 40">
      <g>
        <rect
          x="18"
          y="5"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -8.2843 20)"
          width="4"
          height="30"
        />
      </g>
      <g>
        <rect
          x="5"
          y="18"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -8.2843 20)"
          width="30"
          height="4"
        />
      </g>
    </svg>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Navbar);
