import React, { useState, useEffect } from "react";
import "./CartSummary.scss";
import BrentoButton from "../UI/BrentoButton";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Alert from "../UI/Alert/Alert";
import { Link } from "react-router-dom";
import Collapsible from "../UI/Collapsible/Collapsible";
import ExpressCheckout from "../Checkout/ExpressCheckout/ExpressCheckout";
import ApplyPromoCode from "./ApplyPromoCode/ApplyPromoCode";

const CartSummary = ({ cartItems }) => {
  const [subtotal, setSubtotal] = useState(0);
  const [processing, setProcessing] = useState(0);
  const [total, setTotal] = useState(0);
  const [discountApplied, setDiscountApplied] = useState(0);
  const [totalAfterDiscount, setTotalAfterDiscount] = useState(0);
  const [minimumFeeCharged, setMinimumFeeCharged] = useState(false);

  const cartDiscountCode = useSelector((state) => state.cart.cartDiscountCode);

  const calculateSubtotal = (itemsInCart) => {
    let totalAmount = 0;
    itemsInCart.forEach((item) => {
      totalAmount += item.meal.price * item.qty;
    });
    setSubtotal(totalAmount);
    calculateProcessing(totalAmount);
  };

  const calculateProcessing = (subtotalAmount) => {
    const taxAmount = (subtotalAmount / 100) * 2.9;
    setProcessing(Math.round((taxAmount + Number.EPSILON) * 100) / 100);
  };

  const calculateTotal = (subtotalAmount, processingAmount) => {
    setTotal(subtotalAmount + 5 + processingAmount);
  };

  const calculateDiscountApplied = (totalAmount, discountData) => {
    // Apply discount for Gift Card
    if (discountData.discountCategory === "gift-card") {
      const { currentBalance } = discountData;
      let discountBalanceDollars = currentBalance / 100;
      if (discountBalanceDollars >= totalAmount) {
        setDiscountApplied(totalAmount);
        setTotalAfterDiscount(0.5);
        setMinimumFeeCharged(true);
      } else {
        setDiscountApplied(discountBalanceDollars);
        setTotalAfterDiscount(totalAmount - discountBalanceDollars);
        setMinimumFeeCharged(false);
      }
    } else {
      // Apply discount for Promo Code
    }
  };

  // const calculateTotalAfterDiscount = (totalAmount, discountAmount) => {
  //   let discountAmountDollars;
  //   if (discountAmount > totalAmount) {
  //     setTotalAfterDiscount(0.5);
  //   } else {
  //     discountAmountDollars = discountAmount / 100;
  //     setTotalAfterDiscount(totalAmount - discountAmountDollars);
  //   }
  // };

  useEffect(() => {
    calculateSubtotal(cartItems);
    calculateTotal(subtotal, processing);
  });

  useEffect(() => {
    if (cartDiscountCode) {
      calculateDiscountApplied(total, cartDiscountCode);
    }
  }, [cartDiscountCode, total]);

  return (
    <div className="cart-header">
      <div className="cart-header__core">
        <div
          className={`${
            subtotal < 40
              ? "cart-header__line-item cart-header__line-item--error"
              : "cart-header__line-item"
          }`}
        >
          <p className="cart-header__line-item-label">Subtotal:</p>
          <span className="cart-header__line-item-divider"></span>
          <p className="cart-header__line-item-value">{`$${
            isNaN(subtotal) ? "--" : subtotal.toFixed(2)
          }`}</p>
        </div>
        <div
          className={`${
            subtotal < 40
              ? "cart-header__line-item"
              : "cart-header__line-item cart-header__line-item--addon"
          }`}
        >
          <p className="cart-header__line-item-label">Delivery:</p>
          <span className="cart-header__line-item-divider"></span>
          <p className="cart-header__line-item-value">{`${
            subtotal < 40 || isNaN(subtotal) ? "––" : "+ $5.00"
          }`}</p>
        </div>
        <div
          className={`${
            subtotal < 40
              ? "cart-header__line-item"
              : "cart-header__line-item cart-header__line-item--addon"
          }`}
        >
          <p className="cart-header__line-item-label">Processing fees:</p>
          <span className="cart-header__line-item-divider"></span>
          <p className="cart-header__line-item-value">{`${
            subtotal < 40 || isNaN(subtotal)
              ? "––"
              : `+ $${processing.toFixed(2)}`
          }`}</p>
        </div>
        <div
          className={`cart-header__total ${
            cartDiscountCode && "cart-header__total--discount"
          }`}
        >
          <p className="cart-header__total-label">Total:</p>
          <span className="cart-header__total-divider"></span>
          <p className="display-price">{`${
            subtotal < 40 || isNaN(subtotal) ? "––" : `$${total.toFixed(2)}`
          }`}</p>
        </div>

        {
          // Discount code applied
          cartDiscountCode && (
            <div
              className={`${
                subtotal < 40
                  ? "cart-header__line-item"
                  : "cart-header__line-item cart-header__line-item--addon"
              }`}
            >
              <p className="cart-header__line-item-label">
                {cartDiscountCode.discountCategory === "gift-card"
                  ? `Digital Gift Card (${cartDiscountCode.discountCode}):`
                  : `Promo Code (${cartDiscountCode.discountCode})`}
              </p>
              <span className="cart-header__line-item-divider"></span>
              <p className="cart-header__line-item-value">{`${
                subtotal < 40 ? "––" : `- $${discountApplied.toFixed(2)}`
              }`}</p>
            </div>
          )
        }
        {
          // Discount code applied and minimum fee charged
          cartDiscountCode && minimumFeeCharged && (
            <div
              className={`${
                subtotal < 40
                  ? "cart-header__line-item"
                  : "cart-header__line-item cart-header__line-item--warn"
              }`}
            >
              <p className="cart-header__line-item-label">
                Minimum processing amount:
              </p>
              <span className="cart-header__line-item-divider"></span>
              <p className="cart-header__line-item-value">{`${
                subtotal < 40 ? "––" : "+ $0.50"
              }`}</p>
            </div>
          )
        }
        {cartDiscountCode && (
          <div className="cart-header__total">
            <p className="cart-header__total-label">Total after discount:</p>
            <span className="cart-header__total-divider"></span>
            <p className="display-price">{`${
              subtotal < 40 || isNaN(subtotal)
                ? "––"
                : `$${totalAfterDiscount.toFixed(2)}`
            }`}</p>
          </div>
        )}

        {/* <ApplyPromoCode
          cartTotal={total}
          totalAfterDiscount={totalAfterDiscount}
          discountApplied={discountApplied}
          minimumFeeCharged={minimumFeeCharged}
        /> */}
        <BrentoButton
          variant="contained"
          component={Link}
          to="/payment/checkout"
          color="primary"
          fullWidth={true}
          disabled={subtotal < 40}
          key={subtotal}
        >
          Checkout
        </BrentoButton>
        {cartDiscountCode ? null : (
          <ExpressCheckout
            cartItems={cartItems}
            total={total}
            subtotal={subtotal}
            processing={processing}
            disabled={subtotal < 40}
          />
        )}
        <Collapsible
          isExpanded={subtotal < 40}
          timeout={500}
          unMountOnCollapse={true}
        >
          <Alert
            type="error"
            header="Sorry, $40 order minimum not met"
            content={
              <>
                <p>
                  All order subtotals must meet a $40 minimum. Increase the
                  quantities in your cart, or add more meals from the menu.
                </p>
                <Link to="/menu">Go to menu</Link>
              </>
            }
          />
        </Collapsible>
      </div>
    </div>
  );
};

export default CartSummary;
