import ReactGA from "react-ga4";
import { formatAllLineItems } from "./utils/formatAllLineItems";

export const trackAddShippingInfo = (cartItems, total, discountCode) => {
  let eventArgs = {
    currency: "USD",
    value: total / 100,
    // payment_type: "Credit Card",
    items: formatAllLineItems(cartItems),
  };

  if (discountCode) {
    eventArgs.coupon = discountCode.discountCode;
  }
  ReactGA.gtag("event", "add_shipping_info", eventArgs);
};

// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=10902621433338295728-NA&client_type=gtag#parameters_2
