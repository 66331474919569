export const loadState = () => {
  // try {
  //   const serializedState = localStorage.getItem("cart");
  //   if (serializedState === null) {
  //     return undefined;
  //   }
  //   return JSON.parse(serializedState);
  // } catch (err) {
  //   console.log(err);
  //   return undefined;
  // }
};

export const saveState = (item, state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(item, serializedState);
  } catch {
    // Ignore write errors
  }
};
