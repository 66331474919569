import React from "react";
import "./Hero.scss";
import Container from "../../Layout/Container";
import BrentoButton from "../../UI/BrentoButton";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const Hero = () => {
  const history = useHistory();
  const displayWidth = useSelector((state) => state.layout.displayWidth);

  const handleGoToMenu = () => {
    history.push("/menu");
  };
  return (
    <section className="hero">
      <Container>
        <div className="hero__text">
          <h2 className="eyebrow-1">Brento Box</h2>
          <h1 className="header-1">The Best Meals You've Never Made.</h1>
          <p className="hero__description">
            <span className="font-bold">Brento Box</span> provides fresh,
            delicious and nutritious meals for you all week long. No more messy
            kitchens or counting macros.
          </p>
          <BrentoButton
            size="large"
            onClick={() => handleGoToMenu()}
            fullWidth={displayWidth < 500}
          >
            View the menu
          </BrentoButton>
        </div>
      </Container>
    </section>
  );
};

export default Hero;
