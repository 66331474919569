import React, { useState, useEffect } from "react";
import "./CreateGiftCardForm.scss";
import { useSelector } from "react-redux";
import { variables } from "../../../variables/variables";
import { useFormik } from "formik";
import { allErrorsTouched } from "../../Checkout/CheckoutSteps/utils/allErrorsTouched";
import { validationGenerator } from "./Validation";
import SelectAmountFields from "./fields/SelectAmountFields";
import DiscountNameFields from "./fields/DiscountNameFields";
import RecipientInfoFields from "./fields/RecipientInfoFields";
import BrentoLoadingButton from "../../UI/BrentoLoadingButton";
import Alert from "../../UI/Alert/Alert";

const CreateGiftCardForm = ({
  createAdminDiscountRequest,
  discountCreationSuccessful,
  finalizeDiscountCreation,
  giftCardData,
  setGiftCardData,
}) => {
  const [customGiftCardAmount, setCustomGiftCardAmount] = useState(false);
  const [autoGenerateCode, setAutoGenerateCode] = useState(true);
  const [customDiscountError, setCustomDiscountError] = useState(false);
  const [submitPending, setSubmitPending] = useState(false);
  const testMode = useSelector((state) => state.admin.testMode);
  const isMobile = useSelector(
    (state) => state.layout.displayWidth < variables.breakpoints.lrg
  );
  let validationSchema = validationGenerator(autoGenerateCode);

  useEffect(() => {
    console.log(giftCardData);
  });

  const formik = useFormik({
    initialValues: {
      customDiscountCode: "",
      giftCardAmount: 25,
      recipientEmail: "",
      specialMessage: "Enjoy this gift card from the Brento Box crew!",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("Submitting...");
      setSubmitPending(true);
      try {
        handleFullSubmit(values);
      } catch (err) {
        console.log(err);
      }
    },
  });

  const errorKeys = Object.keys(formik.errors);

  const handlePreSubmit = () => {
    formik.setTouched({
      customDiscountCode: true,
      giftCardAmount: true,
      recipientEmail: true,
      specialMessage: true,
    });
    formik.handleSubmit();
  };

  const handleFullSubmit = async (formValues) => {
    formValues.customDiscountCode =
      formValues.customDiscountCode.toLocaleUpperCase();
    let giftCardResponse = await createAdminDiscountRequest(testMode, {
      ...formValues,
      autoGenerateCode: autoGenerateCode,
    });
    console.log(giftCardResponse);
    if (
      giftCardResponse &&
      giftCardResponse.response &&
      giftCardResponse.response.data.errors.length > 0
    ) {
      setSubmitPending(false);
      setCustomDiscountError(giftCardResponse.response.data.errors);
      setGiftCardData(null);
      return giftCardResponse;
    } else {
      setSubmitPending(false);
      setCustomDiscountError(null);
      setGiftCardData(giftCardResponse);
      finalizeDiscountCreation(giftCardResponse);
      return giftCardResponse;
    }
  };

  const handleSetFixedAmount = (amount) => {
    setCustomGiftCardAmount(false);
    formik.setFieldValue("giftCardAmount", amount);
  };

  const handleSelectAutoGenerate = (value) => {
    setAutoGenerateCode(value);
  };

  return (
    <form className="create-gift-card-form" onSubmit={formik.handleSubmit}>
      <DiscountNameFields
        isMobile={isMobile}
        handleSelectAutoGenerate={handleSelectAutoGenerate}
        formik={formik}
        autoGenerateCode={autoGenerateCode}
        customDiscountError={customDiscountError}
      />
      <SelectAmountFields
        isMobile={isMobile}
        customGiftCardAmount={customGiftCardAmount}
        setCustomGiftCardAmount={setCustomGiftCardAmount}
        handleSetFixedAmount={handleSetFixedAmount}
        formik={formik}
      />
      <RecipientInfoFields formik={formik} />
      {(allErrorsTouched(errorKeys, formik.touched) && errorKeys.length > 0) ||
      (customDiscountError && customDiscountError.length > 0) ? (
        <Alert
          header="We have found the following errors:"
          type="error"
          content={
            <ul>
              {Object.keys(formik.errors)
                .filter((key) => {
                  return formik.touched[key] === true;
                })
                .map((key) => {
                  return (
                    <li key={key} className="checkout-alert-error">
                      {formik.errors[key]}
                    </li>
                  );
                })}
              {customDiscountError
                ? customDiscountError.map((error) => {
                    return (
                      <li key={error.msg} className="checkout-alert-error">
                        {error.msg}
                      </li>
                    );
                  })
                : null}
            </ul>
          }
        />
      ) : null}
      <BrentoLoadingButton pending={submitPending} onClick={handlePreSubmit}>
        Create Gift Card Code
      </BrentoLoadingButton>
    </form>
  );
};

export default CreateGiftCardForm;
