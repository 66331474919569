import React from "react";
import "./AnalyticsBreakdown.scss";
import AnalyticsTable from "../AnalyticsTable/AnalyticsTable";
import { getSubDataNodes } from "../utils/getSubDataNodes";
import { setAnalyticsBreakdownBy } from "../../../../actions/analytics";
import { useDispatch, useSelector } from "react-redux";

const AnalyticsBreakdown = ({ analyticsData, filterBy, dateValues }) => {
  const breakdownBy = useSelector(
    (state) => state.analytics.analyticsBreakdownBy
  );
  const dataNodes = getSubDataNodes(analyticsData, filterBy, dateValues);
  const breakdownOptions = Object.keys(dataNodes);

  return (
    <div className="analytics-breakdown">
      <div className="analytics-breakdown__breakdown-select">
        {breakdownOptions.map((option) => {
          return (
            <BreakdownOption
              key={`${option}-${filterBy}`}
              option={option}
              breakdownBy={breakdownBy}
            />
          );
        })}
      </div>
      <AnalyticsTable
        analyticsData={analyticsData}
        filterBy={filterBy}
        breakdownBy={breakdownBy}
        dateValues={dateValues}
        dataNodes={dataNodes}
      />
    </div>
  );
};

const BreakdownOption = ({ option, breakdownBy, setBreakdownBy }) => {
  const dispatch = useDispatch();

  const handleSetBreakdown = () => {
    dispatch(setAnalyticsBreakdownBy(option));
  };
  return (
    <button
      className={`analytics-breakdown__breakdown-option analytics-breakdown__breakdown-option--${
        option === breakdownBy ? "active" : "inactive"
      }`}
      onClick={() => handleSetBreakdown()}
    >
      {option}
    </button>
  );
};

export default AnalyticsBreakdown;
