import React from "react";
import ScrollToTop from "../../Components/Layout/ScrollToTop";
import SupportingHero from "../../Components/SupportingHero/SupportingHero";
import ContactHero from "../../img/hero3.jpg";
import ContactContent from "../../Components/Contact/ContactContent/ContactContent";
import Container from "../../Components/Layout/Container";
import "./Contact.scss";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://getbrentobox.com/contact" />
        <title>Contact Us | Brento Box - Fresh Meal Prep Made Easy</title>
        <meta
          name="description"
          content="Questions or concerns? Brento Box would love to hear from you!"
        />
      </Helmet>
      <div className="contact-page">
        <ScrollToTop />
        <SupportingHero
          title="Contact Us"
          backgroundImage={`url(${ContactHero})`}
        />
        <Container>
          <div className="contact-content">
            <div className="contact-content__title">
              <p className="body-1">
                If you're looking to get in touch, we're just one phone call,
                text, or email away. If you'd like to keep up to date with the
                latest from Brento Box, follow us on instagram!
              </p>
            </div>
            <div className="contact-content__main">
              <ContactContent />
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Contact;
