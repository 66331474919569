const getQuarter = (month) => {
  if (month === "01" || month === "02" || month === "03") {
    return "Q1";
  } else if (month === "04" || month === "05" || month === "06") {
    return "Q2";
  } else if (month === "07" || month === "08" || month === "09") {
    return "Q3";
  } else {
    return "Q4";
  }
};

const getPriorQuarter = (quarter) => {
  switch (quarter) {
    case "Q1":
      return "Q4";
    case "Q2":
      return "Q1";
    case "Q3":
      return "Q2";
    case "Q4":
      return "Q3";
    default:
      return false;
  }
};

const getPriorYear = () => {
  let today = new Date();
  return `${today.getFullYear() - 1}`;
};

const getPriorMonth = () => {
  let today = new Date();
  let monthInt = today.getMonth();
  let priorMonth;
  if (monthInt === 0) {
    priorMonth = "12";
  } else {
    priorMonth = `${monthInt}`;
  }
  if (priorMonth.length === 1) {
    return `0${priorMonth}`;
  } else {
    return priorMonth;
  }
};

export const getDateValues = () => {
  let dateValues = { prior: {}, current: {} };
  let today = new Date();
  let todayString = today.toISOString().split("T")[0];
  dateValues.current.year = todayString.split("-")[0];
  dateValues.current.month = todayString.split("-")[1];
  dateValues.current.quarter = getQuarter(todayString.split("-")[1]);
  dateValues.prior.year = getPriorYear();
  dateValues.prior.quarter = getPriorQuarter(dateValues.current.quarter);
  dateValues.prior.month = getPriorMonth();
  return dateValues;
};
