import React from "react";
import SupportingHero from "../../Components/SupportingHero/SupportingHero";
import AboutHero from "../../img/hero3.jpg";
import AboutContent from "../../Components/About/AboutContent/AboutContent";
import Container from "../../Components/Layout/Container";
import ScrollToTop from "../../Components/Layout/ScrollToTop";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://getbrentobox.com/about" />
        <title>About Us | Brento Box - Fresh Meal Prep Made Easy</title>
        <meta
          name="description"
          content="We're proud to provide Phoenix, Arizona with our meal prep services. This is our story."
        />
      </Helmet>
      <div>
        <ScrollToTop />
        <SupportingHero title="About" backgroundImage={`url(${AboutHero})`} />
        <Container>
          <AboutContent />
        </Container>
      </div>
    </>
  );
};

export default About;
