import React from "react";
import "./PromoCodeDetailsTable.scss";

const PromoCodeDetailsTable = ({ promoCodeData }) => {
  return (
    <div className="promo-code-details-table">
      <div className="promo-code-details-table__row">
        <p className="promo-code-details-table__key">Status:</p>
        <p>{promoCodeData.isActive ? "Active" : "Inactive"}</p>
      </div>
      <div className="promo-code-details-table__row">
        <p className="promo-code-details-table__key">Audience:</p>
        <p>
          {promoCodeData.discountAudience === "customer"
            ? "Customer"
            : "Team member or vendor"}
        </p>
      </div>
      <div className="promo-code-details-table__row">
        <p className="promo-code-details-table__key">Max number of uses:</p>
        <p>
          {promoCodeData.usageType === "unlimited-use"
            ? "Unlimited"
            : promoCodeData.maxUseCount}
        </p>
      </div>
      <div className="promo-code-details-table__row">
        <p className="promo-code-details-table__key">Benefits:</p>
        <div className="promo-code-details-table__benefits-list">
          {promoCodeData.isPercentOff && (
            <div className="promo-code-details-table__benefit">
              <p>{`${promoCodeData.percentOff}% off`}</p>
            </div>
          )}
          {promoCodeData.isFreeShipping && (
            <div className="promo-code-details-table__benefit">
              <p>Free shipping</p>
            </div>
          )}
          {promoCodeData.isFreeProcessing && (
            <div className="promo-code-details-table__benefit">
              <p>Free processing</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PromoCodeDetailsTable;
