import React, { useState } from "react";
import "./CartItem.scss";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BrentoStepper from "../UI/Stepper/BrentoStepper";
import { removeFromCart, changeCartQuantity } from "../../actions/cart";
import { setModalOpen, setModalComponent } from "../../actions/layout";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import DisplayPrice from "../UI/DisplayPrice/DisplayPrice";
import { trackRemoveFromCart } from "../../tracking/trackRemoveFromCart";

const CartItem = ({ meal, quantity }) => {
  const dispatch = useDispatch();
  const [previousQuantity, setPreviousQuantity] = useState(quantity);

  const handleRemoveClick = (meal, qty, prevQty) => {
    setPreviousQuantity(qty);
    handleRemoveItem(meal, qty, prevQty);
  };

  const handleIncrement = (meal, qty) => {
    let newQuantity = (qty += 1);
    setPreviousQuantity(newQuantity);
    dispatch(changeCartQuantity(meal._id, newQuantity));
  };

  const handleDecrement = (meal, qty, prevQty) => {
    let newQuantity = (quantity -= 1);
    setPreviousQuantity(newQuantity);
    dispatch(changeCartQuantity(meal._id, newQuantity));
    if (newQuantity === 0) {
      setPreviousQuantity(1);
      handleRemoveItem(meal, qty, prevQty);
    }
  };

  const handleQuantityFocus = (qty) => {
    setPreviousQuantity(qty);
  };

  const handleQuantityBlur = (meal, qty, previousQty) => {
    if (qty === null || isNaN(qty)) {
      dispatch(changeCartQuantity(meal._id, previousQty));
    }
  };

  const handleQuantityChange = (e, mealId, prevQty) => {
    dispatch(changeCartQuantity(mealId, parseInt(e.target.value)));
    if (e.target.value === "0") {
      handleRemoveItem(meal, quantity, prevQty);
    } else if (e.target.value !== "") {
      setPreviousQuantity(e.target.value);
    }
  };

  const handleRemoveItem = (meal, qty, prevQty) => {
    dispatch(
      setModalComponent("confirm-remove-cart", {
        meal: meal,
        qty: qty,
        previousQty: prevQty,
      })
    );
    dispatch(setModalOpen(true));
    // trackRemoveFromCart(meal, quantity);
  };

  return (
    <li className="cart-item">
      <Link to={`/menu/meals/${meal._id}`}>
        <img
          src={meal.mealImageUrl}
          alt={meal.mealName}
          className="cart-item__img"
        />
      </Link>
      <div className="cart-item__details">
        <h3 className="cart-item__title">
          <Link to={`/menu/meals/${meal._id}`}>
            {meal.mealName}
            {meal.mealSubtitle ? <span>{` ${meal.mealSubtitle}`}</span> : null}
          </Link>
        </h3>
        <DisplayPrice price={meal.price} />
        <div className="cart-item__controls">
          <BrentoStepper
            stepperValue={quantity}
            size="sml"
            onIncrement={() => handleIncrement(meal, quantity)}
            onDecrement={() =>
              handleDecrement(meal, quantity, previousQuantity)
            }
            onInputFocus={(e) => handleQuantityFocus(quantity)}
            onInputBlur={(e) =>
              handleQuantityBlur(meal, quantity, previousQuantity)
            }
            onInputChange={(e) =>
              handleQuantityChange(e, meal._id, previousQuantity)
            }
          />
          <IconButton
            className="cart-item__remove-btn"
            aria-label="delete item"
            onClick={() => handleRemoveClick(meal, quantity, previousQuantity)}
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </li>
  );
};

export default CartItem;
