import { createStore, applyMiddleware } from "redux";
import { saveState } from "./utils/localStorage";
import throttle from "lodash.throttle";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import rootReducer from "./reducers/index";

// let defaultState;
// const customerIdState = localStorage.getItem("customerId");
// if (persistedCartState) {
//   defaultState = {
//     cart: JSON.parse(persistedCartState),
//   };
// }

let defaultState = {};
const persistedCartState = localStorage.getItem("cart");
const persistedPaymentState = localStorage.getItem("payment");
const persistedMenuViewState = localStorage.getItem("prefMenuView");

if (persistedCartState) {
  defaultState.cart = JSON.parse(persistedCartState);
}
if (persistedPaymentState) {
  defaultState.payment = JSON.parse(persistedPaymentState);
}
if (persistedMenuViewState && defaultState.menu) {
  defaultState.menu.prefMenuView = persistedMenuViewState;
}

const middleware = [thunk];

const store = createStore(
  rootReducer,
  defaultState,
  composeWithDevTools(applyMiddleware(...middleware))
);

store.subscribe(
  throttle(() => {
    saveState("cart", store.getState().cart);
    saveState("payment", store.getState().payment);
  }, 1000)
);
export default store;
