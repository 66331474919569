import React from "react";
import "./Spinner.scss";

const Spinner = ({ size = "small" }) => {
  return (
    <div className="spinner">
      {size === "small" ? <SmallSpinner /> : <LargeSpinner />}
    </div>
  );
};

const LargeSpinner = () => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 72 72" className="spinner--large">
      <g className="spinner__glyph">
        <path
          d="M48.5,34V31c0-1.66-1.35-3-3-3h-5H40H25.5c-1.1,0-2,0.9-2,2V43c0,1.1,0.9,2,2,2H40h0h5.5c1.65,0,3-1.34,3-3v-3
		c0-1.04-0.54-1.96-1.35-2.5C47.96,35.96,48.5,35.04,48.5,34z M39.5,44h-14c-0.55,0-1-0.45-1-1V30c0-0.55,0.45-1,1-1h14V44z
		 M47.5,39v3c0,1.1-0.9,2-2,2h-5v-7h5h0.01C46.6,37,47.5,37.9,47.5,39z M47.5,34c0,1.1-0.9,2-2,2h-5v-7h5c1.1,0,2,0.9,2,2V34z"
        />
        <path
          d="M36.5,38.5c0-1-0.42-1.62-0.94-2c0.52-0.38,0.94-1,0.94-2c0-1.98-1.64-2.5-2.5-2.5h-6.5v4v1v4H34
		C34.86,41,36.5,40.48,36.5,38.5z M28.5,33H34c0.25,0,1.5,0.09,1.5,1.5c0,1.41-1.25,1.5-1.5,1.5h-5.5V33z M28.5,37H34
		c0.25,0,1.5,0.09,1.5,1.5S34.25,40,34,40h-5.5V37z"
        />
      </g>
      <g className="spinner__circle">
        <circle cx="36" cy="36" r="25" />
        <path d="M61,36c0,13.81-11.19,25-25,25" />
      </g>
    </svg>
  );
};

const SmallSpinner = () => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 16 16" className="spinner--small">
      <g className="spinner__circle">
        <circle cx="8" cy="8" r="6" />
        <path d="M14,8c0,3.31-2.69,6-6,6" />
      </g>
    </svg>
  );
};

export default Spinner;
