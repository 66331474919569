import { formatLineItem } from "./formatLineItem";

export const formatAllLineItems = (lineItems, purchase = false) => {
  if (lineItems) {
    return lineItems.map((lineItem, index) => {
      if (purchase) {
        return {
          item_id: lineItem._id,
          item_name: lineItem.mealName,
          index: index,
          price: lineItem.price,
          quantity: lineItem.quantity,
        };
      } else {
        return formatLineItem(lineItem.meal, lineItem.qty, index);
      }
    });
  } else {
    return [];
  }
};
