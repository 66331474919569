import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import moment from "moment";
import { Prompt } from "react-router-dom";
import "./GiftCardCheckoutForm.scss";
import {
  CardNumberElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import {
  setGiftCardPaymentIntentId,
  clearGiftCardPaymentIntent,
} from "../../../actions/payment";
import BrentoLoadingButton from "../../UI/BrentoLoadingButton";
import { giftCardSchema } from "./Validation";
import Alert from "../../UI/Alert/Alert";
import SelectAmountFields from "./fields/SelectAmountFields";
import ContactInfoFields from "./fields/ContactInfoFields";
import RecipientInfoFields from "./fields/RecipientInfoFields";
import PaymentInfoFields from "./fields/PaymentInfoFields";
import { allErrorsTouched } from "../../Checkout/CheckoutSteps/utils/allErrorsTouched";
import { generateDiscountStripeDataObject } from "./utils/generateDiscountStripeDataObject";
import { checkIfToday } from "./utils/checkIfToday";
import { getRightNow } from "./utils/getRightNow";
import { variables } from "../../../variables/variables";

const GiftCardCheckoutForm = ({
  purchaseGiftCardRequest,
  giftCardPaymentIntentRequest,
  purchaseCompleted,
  finalizeGiftCardPurchase,
}) => {
  const [customGiftCardAmount, setCustomGiftCardAmount] = useState(false);
  const [paymentInputError, setPaymentInputError] = useState([]);
  const [paymentSucceeded, setPaymentSucceeded] = useState(false);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const isMobile = useSelector(
    (state) => state.layout.displayWidth < variables.breakpoints.lrg
  );
  const testMode = useSelector((state) => state.admin.testMode);
  const giftCardPaymentIntentId = useSelector(
    (state) => state.payment.giftCardPaymentIntentId
  );
  const dispatch = useDispatch();

  const handleSetFixedAmount = (amount) => {
    setCustomGiftCardAmount(false);
    formik.setFieldValue("giftCardAmount", amount);
  };

  console.log(getRightNow());

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues: {
      giftCardAmount: 25,
      // Contact Fields
      contactName: "",
      contactEmail: "",
      contactPhone: "",
      // Recipient Information
      recipientEmail: "",
      recipientDeliveryDate: moment(getRightNow()).format("YYYY-MM-DD"),
      recipientZipCode: "",
      specialMessage: "I hope you enjoy some tasty Brento Boxes!",
      // Payment Fields
      ccnumber: "",
      ccExpiry: "",
      ccCvc: "",
      billingZipCode: "",
    },
    validationSchema: giftCardSchema,
    onSubmit: (values) => {
      // setPaymentProcessing(true);
      console.log("SUBMITTTINNGGGG");
      try {
        // Try submitting form
        handleFullSubmit(values);
        console.log(values);
      } catch (err) {
        // Handle form submission error
        console.log(err);
      }
    },
  });

  const errorKeys = Object.keys(formik.errors);
  // const schemaKeys = Object.keys(giftCardSchema.fields);

  const handlePreSubmit = () => {
    formik.setTouched({
      giftCardAmount: true,
      contactName: true,
      contactEmail: true,
      contactPhone: true,
      recipientEmail: true,
      recipientZipCode: true,
      specialMessage: true,
      ccnumber: true,
      ccExpiry: true,
      ccCvc: true,
      billingZipCode: true,
    });
    formik.handleSubmit();
  };

  const handleFullSubmit = async (values) => {
    setPaymentProcessing(true);
    try {
      let clientSecret;
      // Create payment intent
      if (giftCardPaymentIntentId) {
        let clientSecretPayload = await stripe.retrievePaymentIntent(
          giftCardPaymentIntentId
        );
        console.log("Existing client secret:");
        clientSecret = clientSecretPayload.paymentIntent.client_secret;
        console.log(clientSecret);
      } else {
        // Create payment intent request
        clientSecret = await giftCardPaymentIntentRequest(
          values,
          giftCardPaymentIntentId,
          testMode
        );
        dispatch(setGiftCardPaymentIntentId(clientSecret));
      }
      const paymentPayload = await stripe.confirmCardPayment(
        clientSecret,
        generateDiscountStripeDataObject(
          values,
          elements.getElement(CardNumberElement)
        )
      );
      if (paymentPayload.error) {
        setPaymentError(true);
        setPaymentProcessing(false);
        setPaymentInputError([
          ...paymentInputError,
          paymentPayload.error.message,
        ]);
        console.log(paymentPayload.error);
      } else {
        console.log("Payment was a success!");
        setPaymentProcessing(false);
        dispatch(clearGiftCardPaymentIntent());
        // Create gift card
        let discountResponse = await purchaseGiftCardRequest(testMode, {
          ...values,
          paymentIntentId: paymentPayload.paymentIntent.id,
        });
        console.log(discountResponse);
        finalizeGiftCardPurchase(discountResponse);
      }
    } catch (err) {
      console.log(err);
    }

    // Confirm Card Payment
    // If successful complete the full process
  };

  return (
    <div className="gift-card-checkout">
      <form className="gift-card-checkout__form" onSubmit={formik.handleSubmit}>
        <Prompt
          when={formik.dirty && !paymentSucceeded & !purchaseCompleted}
          message="Are you sure you want to leave this page? Any unsaved changes will be lost"
        />
        <SelectAmountFields
          isMobile={isMobile}
          customGiftCardAmount={customGiftCardAmount}
          setCustomGiftCardAmount={setCustomGiftCardAmount}
          handleSetFixedAmount={handleSetFixedAmount}
          formik={formik}
        />
        <ContactInfoFields formik={formik} />
        <RecipientInfoFields formik={formik} moment={moment} />
        <PaymentInfoFields
          formik={formik}
          paymentInputError={paymentInputError}
          setPaymentInputError={setPaymentInputError}
        />
        {(allErrorsTouched(errorKeys, formik.touched) &&
          errorKeys.length > 0) ||
        paymentInputError.length > 0 ? (
          <Alert
            header="We have found the following errors:"
            type="error"
            content={
              <ul>
                {Object.keys(formik.errors)
                  .filter((key) => {
                    return formik.touched[key] === true;
                  })
                  .map((key) => {
                    return (
                      <li key={key} className="checkout-alert-error">
                        {formik.errors[key]}
                      </li>
                    );
                  })}
                {paymentInputError.map((error) => {
                  return (
                    <li key={error} className="checkout-alert-error">
                      {error}
                    </li>
                  );
                })}
              </ul>
            }
          />
        ) : null}
        <div className="gift-card-checkout__submit-panel">
          <div className="gift-card-checkout__summary">
            <div className="total">
              Total:{" "}
              <span>
                {formik.values.giftCardAmount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </span>
            </div>
            <div className="send-date">
              <p className="label">Email delivery:</p>
              <p className="value">
                {checkIfToday(formik.values.recipientDeliveryDate)
                  ? "Right now"
                  : moment(formik.values.recipientDeliveryDate).format(
                      isMobile ? "MMM Do, YYYY, 8:00a" : "MMMM Do YYYY, 8:00a"
                    )}
              </p>
            </div>
          </div>
          <div className="button-slot">
            <BrentoLoadingButton
              fullWidth={true}
              onClick={handlePreSubmit}
              pending={paymentProcessing}
              disabled={
                (allErrorsTouched(errorKeys, formik.touched) &&
                  errorKeys.length > 0) ||
                Boolean(paymentInputError.length > 0)
              }
            >
              Purchase & Send Gift Card
            </BrentoLoadingButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default GiftCardCheckoutForm;
