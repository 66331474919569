import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setPrefMenuView } from "../../../actions/menu";
import BrentoTabs from "../../UI/BrentoTabs";
import BrentoTab from "../../UI/BrentoTab/BrentoTab";
import "./MenuViewSelect.scss";

const MenuViewSelect = () => {
  const dispatch = useDispatch();
  const prefMenuView = useSelector((state) => state.menu.prefMenuView);

  const handleChangeView = (event, newValue) => {
    dispatch(setPrefMenuView(newValue));
  };

  return (
    <div className="menu-view-select">
      <BrentoTabs value={prefMenuView} onChange={handleChangeView}>
        <BrentoTab label="Categories" value={"categories"} />
        <BrentoTab label="All Items" value={"all-items"} />
      </BrentoTabs>
    </div>
  );
};

export default MenuViewSelect;
