import axios from "axios";
import {
  ADD_NEW_MEAL,
  ADD_NEW_MEAL_ERROR,
  ADD_NEW_MEAL_LOADING,
  GET_MEAL,
  GET_MEAL_ERROR,
  GET_ALL_MEALS,
  GET_ALL_MEALS_ERROR,
  MEAL_LOADING,
  DELETE_MEAL,
  DELETE_MEAL_ERROR,
  UPDATE_MEAL,
  UPDATE_MEAL_ERROR,
  UPDATE_MEAL_LOADING,
  SELECT_MEAL,
} from "./types";
// import setAuthToken from "../utils/setAuthToken";
import { setAlert } from "./alert";

// Add new meal
// ADMIN ONLY
export const addNewMeal =
  (formValues, imageFile, imageUrl) => async (dispatch) => {
    dispatch({ type: ADD_NEW_MEAL_LOADING, payload: true });
    try {
      const formData = new FormData();

      // Map rest of form values to new Form Data
      Object.keys(formValues)
        .filter((key) => key !== "mealImageUrl")
        .forEach((key) => formData.append(key, formValues[key]));

      // Add image blob to new Form Data
      formData.append("imageFile", imageFile);
      formData.append("imageUrl", imageUrl);

      let res = await axios({
        method: "post",
        url: "/api/meals/add-meal",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      dispatch({
        type: ADD_NEW_MEAL,
        payload: res.data,
      });
      dispatch(setAlert("New meal added:", `${res.data.mealName}`, "success"));
      dispatch({ type: ADD_NEW_MEAL_LOADING, payload: false });
      return res;
    } catch (err) {
      console.log(err);
      dispatch({
        type: ADD_NEW_MEAL_ERROR,
      });
      dispatch({ type: ADD_NEW_MEAL_LOADING, payload: false });
    }
  };

// Update an existing meal's information & details
// ADMIN ONLY
export const updateMeal =
  (id, updatedMealValues, imageFile, imageUrl) => async (dispatch) => {
    dispatch({ type: UPDATE_MEAL_LOADING, payload: true });
    try {
      const formData = new FormData();
      console.log(updatedMealValues);
      // Map rest of form values to new Form Data
      Object.keys(updatedMealValues)
        .filter((key) => key !== "mealImageUrl")
        .forEach((key) => formData.append(key, updatedMealValues[key]));

      // If new image, add image file to new Form Data

      formData.append("imageFile", imageFile);
      // formData.append("imageUrl", imageUrl);

      console.log(formData);
      let res = await axios({
        method: "post",
        url: `/api/meals/${id}`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      dispatch({
        type: UPDATE_MEAL,
        payload: res.data,
      });
      dispatch(setAlert("Meal updated:", `${res.data.mealName}`, "success"));
      dispatch({ type: UPDATE_MEAL_LOADING, payload: false });
      return res;
    } catch (err) {
      console.log(err);
      dispatch({
        type: UPDATE_MEAL_ERROR,
      });
      dispatch(
        setAlert(
          "Error updating meal:",
          `${updatedMealValues.mealName}`,
          "error"
        )
      );
      dispatch({ type: UPDATE_MEAL_LOADING, payload: false });
    }
  };

// Get a single meal based on ID
export const getMeal = (id) => async (dispatch) => {
  dispatch({ type: MEAL_LOADING, payload: true });
  try {
    const res = await axios.get(`/api/meals/${id}`);
    dispatch({
      type: GET_MEAL,
      payload: res.data,
    });
    dispatch({ type: MEAL_LOADING, payload: false });
  } catch (err) {
    dispatch({
      type: GET_MEAL_ERROR,
    });
  }
};

// Get all meals in database
// ADMIN ONLY
export const getAllMeals = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/meals");
    dispatch({
      type: GET_ALL_MEALS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_ALL_MEALS_ERROR,
    });
  }
};

// Delete Meal
// ADMIN ONLY
export const deleteMeal = (mealName, mealId) => async (dispatch) => {
  try {
    const res = await axios.delete(`/api/meals/${mealId}`);
    dispatch({
      type: DELETE_MEAL,
      payload: res.data,
    });
    dispatch(setAlert("Meal removed successfully:", `${mealName}`, "success"));
  } catch (err) {
    dispatch({
      type: DELETE_MEAL_ERROR,
    });
  }
};

// Select a meal for meal manager action panel
export const setSelectedMeal = (id) => (dispatch) => {
  try {
    dispatch({
      type: SELECT_MEAL,
      payload: id,
    });
  } catch (err) {}
};
