import axios from "axios";
import {
  GET_ANALYTICS_DATA,
  GET_ANALYTICS_DATA_LOADING,
  GET_ANALYTICS_DATA_ERROR,
  SET_ANALYTICS_FILTER_BY,
  SET_ANALYTICS_BREAKDOWN_BY,
} from "./types";

// Get all meals in database
// ADMIN ONLY
export const getAnalyticsData = () => async (dispatch) => {
  dispatch({ type: GET_ANALYTICS_DATA_LOADING, payload: true });
  try {
    const res = await axios.get("/api/analytics");
    dispatch({
      type: GET_ANALYTICS_DATA,
      payload: res.data,
    });
    dispatch({ type: GET_ANALYTICS_DATA_LOADING, payload: false });
    dispatch({ type: GET_ANALYTICS_DATA_ERROR, payload: false });
  } catch (err) {
    dispatch({
      type: GET_ANALYTICS_DATA_ERROR,
      payload: err.message,
    });
    dispatch({ type: GET_ANALYTICS_DATA_LOADING, payload: false });
  }
};

export const setAnalyticsFilterBy = (filterBy) => async (dispatch) => {
  dispatch({ type: SET_ANALYTICS_FILTER_BY, payload: filterBy });
};

export const setAnalyticsBreakdownBy = (breakdownBy) => async (dispatch) => {
  dispatch({ type: SET_ANALYTICS_BREAKDOWN_BY, payload: breakdownBy });
};
