export const sortItems = (items, sortByValue) => {
  if (sortByValue === "Most recent") {
    console.log(
      items.sort((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded))
    );
    return items.sort((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded));
  } else if (sortByValue === "A-Z") {
    return items.sort((a, b) =>
      a.mealName !== b.mealName ? (a.mealName < b.mealName ? -1 : 1) : 0
    );
  } else if (sortByValue === "Z-A") {
    return items.sort((a, b) =>
      b.mealName !== a.mealName ? (b.mealName < a.mealName ? -1 : 1) : 0
    );
  } else {
    return items;
  }
};
