import React from "react";
import { Tabs } from "@mui/material";

const BrentoTabs = ({
  children,
  onChange,
  ariaLabel,
  value,
  indicatorColor = "primary",
}) => {
  return (
    <Tabs
      value={value}
      aria-label={ariaLabel}
      onChange={onChange}
      indicatorColor={indicatorColor}
    >
      {children}
    </Tabs>
    // <Button
    //   classes={{ root: classes.root, outlined: classes.outlined }}
    //   onClick={onClick ? () => onClick() : null}
    //   variant="contained"
    //   color="primary"
    //   startIcon={startIcon}
    //   endIcon={endIcon}
    //   size={size}
    // >
    //   {children}
    // </Button>
  );
};

export default BrentoTabs;
