import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import "./EditPromoCodeForm.scss";
import PromoCodeStatus from "../CreatePromoCodeForm/fields/PromoCodeStatus";
import PromoCodeEssentials from "../CreatePromoCodeForm/fields/PromoCodeEssentials";
import TeamMemberEmails from "../CreatePromoCodeForm/fields/TeamMemberEmails";
import BrentoLoadingButton from "../../UI/BrentoLoadingButton";
import { validationSchema } from "./Validation";
import { useSelector } from "react-redux";

const EditPromoCodeForm = ({
  editPromoCodeRequest,
  promoCodeId,
  finalizePromoCodeCreation,
  promoCodeData,
}) => {
  const [submitPending, setSubmitPending] = useState(false);

  const testMode = useSelector((state) => state.admin.testMode);
  useEffect(() => {
    console.log(formik.errors);
    console.log(formik.touched);
  });

  const formik = useFormik({
    initialValues: {
      discountCode: promoCodeData.discountCode,
      isActive: promoCodeData.isActive,
      discountAudience: promoCodeData.discountAudience,
      isFreeShipping: promoCodeData.isFreeShipping,
      isFreeProcessing: promoCodeData.isFreeProcessing,
      isPercentOff: promoCodeData.isPercentOff,
      percentOff: promoCodeData.percentOff,
      usageType: promoCodeData.usageType,
      maxUseCount: promoCodeData.maxUseCount,
      teamMemberEmails: [...Object.keys(JSON.parse(promoCodeData.emailList))],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("Submitting!");
      setSubmitPending(true);
      try {
        handleFullSubmit(values);
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePreSubmit = () => {
    formik.setTouched({
      discountCode: true,
      maxUseCount: true,
      greetingMessage: true,
      percentOff: true,
      teamMemberEmails: true,
    });
    formik.handleSubmit();
  };

  const handleFullSubmit = async (formValues) => {
    formValues.discountCode = formValues.discountCode.toLocaleUpperCase();
    let promoCodeResponse = await editPromoCodeRequest(testMode, promoCodeId, {
      ...formValues,
    });
    console.log("Response:");
    console.log(promoCodeResponse);
    if (
      promoCodeResponse &&
      promoCodeResponse.response &&
      promoCodeResponse.response.data.errors.length > 0
    ) {
      setSubmitPending(false);
      return promoCodeResponse;
    } else {
      setSubmitPending(false);
      finalizePromoCodeCreation(promoCodeResponse);
      return promoCodeResponse;
    }
  };

  return (
    <form className="create-promo-code-form" onSubmit={formik.handleSubmit}>
      <PromoCodeStatus formik={formik} />
      <PromoCodeEssentials formik={formik} formMode="edit" />
      {formik.values.discountAudience === "team-member-vendor" && (
        <TeamMemberEmails formik={formik} />
      )}
      <div>
        <BrentoLoadingButton
          onClick={handlePreSubmit}
          pending={submitPending}
          disabled={!formik.dirty}
        >
          Update Promo Code
        </BrentoLoadingButton>
      </div>
    </form>
  );
};

export default EditPromoCodeForm;
