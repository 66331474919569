import axios from "axios";
import {
  GET_ALL_GIFT_CARDS,
  GET_GIFT_CARD_DATA,
  SET_GIFT_CARD_MANAGER_VIEW,
} from "../actions/types";

// Get all gift cards
// ADMIN ONLY
export const getAllGiftCards = (testMode) => async (dispatch) => {
  try {
    const requestPath = testMode ? "/api/test-discount" : "/api/discount";
    let res = await axios.get(requestPath);
    dispatch({
      type: GET_ALL_GIFT_CARDS,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

// Get gift card based on ID
// ADMIN ONLY
export const getGiftCardAdmin = (testMode, giftCardId) => async (dispatch) => {
  try {
    const requestPath = testMode
      ? `/api/test-discount/${giftCardId}`
      : `/api/discount/${giftCardId}`;
    let res = await axios.get(requestPath);
    dispatch({ type: GET_GIFT_CARD_DATA, payload: res.data });
  } catch (err) {
    console.log(err);
  }
};

// Use this to persist tab selection in gift card manager
// ADMIN ONLY
export const setGiftCardManagerView = (managerView) => (dispatch) => {
  try {
    dispatch({ type: SET_GIFT_CARD_MANAGER_VIEW, payload: managerView });
  } catch (err) {
    console.log(err);
  }
};
