import React from "react";
import { REMOVE_ALERT } from "../../actions/types";
import { useSelector, useDispatch } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import "./Alert.scss";

const Alert = () => {
  const alerts = useSelector((state) => state.alert);
  const dispatch = useDispatch();

  const handleClearAlert = (alertId) => {
    dispatch({ type: REMOVE_ALERT, payload: alertId });
  };

  return alerts.length > 0 &&
  alerts.map((alert) => (
    <div key={alert.id} className={`alert alert--${alert.alertType}`}>
      <div className="alert__contents">
        <p className="alert__name">{alert.alertTitle}</p>
        <p className="alert__message">{alert.alertMsg}</p>
      </div>
      <IconButton
        aria-label="Remove alert"
        onClick={() => handleClearAlert(alert.id)}
        size="large">
        <ClearIcon />
      </IconButton>
    </div>
  ));
};

export default Alert;
