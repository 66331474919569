import React from "react";
import "./AdminNavbar.scss";
import { NavLink } from "react-router-dom";

const AdminNavbar = () => {
  return (
    <nav className="admin-navbar">
      <NavLink
        to="/admin"
        className="admin-navbar__link"
        activeClassName="active"
        exact
      >
        Home
      </NavLink>
      <NavLink
        to="/admin/meals"
        className="admin-navbar__link"
        activeClassName="active"
      >
        Meals
      </NavLink>
      <NavLink
        to="/admin/menu"
        className="admin-navbar__link"
        activeClassName="active"
      >
        Menu
      </NavLink>
      <NavLink
        to="/admin/orders"
        className="admin-navbar__link"
        activeClassName="active"
      >
        Orders
      </NavLink>
      <NavLink
        to="/admin/discount"
        className="admin-navbar__link"
        activeClassName="active"
      >
        Discounts
      </NavLink>
      <NavLink
        to="/admin/zip-codes"
        className="admin-navbar__link hide-sm"
        activeClassName="active"
      >
        Zip Codes
      </NavLink>
    </nav>
  );
};

export default AdminNavbar;
