import React, { useEffect } from "react";
import "./ConfirmRemoveCart.scss";
import ModalControls from "../../ModalControls";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setModalOpen } from "../../../../../actions/layout";
import { trackRemoveFromCart } from "../../../../../tracking/trackRemoveFromCart";
import {
  changeCartQuantity,
  removeFromCart,
} from "../../../../../actions/cart";

const ConfirmRemoveCart = ({ data }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleRemoveFromCart = () => {
    trackRemoveFromCart(data.meal, data.qty);
    dispatch(removeFromCart(data.meal._id));
    dispatch(setModalOpen(false));
  };

  const handleCancelRemove = () => {
    dispatch(changeCartQuantity(data.meal._id, data.previousQty));
    dispatch(setModalOpen(false));
  };

  return (
    <div className="confirm-remove-cart">
      <div className="confirm-remove-cart__main">
        <img
          src={data.meal.mealImageUrl}
          alt={data.meal.mealName}
          className="confirm-remove-cart__img"
        />
        <h3 className="confirm-remove-cart__header">
          Remove{" "}
          <span className="confirm-remove-cart__meal-name">
            {data.meal.mealName}
          </span>{" "}
          from your cart?
        </h3>
      </div>
      <ModalControls
        primaryText="Remove from cart"
        primaryAction={() => handleRemoveFromCart()}
        secondaryText="Cancel"
        secondaryAction={() => handleCancelRemove()}
      />
    </div>
  );
};

export default ConfirmRemoveCart;
