import {
  SET_PAYMENT_INTENT_ID,
  SET_GIFTCARD_PAYMENT_INTENT_ID,
  SET_PAYMENT_INTENT_EXPIRATION,
  CLEAR_PAYMENT_INTENT,
  CLEAR_GIFTCARD_PAYMENT_INTENT,
} from "./types";
// import setAuthToken from "../utils/setAuthToken";

// Set payment intent id
export const setPaymentIntentId = (paymentIntentId) => async (dispatch) => {
  try {
    dispatch({ type: SET_PAYMENT_INTENT_ID, payload: paymentIntentId });
  } catch (err) {
    console.log(err);
  }
};

// Set the gift payment card intent id
export const setGiftCardPaymentIntentId =
  (paymentIntentId) => async (dispatch) => {
    console.log("Redux action: setting giftcard payment intent id");
    try {
      dispatch({
        type: SET_GIFTCARD_PAYMENT_INTENT_ID,
        payload: paymentIntentId,
      });
    } catch (err) {
      console.log(err);
    }
  };

// Set the expiration time and date for the payment intent to time out
export const setPaymentIntentExpiry =
  (paymentIntentExpiry) => async (dispatch) => {
    try {
      dispatch({
        type: SET_PAYMENT_INTENT_EXPIRATION,
        payload: paymentIntentExpiry,
      });
    } catch (err) {
      console.log(err);
    }
  };

// Clear the payment intent, such as when a payment is completed or timed-out
export const clearPaymentIntent = () => (dispatch) => {
  dispatch({ type: CLEAR_PAYMENT_INTENT });
};

// Clear the gift cardpayment intent, such as when a payment is completed or timed-out
export const clearGiftCardPaymentIntent = () => (dispatch) => {
  dispatch({ type: CLEAR_GIFTCARD_PAYMENT_INTENT });
};
