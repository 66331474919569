import {
  GET_ALL_PROMO_CODES,
  GET_PROMO_CODE_DATA,
  SET_PROMO_CODE_DATA_LOADING,
} from "../actions/types";

const initialState = {
  promoCodes: [],
  promoCodesLoading: true,
  promoCodeDataLoading: true,
  promoCodeData: null,
};

export default function promoCodeReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_PROMO_CODES:
      return {
        ...state,
        promoCodes: payload,
        promoCodesLoading: false,
      };
    case SET_PROMO_CODE_DATA_LOADING: {
      return {
        ...state,
        promoCodeDataLoading: payload,
      };
    }
    case GET_PROMO_CODE_DATA:
      return {
        ...state,
        promoCodeDataLoading: false,
        promoCodeData: payload,
      };
    default:
      return state;
  }
}
