import React from "react";
import moment from "moment";

const PromoCodeEmailList = ({
  emailList,
  tableHeader = "Email history",
  emailHeader = "Email address",
  isMobile,
}) => {
  const emailListData = JSON.parse(emailList);
  const emailListKeys = Object.keys(emailListData);
  console.log(emailListData);
  return (
    <div className="promo-code-details__email-list">
      <p className="promo-code-details__email-list-title">{tableHeader}</p>
      <div className="promo-code-details__email-list-table">
        <div className="promo-code-details__email-list-table-header">
          <p>{emailHeader}</p>
          <p>Times used</p>
          {!isMobile && <p>Last used</p>}
        </div>
        <ul>
          {emailListKeys && emailListKeys.length > 0 ? (
            emailListKeys.map((emailKey) => {
              return (
                <PromoCodeEmailItem
                  emailValue={emailKey}
                  timesUsed={emailListData[emailKey].timesUsed}
                  lastUsedDate={emailListData[emailKey].lastUsedDate}
                  isMobile={isMobile}
                />
              );
            })
          ) : (
            <p>No history</p>
          )}
        </ul>
      </div>
    </div>
  );
};

const PromoCodeEmailItem = ({
  emailContent,
  emailValue,
  timesUsed,
  lastUsedDate,
  isMobile,
}) => {
  console.log(emailContent);
  return (
    <li className="promo-code-details__email-list-table-row">
      <p>{emailValue}</p>
      <p>{timesUsed}</p>
      {!isMobile && (
        <p>{lastUsedDate ? moment(lastUsedDate).fromNow() : "N/A"}</p>
      )}
    </li>
  );
};

export default PromoCodeEmailList;
