import React from "react";
import "./MenuHero.scss";
import "../MenuCountdown/MenuCountdown.scss";
import Container from "../../Layout/Container";
import Skeleton from "@mui/material/Skeleton";

const MenuHero = ({ menuTitle, orderByDate, menuLoading }) => {
  return (
    <div className="menu-hero">
      <Container>
        <div className="menu-hero__contents">
          <h1 className="menu-hero__title">Menu</h1>
          <div className="menu-hero__sub-section">
            {menuLoading ? (
              <Skeleton variant="text" height={20} width={120} />
            ) : (
              <p className="menu-hero__name">{menuTitle}</p>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MenuHero;
