import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { clearPaymentIntent } from "../../../actions/payment";
import { isFieldExpired } from "../../../utils/isFieldExpired";

const DetectPaymentIntent = ({ checkoutCompleted }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const paymentIntentExpiry = useSelector(
    (state) => state.payment.paymentIntentExpiry
  );
  const paymentIntentId = useSelector((state) => state.payment.paymentIntentId);

  useEffect(() => {
    console.log("Checking payment intent");
    if (isFieldExpired(paymentIntentExpiry)) {
      console.log("Payment intent expired");
      dispatch(clearPaymentIntent());
    }
    if (!paymentIntentId && !checkoutCompleted) {
      history.push("/cart");
    }
  }, [paymentIntentId]);
  return <></>;
};

export default DetectPaymentIntent;
