import React from "react";
import CategoryTemplate from "../CategoryTemplate";
import { Helmet } from "react-helmet";

const SignatureMeals = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link
          rel="canonical"
          href="https://getbrentobox.com/menu/signature-meals"
        />
        <title>
          Menu - Signature Meals | Brento Box - Fresh Meal Prep Made Easy
        </title>
        <meta
          name="description"
          content="At Brento Box, our signature meals are made with fresh ingredients, with new menu items posted each week."
        />
      </Helmet>
      <CategoryTemplate
        categoryTitle="Signature Meals"
        categoryValue={"meal"}
        categoryTrackingId={"meals"}
      />
    </>
  );
};

export default SignatureMeals;
