import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "../../Pages/Admin/Login/Login";
// import Register from "../Auth/Register";
import AdminHome from "../../Pages/Admin/AdminHome";
import NotFound from "../Layout/NotFound";
import PrivateRoute from "../Routing/PrivateRoute";
import AddMeal from "../../Pages/Admin/AddMeal/AddMeal";
import EditMeal from "../../Pages/Admin/EditMeal";
import Meals from "../../Pages/Admin/Meals";
import AdminMenu from "../../Pages/Admin/Menu";
import Orders from "../../Pages/Admin/Orders/Orders";
import Menu from "../../Pages/Menu/Menu";
import SignatureMeals from "../../Pages/Menu/Categories/SignatureMeals/SignatureMeals";
import SnacksExtras from "../../Pages/Menu/Categories/SnacksExtras/SnacksExtras";
import JuicesCoffee from "../../Pages/Menu/Categories/JuicesCoffee/JuicesCoffee";
import Bulk from "../../Pages/Menu/Categories/Bulk/Bulk";
import DogTreats from "../../Pages/Menu/Categories/DogTreats/DogTreats";
import Merch from "../../Pages/Menu/Categories/Merch/Merch";
import MealDetails from "../../Pages/MealDetails/MealDetails";
import FAQs from "../../Pages/FAQs/FAQs";
import About from "../../Pages/About/About";
import Contact from "../../Pages/Contact/Contact";
import Cart from "../../Pages/Cart/Cart";
import Checkout from "../../Pages/Payment/Checkout/Checkout";
import ExpressThankYou from "../../Pages/Payment/ExpressThankYou/ExpressThankYou";
import PrivacyPolicy from "../../Pages/PrivacyPolicy/PrivacyPolicy";
import BrandAssets from "../../Pages/BrandAssets/BrandAssets";
import GiftCard from "../../Pages/GiftCard/GiftCard";
import PurchaseGiftCard from "../../Pages/GiftCard/PurchaseGiftCard/PurchaseGiftCard";
import DiscountAdmin from "../../Pages/Admin/DiscountAdmin/DiscountAdmin";
import CreateGiftCard from "../../Pages/Admin/DiscountAdmin/CreateGiftCard/CreateGiftCard";
import CreatePromoCode from "../../Pages/Admin/DiscountAdmin/CreatePromoCode/CreatePromoCode";
import EditPromoCode from "../../Pages/Admin/DiscountAdmin/EditPromoCode/EditPromoCode";
import GiftCardDetails from "../../Pages/Admin/GiftCardDetails/GiftCardDetails";
import PromoCodeDetails from "../../Pages/Admin/PromoCodeDetails/PromoCodeDetails";
import ZipCodeAdmin from "../../Pages/Admin/ZipCodeAdmin/ZipCodeAdmin";

const Routes = () => {
  return (
    <>
      <Switch>
        {/* <Route exact path="/register" component={Register} /> */}
        <Route exact path="/login" component={Login} />
        <Route exact path="/menu/meals/:id" component={MealDetails} />
        <Route exact path="/menu" component={Menu} />
        {/* Menu Categories */}
        <Route path="/menu/signature-meals" component={SignatureMeals} />
        <Route path="/menu/treats" component={SnacksExtras} />
        <Route path="/menu/juices-and-coffee" component={JuicesCoffee} />
        <Route path="/menu/a-la-carte" component={Bulk} />
        <Route path="/menu/dog-treats" component={DogTreats} />
        <Route path="/merch-and-more" component={Merch} />
        <Route exact path="/faqs" component={FAQs} />
        <Route exact path="/about" component={About} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/cart" component={Cart} />
        <Route exact path="/gift-cards" component={GiftCard} />
        <Route
          exact
          path="/gift-cards/purchase-gift-card"
          component={PurchaseGiftCard}
        />
        <Route exact path="/payment/checkout" component={Checkout} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/brand-assets" component={BrandAssets} />
        <Route
          exact
          path="/payment/express/thank-you"
          component={ExpressThankYou}
        />
        <PrivateRoute exact path="/admin" component={AdminHome} />
        <PrivateRoute exact path="/admin/meals" component={Meals} />
        <PrivateRoute exact path="/admin/meals/add-meal" component={AddMeal} />
        <PrivateRoute
          exact
          path="/admin/meals/edit-meal/:id"
          component={EditMeal}
        />
        <PrivateRoute exact path="/admin/menu" component={AdminMenu} />
        <PrivateRoute exact path="/admin/orders" component={Orders} />
        <PrivateRoute exact path="/admin/discount" component={DiscountAdmin} />
        <PrivateRoute
          exact
          path="/admin/discount/gift-card/create-gift-card"
          component={CreateGiftCard}
        />
        <PrivateRoute
          exact
          path="/admin/discount/promo-code/create-promo-code"
          component={CreatePromoCode}
        />
        <PrivateRoute
          exact
          path="/admin/discount/promo-code/edit-promo-code/:id"
          component={EditPromoCode}
        />
        <PrivateRoute
          exact
          path="/admin/discount/gift-card/:id"
          component={GiftCardDetails}
        />
        <PrivateRoute
          exact
          path="/admin/discount/promo-code/:id"
          component={PromoCodeDetails}
        />
        <PrivateRoute exact path="/admin/zip-codes" component={ZipCodeAdmin} />
        <Route component={NotFound} />
      </Switch>
    </>
  );
};

export default Routes;
