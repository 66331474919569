import React, { useState, useEffect } from "react";
import BrentoTextField from "../../../UI/BrentoTextField/BrentoTextField";
import CardInput from "../../../Checkout/CheckoutSteps/Payment/CCDetails/CardInput";
import {
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";

const PaymentInfoFields = ({
  formik,
  paymentInputError,
  setPaymentInputError,
}) => {
  const [cardNumberError, setCardNumberError] = useState(null);
  const [cardExpiryError, setCardExpiryError] = useState(null);
  const [cardCvcError, setCardCvcError] = useState(null);

  const validateCardField = (e, setErrorHook) => {
    console.log(e);
    // formik.setFieldValue("ccnumber", );
    if (e.error) {
      setErrorHook(e.error.message);
      setPaymentInputError([...paymentInputError, e.error.message]);
    } else {
      setErrorHook(null);
    }
  };

  useEffect(() => {
    if (!cardNumberError && !cardExpiryError && !cardCvcError) {
      setPaymentInputError([]);
    }
  }, [cardNumberError, cardExpiryError, cardCvcError]);

  const cardOptions = {
    // hidePostalCode: true,
    style: {
      base: {
        color: "#011212",
        fontFamily:
          '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#9da4a4",
        },
      },
      invalid: {
        color: "#ef5350",
        iconColor: "#ff867c",
      },
    },
  };

  return (
    <div className="gift-card-checkout__field-group">
      <h2 className="header-5">Payment information</h2>
      <p className="body-2">
        Enter your credit card information below to complete the purchase of
        your gift card.
      </p>
      <div className="gift-card-checkout__field">
        <BrentoTextField
          name="ccnumber"
          variant="outlined"
          label="Credit Card Number"
          required
          fullWidth
          onChange={(e) => validateCardField(e, setCardNumberError)}
          error={Boolean(cardNumberError)}
          helperText={cardNumberError}
          InputProps={{
            inputComponent: CardInput,
            inputProps: {
              component: CardNumberElement,
              options: cardOptions,
            },
          }}
          InputLabelProps={{ shrink: true }}
        />
      </div>
      <div className="gift-card-checkout__field">
        <div className="gift-card-checkout__cc-details-row">
          <BrentoTextField
            name="cc-exp"
            label="Card Expiration Date"
            variant="outlined"
            required
            fullWidth
            onChange={(e) => validateCardField(e, setCardExpiryError)}
            error={Boolean(cardExpiryError)}
            helperText={cardExpiryError}
            InputProps={{
              inputComponent: CardInput,
              inputProps: {
                component: CardExpiryElement,
                options: cardOptions,
              },
            }}
            InputLabelProps={{ shrink: true }}
          />
          <BrentoTextField
            name="ccnumber"
            variant="outlined"
            label="Card CVC"
            required
            fullWidth
            onChange={(e) => validateCardField(e, setCardCvcError)}
            error={Boolean(cardCvcError)}
            helperText={cardCvcError}
            InputProps={{
              inputComponent: CardInput,
              inputProps: {
                component: CardCvcElement,
                options: cardOptions,
              },
            }}
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </div>
      <div className="gift-card-checkout__field">
        <BrentoTextField
          fullWidth={true}
          label="Billing ZIP code"
          value={formik.values.billingZipCode}
          name="billingZipCode"
          onChange={formik.handleChange}
          type="number"
        ></BrentoTextField>
      </div>
    </div>
  );
};

export default PaymentInfoFields;
