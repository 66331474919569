import axios from "axios";

export const createAdminDiscountRequest = async (testMode, formValues) => {
  console.log("Creating admin test discount");
  try {
    let requestUrl = testMode
      ? "/api/test-discount/create-admin-discount"
      : "/api/discount/create-admin-discount";

    // TO-DO: Replace below with real data from parameters
    let requestBody = {
      ...formValues,
    };

    let res = await axios.post(requestUrl, requestBody, {
      headers: { "Content-Type": "application/json" },
    });
    return res.data;
  } catch (err) {
    console.log(err.response);
    return err;
  }
};
