export const generateStripeDataObject = (values, cardElement) => {
  let useDifferentBillingAddress = values.useDifferentBillingAddress === "true";
  let dataObject = {
    payment_method: {
      card: cardElement,
      billing_details: {
        name: values.name,
        email: values.email,
        phone: values.phone,
      },
    },
    shipping: {
      name: values.name,
      phone: values.phone,
      address: {
        city: values.deliveryCity,
        line1: values.deliveryAddressLine1,
        line2: values.deliveryAddressLine2,
        postal_code: values.deliveryZipCode,
        state: "AZ",
      },
    },
  };
  if (useDifferentBillingAddress) {
    dataObject.payment_method.billing_details.address = {
      city: values.billingCity,
      country: "US",
      line1: values.billingAddressLine1,
      line2: values.billingAddressLine2,
      postal_code: values.billingZipCode,
      state: values.billingState,
    };
  } else {
    dataObject.payment_method.billing_details.address = {
      city: values.deliveryCity,
      country: "US",
      line1: values.deliveryAddressLine1,
      line2: values.deliveryAddressLine2,
      postal_code: values.deliveryZipCode,
      state: values.deliveryState,
    };
  }
  return dataObject;
};
