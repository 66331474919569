import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const Theme = ({ children }) => {
  const theme = createTheme({
    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    palette: {
      primary: {
        main: "#2c3838",
        light: "#9da4a4",
        lightest: "#f2f2f2",
      },
      secondary: {
        main: "#5dd9c5",
      },
      error: {
        main: "#ff4230",
      },
    },
    // components: {
    //   MuiButton: {
    //     styleOverrides: {
    //       text: {
    //         borderRadius: 1,
    //         height: 48,
    //       },
    //     },
    //   },
    //   MuiTab: {
    //     styleOverrides: {
    //       root: {
    //         border: "1px solid #dadede !important",
    //         textTransform: "none",
    //         flexGrow: 1,
    //         "&.Mui-Hover": {
    //           backgroundColor: "#fafafa",
    //           opacity: 1,
    //         },
    //       },
    //       "&.Mui-Selected": {
    //         color: "#FFF",
    //         backgroundColor: palette.primary.light,
    //         "&:hover": {
    //           backgroundColor: "#f2f2f2",
    //         },
    //       },
    //     },
    //   },
    // },

    zIndex: {
      modal: 2100,
      drawer: 1200,
    },
  });
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
