import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getFullMenu } from "../../../actions/menu";
import { trackViewItemList } from "../../../tracking/trackViewItemList";
import ScrollToTop from "../../../Components/Layout/ScrollToTop";
import Container from "../../../Components/Layout/Container";
import MenuItemGroup from "../../../Components/Menu/MenuItemGroup/MenuItemGroup";
import BrentoBackButton from "../../../Components/UI/BrentoBackButton";
import "./CategoryTemplate.scss";

const CategoryTemplate = ({
  image,
  categoryTitle,
  categoryValue,
  categoryTrackingId,
  introComponent,
  bottomComponent,
}) => {
  const dispatch = useDispatch();
  const activeMenu = useSelector((state) => state.menu.activeMenu);
  const meals = useSelector((state) => state.menu.activeMenu.meals);

  const menuLoading = useSelector((state) => state.menu.menuLoading);
  const testMode = useSelector((state) => state.admin.testMode);

  useEffect(() => {
    if (!meals) {
      dispatch(getFullMenu(testMode));
    }
  }, [dispatch]);

  useEffect(() => {
    if (meals) {
      const categoryMeals = meals.filter(
        (meal) => meal.category === categoryValue
      );
      trackViewItemList(categoryMeals, categoryTrackingId, categoryTitle);
    }
  }, [meals]);

  return (
    <>
      <ScrollToTop />
      <div className="menu-category-template">
        <Container>
          <div className="menu-category-template__header">
            <BrentoBackButton />
            <h1 className="header-3">{categoryTitle}</h1>
            {introComponent && introComponent}
          </div>
          <MenuItemGroup
            menuItems={
              meals && meals.filter((meal) => meal.category === categoryValue)
            }
            id={categoryTrackingId}
            menuLoading={menuLoading}
          />
          {bottomComponent && bottomComponent}
        </Container>
      </div>
    </>
  );
};

export default CategoryTemplate;
