import React from "react";
import AddMealForm from "../../../Components/Admin/AddMealForm/AddMealForm";
import BrentoBackButton from "../../../Components/UI/BrentoBackButton";
import "../../../variables/_type.scss";

const AddMeal = () => {
  return (
    <div>
      <BrentoBackButton />
      <h2 className="header-5">Add a new meal</h2>
      <AddMealForm />
    </div>
  );
};

export default AddMeal;
