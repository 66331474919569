import React from "react";
import "./MinimumProcessingAmount.scss";
import ModalControls from "../../ModalControls";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setModalOpen } from "../../../../../actions/layout";

const ConfirmAddCart = ({ data }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleGotoCart = () => {
    dispatch(setModalOpen(false));
  };

  const handleGotoGiftCardFAQ = () => {
    history.push("/gift-cards");
    dispatch(setModalOpen(false));
  };

  return (
    <div className="minimum-amount-modal">
      <div className="minimum-amount-modal__main">
        <h3 className="header-4">About the Minimum Processing Amount</h3>
        <p className="body-2">
          Because your gift card balance exceeds your current order total, we
          need to include a minimum of $0.50, as required by our payment
          processor, which our ordering system is built around. Don't worry,
          this 50 cents will be carried over to your gift card balance. We
          apologize for any confusion or inconvenience while we work on a better
          solution.
        </p>
      </div>
      <ModalControls
        primaryText="Back to cart"
        primaryAction={() => handleGotoCart()}
        secondaryText="Learn more"
        secondaryAction={() => handleGotoGiftCardFAQ()}
      />
    </div>
  );
};

export default ConfirmAddCart;
