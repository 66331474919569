import React from "react";
import "./CheckoutStepper.scss";
import { useHistory } from "react-router";

const CheckoutStepper = ({
  activeStep,
  setActiveStep,
  completedStepCount,
  setActiveValidationSchema,
}) => {
  let history = useHistory();

  const handleSelectStep = (stepNumber, validationSchema) => {
    setActiveValidationSchema(validationSchema);
    setActiveStep(stepNumber);
  };

  return (
    <div className="checkout-stepper">
      <CheckoutStep
        label="Cart"
        isActive={false}
        onClick={() => history.push("/cart")}
      />
      <span className="checkout-stepper__divider"></span>
      <CheckoutStep
        label="Contact & Delivery"
        isActive={activeStep === 1}
        onClick={() => handleSelectStep(1, "contact")}
      />
      <span className="checkout-stepper__divider"></span>
      <CheckoutStep
        label="Payment"
        isActive={activeStep === 2}
        disabled={completedStepCount < 1}
        onClick={() => handleSelectStep(2, "payment")}
      />
    </div>
  );
};

const CheckoutStep = ({ isActive, label, onClick, disabled, number }) => {
  return (
    <>
      {isActive ? (
        <p className="checkout-stepper__step checkout-stepper__step--active">
          {label}
        </p>
      ) : (
        <button
          disabled={disabled}
          className={`checkout-stepper__step checkout-stepper__step--${
            disabled ? "disabled" : "inactive"
          }`}
          onClick={onClick}
        >
          {label}
        </button>
      )}
    </>
  );
};

export default CheckoutStepper;
