import React, { useEffect } from "react";
import AddZipCode from "./AddZipCode/AddZipCode";
import ZipCodeTable from "./ZipCodeTable/ZipCodeTable";
import { getAllZipCodes } from "../../../actions/zipCodes";
import { useDispatch, useSelector } from "react-redux";

const ZipCodeManager = () => {
  const dispatch = useDispatch();
  const zipCodesLoading = useSelector((state) => state.zipCode.zipCodesLoading);
  const zipCodes = useSelector((state) => state.zipCode.zipCodes);

  useEffect(() => {
    dispatch(getAllZipCodes());
  }, []);

  return (
    <div className="zip-code-manager">
      <AddZipCode />
      {zipCodesLoading ? "Loading..." : <ZipCodeTable zipCodes={zipCodes} />}
    </div>
  );
};

export default ZipCodeManager;
