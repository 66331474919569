import {
  GET_ALL_GIFT_CARDS,
  GET_GIFT_CARD_DATA,
  SET_GIFT_CARD_DATA_LOADING,
  SET_GIFT_CARD_MANAGER_VIEW,
} from "../actions/types";

const initialState = {
  giftCards: [],
  giftCardsLoading: true,
  giftCardDataLoading: true,
  giftCardData: null,
  giftCardManagerView: "customer",
};

export default function adminReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ALL_GIFT_CARDS:
      return {
        ...state,
        giftCards: payload,
        giftCardsLoading: false,
      };
    case SET_GIFT_CARD_DATA_LOADING: {
      return {
        ...state,
        giftCardDataLoading: payload,
      };
    }
    case GET_GIFT_CARD_DATA:
      return {
        ...state,
        giftCardDataLoading: false,
        giftCardData: payload,
      };
    case SET_GIFT_CARD_MANAGER_VIEW:
      return {
        ...state,
        giftCardManagerView: payload,
      };
    default:
      return state;
  }
}
