import {
  GET_CART_ITEMS,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CHANGE_CART_QUANTITY,
  INCREASE_CART_QUANTITY,
  SET_CART_EXPIRATION_DATE,
  CLEAR_ALL_CART_ITEMS,
  SET_CART_INDICATOR_ACTIVE,
  SET_CART_DISCOUNT_CODE,
  CLEAR_CART_DISCOUNT_CODE,
} from "./types";
// import setAuthToken from "../utils/setAuthToken";

// Get all items in cart
export const getCartItems = () => async (dispatch) => {
  dispatch({ type: GET_CART_ITEMS });
};

// Add an item to cart
export const addToCart = (meal, quantity) => async (dispatch) => {
  try {
    dispatch({
      type: SET_CART_INDICATOR_ACTIVE,
      payload: true,
    });
    dispatch({
      type: ADD_TO_CART,
      payload: { meal: meal, qty: quantity },
    });
  } catch (err) {
    console.log(err);
  }
};

// Remove item from cart
export const removeFromCart = (mealId) => async (dispatch) => {
  dispatch({ type: REMOVE_FROM_CART, payload: mealId });
};

// Change the quantity of an item inside of the cart
export const changeCartQuantity = (mealId, newQuantity) => async (dispatch) => {
  dispatch({
    type: CHANGE_CART_QUANTITY,
    payload: { id: mealId, qty: newQuantity },
  });
};

// Increase the quantity of an item inside of the cart
export const increaseCartQuantity =
  (mealId, quantityToAdd) => async (dispatch) => {
    dispatch({
      type: INCREASE_CART_QUANTITY,
      payload: { id: mealId, qty: quantityToAdd },
    });
  };

// Set the time/date when cart automatically clears
export const setCartExpirationDate = (expirationDate) => async (dispatch) => {
  dispatch({
    type: SET_CART_EXPIRATION_DATE,
    payload: expirationDate,
  });
};

export const setCartIndicatorActive = (isActive) => async (dispatch) => {
  dispatch({
    type: SET_CART_INDICATOR_ACTIVE,
    payload: isActive,
  });
};

export const clearAllCartItems = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ALL_CART_ITEMS,
  });
};

export const setCartDiscountCode = (discountCodeObject) => async (dispatch) => {
  dispatch({
    type: SET_CART_DISCOUNT_CODE,
    payload: discountCodeObject,
  });
};

export const clearCartDiscountCode = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CART_DISCOUNT_CODE,
  });
};
