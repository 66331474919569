import React from "react";
import "./MealDetailsHero.scss";
import BrentoBackButton from "../../Components/UI/BrentoBackButton";
import MealDetailsMacros from "./MealDetailsMacros";
import MealDetailsIngredients from "./MealDetailsIngredients";
import MealDetailsDietaryChip from "./MealDetailsDietaryChip";
import MealDetailsAddToCart from "./MealDetailsAddToCart";
import MealDetailsUnavailable from "./MealDetailsUnavailable";
import DisplayPrice from "../UI/DisplayPrice/DisplayPrice";

const MealDetailsHero = ({ meal, availableMeals, orderingAvailable }) => {
  const {
    _id,
    mealName,
    mealSubtitle,
    mealImageUrl,
    category,
    mealIsGlutenFree,
    mealIsVegetarian,
    calories,
    protein,
    fat,
    carbs,
    description,
    ingredients,
    price,
  } = meal;

  const mealOnMenu = isMealOnTheMenu(availableMeals, _id);

  return (
    <>
      <BrentoBackButton />
      <div className="meal-details-hero">
        <img
          className="meal-details-hero__image"
          src={mealImageUrl}
          alt={mealName}
        />
        <div className="meal-details-hero__content">
          <div className="meal-details-hero__title">
            <h1 className="meal-details-hero__title-header">
              {mealName + " "}
            </h1>
            {mealSubtitle && (
              <h2 className="meal-details-hero__title-sub-head font-light">
                {mealSubtitle}
              </h2>
            )}
            <DisplayPrice price={price} />
          </div>
          {category !== "merch" && category !== "dogTreat" && (
            <MealDetailsMacros
              protein={protein}
              calories={calories}
              fat={fat}
              carbs={carbs}
            />
          )}
          {mealIsGlutenFree || mealIsVegetarian ? (
            <div className="meal-details-hero__dietary-chips">
              {mealIsGlutenFree && (
                <MealDetailsDietaryChip label="Gluten-Free" />
              )}
              {mealIsVegetarian && (
                <MealDetailsDietaryChip label="Vegetarian" />
              )}
            </div>
          ) : null}
          <p className="meal-details-hero__description body-1">{description}</p>
          {mealOnMenu ? (
            orderingAvailable ? (
              <MealDetailsAddToCart meal={meal} />
            ) : (
              <MealDetailsUnavailable />
            )
          ) : (
            <MealDetailsUnavailable type="not-on-menu" />
          )}
          {category !== "merch" && (
            <MealDetailsIngredients ingredients={ingredients} />
          )}
        </div>
      </div>
    </>
  );
};

const isMealOnTheMenu = (currentMenu, mealId) => {
  let menuMealIds = currentMenu.map((meal) => meal._id);
  return menuMealIds.indexOf(mealId) >= 0;
};

export default MealDetailsHero;
