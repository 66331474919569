import React from "react";

const youtube = () => {
  return (
    <svg className="social-icon--youtube" x="0px" y="0px" viewBox="0 0 72 72">
      <circle cx="36" cy="36" r="36" />
      <path
        d="M36-0.2c19.9,0,36,16.1,36,36s-16.1,36-36,36S0,55.7,0,35.8S16.1-0.2,36-0.2z M57.6,24.9c-0.5-1.9-2-3.5-4-4
	C50.1,20,36,20,36,20S21.9,20,18.4,21c-1.9,0.5-3.5,2-4,4c-0.9,3.5-0.9,10.8-0.9,10.8s0,7.3,0.9,10.8c0.5,1.9,2,3.5,4,4
	c3.5,0.9,17.6,0.9,17.6,0.9s14.1,0,17.6-0.9c1.9-0.5,3.5-2,4-4c0.9-3.5,0.9-10.8,0.9-10.8S58.5,28.5,57.6,24.9z M31.5,42.5V29
	l11.7,6.8C43.2,35.8,31.5,42.5,31.5,42.5z"
      />
    </svg>
  );
};

export default youtube;
