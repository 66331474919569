import React, { useState } from "react";
import {
  Button,
  ButtonGroup,
  CircularProgress,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  InputAdornment,
} from "@mui/material";
import BrentoTextField from "../../../UI/BrentoTextField/BrentoTextField";

const DiscountNameField = ({
  formik,
  isMobile,
  autoGenerateCode,
  handleSelectAutoGenerate,
  customDiscountCode,
  customDiscountError,
  setCustomDiscountCode,
}) => {
  return (
    <div className="create-discount-form__field-group">
      <FormControl>
        <FormLabel>Gift card code:</FormLabel>
        <RadioGroup name="autoGenerateCode" row={!isMobile}>
          <FormControlLabel
            onClick={() => handleSelectAutoGenerate(true)}
            checked={autoGenerateCode === true}
            control={<Radio />}
            label="Auto-generate code"
          />
          <FormControlLabel
            onClick={() => handleSelectAutoGenerate(false)}
            checked={autoGenerateCode === false}
            control={<Radio />}
            label="Create a custom code"
          />
        </RadioGroup>
      </FormControl>
      {!autoGenerateCode && (
        <CustomNameField
          formik={formik}
          customDiscountError={customDiscountError}
          customDiscountCode={customDiscountCode}
          setCustomDiscountCode={setCustomDiscountCode}
        />
      )}
    </div>
  );
};

const CustomNameField = ({
  formik,
  customDiscountCode,
  customDiscountError,
}) => {
  return (
    <div className="create-discount-form__custom-name-field">
      <FormLabel>Enter a custom discount code:</FormLabel>
      <br />
      <BrentoTextField
        fullWidth
        name="customDiscountCode"
        onChange={formik.handleChange}
        error={
          (formik.touched.customDiscountCode &&
            formik.errors.customDiscountCode) ||
          customDiscountError
        }
        value={formik.values.customDiscountCode}
        helperText={
          formik.errors.customDiscountCode
            ? formik.errors.customDiscountCode
            : "Enter a unique custom discount code"
        }
        // InputProps={{
        //   endAdornment: (
        //     <InputAdornment position="end">
        //       <ValidationAdornment isLoading={isLoading} isValid={isValid} />
        //     </InputAdornment>
        //   ),
        // }}
        inputProps={{ style: { textTransform: "uppercase" } }}
      ></BrentoTextField>
    </div>
  );
};

// const ValidationAdornment = ({ isLoading, isValid }) => {
//   return <>{isLoading ? <CircularProgress /> : isValid ? "Good" : null}</>;
// };

export default DiscountNameField;
