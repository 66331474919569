import React, { useEffect } from "react";
import Navbar from "../Layout/Navbar/Navbar";
import Alert from "../Alert/Alert";
import AdminNavbar from "../Layout/AdminNavbar/AdminNavbar";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { getDisplayWidth, setDrawerOpen } from "../../actions/layout";
import { Drawer } from "@mui/material";
import { variables } from "../../variables/variables";
import ActionPanel from "../../Components/Admin/MealManager/ActionPanel";
import Footer from "../Layout/Footer/Footer";
import BrentoModal from "./BrentoModal/BrentoModal";
import TestModeIndicator from "./TestModeIndicator/TestModeIndicator";

import "./Layout.scss";

const Layout = ({ children, drawerVariant, drawerAnchor }) => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const authLoading = useSelector((state) => state.auth.loading);
  const displayWidth = useSelector((state) => state.layout.displayWidth);
  const testMode = useSelector((state) => state.admin.testMode);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      dispatch(getDisplayWidth());
    };
    dispatch(getDisplayWidth());

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch, displayWidth]);

  return (
    <div className="layout">
      {testMode && <TestModeIndicator />}
      {isAuthenticated &&
      !authLoading &&
      location.pathname.startsWith("/admin") ? (
        <AdminLayout drawerVariant={drawerVariant} drawerAnchor={drawerAnchor}>
          {children}
        </AdminLayout>
      ) : (
        <StandardLayout>{children}</StandardLayout>
      )}
    </div>
  );
};

const AdminLayout = ({ children }) => {
  const dispatch = useDispatch();
  const drawerOpen = useSelector((state) => state.layout.drawerOpen);
  const drawerComponent = useSelector((state) => state.layout.drawerComponent);
  const selectedMeal = useSelector((state) => state.meal.selectedMeal);
  const isMobile = useSelector(
    (state) => state.layout.displayWidth < variables.breakpoints.lrg
  );

  const handleDrawerToggle = () => {
    dispatch(setDrawerOpen(!drawerOpen));
  };

  return (
    <div className="admin-layout">
      <AdminNavbar />
      <div className="admin-layout__main">
        <div className="admin-layout__primary">
          <Alert />
          {children}
        </div>

        {drawerComponent ? (
          <>
            {drawerComponent === "meal-mgr-action-panel" ? (
              <Drawer
                BackdropProps={{ invisible: true }}
                open={isMobile ? drawerOpen : true}
                anchor={isMobile ? "bottom" : "right"}
                variant="persistent"
                onClose={handleDrawerToggle}
              >
                <div className="admin-layout__drawer">
                  <ActionPanel
                    selectedMeal={selectedMeal}
                    meal={selectedMeal}
                  />
                </div>
              </Drawer>
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  );
};

const StandardLayout = ({ children }) => {
  return (
    <div className="standard-layout">
      <BrentoModal />
      <Navbar />
      <main className="standard-layout__main">
        <Alert />
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
