import { SET_DISCOUNT_MANAGER_VIEW } from "../actions/types";

const initialState = {
  discountManagerView: "gift-card",
};

export default function discountReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_DISCOUNT_MANAGER_VIEW:
      return {
        ...state,
        discountManagerView: payload,
      };
    default:
      return state;
  }
}
