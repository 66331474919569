import React from "react";
import "./ImageUpload.scss";
import { Button } from "@mui/material";
import { formatBytes } from "../../../utils/formatBytes";
import Compressor from "compressorjs";

const ImageUpload = ({
  setFieldValue,
  value,
  newImageFile,
  newImagePreview,
  setNewImagePreview,
  setNewImageFile,
  setNewImageUrl,
}) => {
  const handleImagePreview = async (e) => {
    let file = e.currentTarget.files[0];
    console.log(file);
    if (file) {
      await handleImageCompress(file);
    }
  };

  const handleImageCompress = async (file) => {
    // Compress image if over 200kb
    if (file.size > 200000) {
      new Compressor(file, {
        quality: 0.9,
        maxWidth: 800,
        success(result) {
          let compressedFile = new File([result], result.name);
          handleSetPreview(compressedFile);
          setNewImageFile(compressedFile);
          console.log("compressed successfully");
        },
      });
    } else {
      setNewImageFile(file);
      handleSetPreview(file);
    }
  };

  const handleSetPreview = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    // setFieldValue("mealImageDetails", file);
    reader.onloadend = (e) => {
      setNewImagePreview(reader.result);
      setNewImageUrl(reader.result);
      setFieldValue("mealImageUrl", e.target.result);
      //
    };
  };

  return (
    <div className="image-upload">
      {value ? (
        <ImageUploadPreview
          imageSrc={newImagePreview}
          newImageFile={newImageFile}
        />
      ) : (
        <ImageUploadEmptyState />
      )}
      <Button variant="outlined" color="primary" component="label">
        Upload Image
        <input
          hidden
          id="file"
          name="mealImageUrl"
          type="file"
          accept="image/*"
          onChange={(event) => {
            handleImagePreview(event);
          }}
        />
      </Button>
    </div>
  );
};

const ImageUploadEmptyState = () => {
  return (
    <div className="image-upload-empty">
      <p className="image">No image selected</p>
    </div>
  );
};

const ImageUploadPreview = ({ imageSrc, newImageFile }) => {
  return (
    <div className="image-upload-preview">
      <img
        src={imageSrc}
        className="image-upload-preview__thumbnail"
        alt="meal image preview"
      />
      {newImageFile ? (
        <div className="image-upload-preview__details">
          <div className="image-upload-preview__field">
            <p className="image-upload-preview__label">File:</p>
            <p className="image-upload-preview__value">{newImageFile.name}</p>
          </div>
          <div className="image-upload-preview__field">
            <p className="image-upload-preview__label">Size:</p>
            <p className="image-upload-preview__value">
              {formatBytes(newImageFile.size)}
            </p>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ImageUpload;
