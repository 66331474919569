import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setDrawerComponent } from "../../../actions/layout";
import "./ZipCodeAdmin.scss";
import ZipCodeManager from "../../../Components/Admin/ZipCodeManager/ZipCodeManager";

const ZipCodeAdmin = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setDrawerComponent(null));
  });

  return (
    <div className="zip-code-admin">
      <div className="zip-code-admin-header">
        <h2 className="zip-code-admin-header__title">Zip Code Manager</h2>
        <p className="zip-code-admin-header__label body-2">
          Manage zip codes to determine which areas are available for service
        </p>
      </div>
      <ZipCodeManager />
    </div>
  );
};

export default ZipCodeAdmin;
