import React from "react";
import "./MealDetailsIngredients.scss";

const MealDetailsIngredients = ({ ingredients }) => {
  return (
    <div className="meal-ingredients">
      <p className="meal-ingredients__label">
        <span className="eyebrow-2">Ingredients</span>
      </p>
      <p className="meal-ingredients__ingredients body-2">{ingredients}</p>
    </div>
  );
};

export default MealDetailsIngredients;
