import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import GiftCardCheckoutForm from "../../../Components/GiftCard/GiftCardCheckoutForm/GiftCardCheckoutForm";
import { useSelector, useDispatch } from "react-redux";
import Container from "../../../Components/Layout/Container";
import { Link } from "react-router-dom";
import BrentoBackButton from "../../../Components/UI/BrentoBackButton";
import ScrollToTop from "../../../Components/Layout/ScrollToTop";
import { purchaseGiftCardRequest } from "./utils/purchaseGiftCardRequest";
import { giftCardPaymentIntentRequest } from "./utils/giftCardPaymentIntentRequest";
import GiftCardThankYou from "../../../Components/GiftCard/GiftCardThankYou/GiftCardThankYou";
import "./PurchaseGiftCard.scss";

const PurchaseGiftCard = () => {
  const [giftCardOrderDetails, setGiftCardOrderDetails] = useState(null);
  const [purchaseCompleted, setPurchaseCompleted] = useState(false);
  // // Redux Hooks
  // const dispatch = useDispatch();
  // const giftCardPaymentIntentId = useSelector(
  //   (state) => state.payment.giftCardPaymentIntentId
  // );
  // const testMode = useSelector((state) => state.admin.testMode);

  // // Checkout States
  // const [giftCardCheckoutLoading, setGiftCardCheckoutLoading] = useState(true);
  // const [giftCardCheckoutError, setGiftCardCheckoutError] = useState(true);
  // const [giftCardCheckoutComplete, setGiftCardCheckoutComplete] =
  //   useState(true);

  // const initializeGiftCardCheckout = async (giftCardPaymentIntentId) => {
  //   console.log(giftCardPaymentIntentId);
  //   try {
  //     let res = await giftCardPaymentIntentRequest();
  //   } catch (err) {
  //     console.log(err)
  //   }
  // };

  // useEffect(() => {
  //   initializeGiftCardCheckout(giftCardPaymentIntentId);
  // }, []);

  const finalizeGiftCardPurchase = (giftCardOrderDetails) => {
    console.log("Finalizing gift-card order");
    setGiftCardOrderDetails(giftCardOrderDetails);
    setPurchaseCompleted(true);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link
          rel="canonical"
          href="https://getbrentobox.com/gift-cards/purchase-gift-card"
        />
        <title>
          Purchase Digital Gift Card | Brento Box - Fresh Meal Prep Made Easy
        </title>
        <meta
          name="description"
          content="Complete the checkout form to purchase a Brento Box digital gift card for your friends and family!"
        />
      </Helmet>
      <ScrollToTop />
      <Container>
        {purchaseCompleted ? (
          <GiftCardThankYou giftCardOrderDetails={giftCardOrderDetails} />
        ) : (
          <div className="purchase-gift-card">
            <BrentoBackButton />
            <div className="purchase-gift-card__primary-content">
              <h1 className="header-2">Gift Card Checkout</h1>
              <p className="body-1">
                Complete the form below to purchase and send a Brento Box
                Digital Gift Card.
              </p>
              <GiftCardCheckoutForm
                purchaseGiftCardRequest={purchaseGiftCardRequest}
                giftCardPaymentIntentRequest={giftCardPaymentIntentRequest}
                purchaseCompleted={purchaseCompleted}
                finalizeGiftCardPurchase={finalizeGiftCardPurchase}
              />
              <div className="purchase-gift-card__disclaimer">
                <p>
                  For information on how we manage your personal information,
                  visit our <Link to="/privacy-policy">Privacy Policy</Link>.
                </p>
              </div>
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

export default PurchaseGiftCard;
