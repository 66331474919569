import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getOrderData, getPaginatedOrders } from "../../../actions/orders";
import { getMenuData } from "../../../actions/menu";
import OrderManagerHeader from "../../../Components/Admin/OrderManager/OrderManagerHeader/OrderManagerHeader";
import { setDrawerComponent } from "../../../actions/layout";
import OrderStats from "../../../Components/Admin/OrderManager/OrderStats/OrderStats";
import OrderList from "../../../Components/Admin/OrderManager/OrderList/OrderList";
import OrderMealBreakdown from "../../../Components/Admin/OrderManager/OrderMealBreakdown/OrderMealBreakdown";
import BrentoTabs from "../../../Components/UI/BrentoTabs";
import BrentoTab from "../../../Components/UI/BrentoTab/BrentoTab";
import Spinner from "../../../Components/UI/Spinner/Spinner";
import "../../../variables/_type.scss";
import "./Orders.scss";
import moment from "moment";

const Orders = () => {
  const dispatch = useDispatch();
  const testMode = useSelector((state) => state.admin.testMode);
  const orderStartDate = useSelector(
    (state) => state.menu.activeMenu.orderStartDate
  );
  const menuDataLoading = useSelector((state) => state.menu.menuDataLoading);
  const orderByDate = useSelector((state) => state.menu.activeMenu.orderByDate);
  const orderData = useSelector((state) => state.orders.orderData);
  const paginatedOrders = useSelector((state) => state.orders.paginatedOrders);
  const paginatedOrdersLoading = useSelector(
    (state) => state.orders.paginatedOrdersLoading
  );
  const [dateMin, setDateMin] = useState(orderStartDate);
  const [dateMax, setDateMax] = useState(orderByDate);
  const [activeTab, setActiveTab] = useState("orderList");

  const handleSelectTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    dispatch(setDrawerComponent(null));
    dispatch(getMenuData(testMode));
  }, []);

  useEffect(() => {
    dispatch(getOrderData(dateMin, dateMax, testMode));
    console.log(dateMax);
    console.log(moment(dateMax).format("YYYY-MM-DDThh:mm"));
    if (orderStartDate && orderByDate) {
      dispatch(getOrderData(dateMin, dateMax, testMode));
      dispatch(getPaginatedOrders(dateMin, dateMax, testMode));
    }
  }, [dateMin, dateMax]);

  return (
    <div className="order-manager">
      {!menuDataLoading && dateMin && dateMax ? (
        <>
          <OrderManagerHeader
            dateMin={dateMin}
            dateMax={dateMax}
            setDateMin={setDateMin}
            setDateMax={setDateMax}
          />
          {paginatedOrdersLoading ? (
            <Spinner size="large" />
          ) : (
            <>
              <OrderStats orderData={orderData} />
              <BrentoTabs
                value={activeTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleSelectTab}
              >
                <BrentoTab
                  label="Orders"
                  value={"orderList"}
                  id="order-tab-1"
                  aria-controls="panel-1"
                />
                <BrentoTab
                  label="Meals"
                  value={"mealBreakdown"}
                  id="order-tab-2"
                  aria-controls="panel-2"
                />
              </BrentoTabs>
              <div
                hidden={activeTab !== "orderList"}
                role="tabpanel"
                id="panel-1"
                aria-labelledby="order-tab-1"
              >
                <OrderList
                  orderData={orderData}
                  paginatedOrders={paginatedOrders}
                />
              </div>
              <div
                hidden={activeTab !== "mealBreakdown"}
                role="tabpanel"
                id="panel-2"
                aria-labelledby="order-tab-2"
              >
                <OrderMealBreakdown orderData={orderData} />
              </div>
            </>
          )}
        </>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Orders;
