import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Prompt } from "react-router-dom";
import { addNewMeal } from "../../../actions/meal";
import { setDrawerComponent } from "../../../actions/layout";
import {
  TextField,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import LoadingButton from "@mui/lab/LoadingButton";
import ImageUpload from "../ImageUpload/ImageUpload";
import "./AddMealForm.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(1),
    },
    "& .MuiFormGroup-root": {
      width: "25ch",
    },
    "& .MuiTextField-root": {
      marginRight: "1rem",
    },
    "& .MuiButton-root": {
      borderRadius: 2,
    },
  },
}));

const validationSchema = yup.object({
  mealName: yup
    .string("Enter meal name")
    .min(4, "Meal name should be at least 4 characters")
    .required("Meal name is required"),
  mealSubtitle: yup
    .string("Enter meal subtitle")
    .min(4, "Meal name should be at least 4 characters"),
  category: yup
    .string("Select a category")
    .oneOf(["meal", "extra", "juice", "merch", "dogTreat", "bulk"]),
  calories: yup.number("Enter meal calories").required("Calories required"),
  protein: yup.number("Enter meal protein").required("Protein required"),
  fat: yup.number("Enter meal fat").required("Fat required"),
  carbs: yup.number("Enter meal carbs").required("Carbs required"),
  ingredients: yup
    .string("Enter meal ingredients")
    .min(25, "Ingredients should be at least 50 characters")
    .required("Meal ingredients are required"),
  price: yup.number("Enter meal price").required("Price is required"),
  mealImageUrl: yup
    .string("Upload an image for this meal")
    .required("You must add an image"),
  description: yup
    .string("Add meal description")
    .min(50, "Description should be at least 50 characters")
    .required("You must add a description"),
});

const AddMealForm = () => {
  const [newImagePreview, setNewImagePreview] = useState(null);
  const [newImageFile, setNewImageFile] = useState(null);
  const [newImageUrl, setNewImageUrl] = useState(null);
  const [submitSuccessful, setSubmitSuccessful] = useState(false);

  useEffect(() => {
    dispatch(setDrawerComponent(null));
    console.log(newImageFile);
  });

  const handleSelectCategory = (value) => {
    formik.setFieldValue("category", value);
  };

  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const addMealLoading = useSelector((state) => state.meal.addMealLoading);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      mealName: "",
      mealSubtitle: "",
      category: "meal",
      mealIsVegetarian: false,
      mealIsGlutenFree: false,
      mealIsNew: true,
      calories: 0,
      protein: 0,
      fat: 0,
      carbs: 0,
      ingredients: "",
      price: 10,
      mealImageUrl: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      try {
        dispatch(addNewMeal(values, newImageFile, newImageUrl)).then((res) => {
          setSubmitSuccessful(true);
          history.push("/admin/meals");
        });
      } catch (err) {
        setSubmitSuccessful(false);
        console.log(err);
      }
    },
  });

  return (
    <form className={classes.root} onSubmit={formik.handleSubmit}>
      <Prompt
        when={formik.dirty && !submitSuccessful}
        message="Are you sure you want to leave this page? Any unsaved changes will be lost"
      />
      <div className="form-container">
        <TextField
          id="mealName"
          name="mealName"
          label="Meal Name"
          variant="outlined"
          margin="normal"
          fullWidth={true}
          value={formik.values.mealName}
          onChange={formik.handleChange}
          error={formik.touched.mealName && formik.errors.mealName}
          helperText={formik.touched.mealName && formik.errors.mealName}
        />
        <TextField
          id="mealSubTitle"
          name="mealSubtitle"
          label="Meal Sub-Title"
          variant="outlined"
          margin="normal"
          fullWidth={true}
          value={formik.values.mealSubtitle}
          onChange={formik.handleChange}
          error={formik.touched.mealSubtitle && formik.errors.mealSubtitle}
          helperText={formik.touched.mealSubtitle && formik.errors.mealSubtitle}
        />
        <div className="form-field-row">
          <div className="form-field-row__left">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    id="mealIsVegetarian"
                    name="mealIsVegetarian"
                    checked={formik.values.mealIsVegetarian}
                    onChange={formik.handleChange}
                    color="primary"
                  />
                }
                label="Vegetarian"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="mealIsNew"
                    name="mealIsNew"
                    checked={formik.values.mealIsNew}
                    onChange={formik.handleChange}
                    color="primary"
                  />
                }
                label="New"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    id="mealIsGlutenFree"
                    name="mealIsGlutenFree"
                    checked={formik.values.mealIsGlutenFree}
                    onChange={formik.handleChange}
                    color="primary"
                  />
                }
                label="Gluten Free"
              />
            </FormGroup>
            <div className="macro-row">
              <TextField
                id="price"
                name="price"
                label="Price"
                variant="outlined"
                margin="normal"
                value={formik.values.price}
                onChange={formik.handleChange}
                error={formik.touched.price && formik.errors.price}
                helperText={formik.touched.price && formik.errors.price}
                inputProps={{ step: "any", min: 0 }}
                type="number"
                fullWidth
              />
              <FormControl sx={{ margin: 2, marginLeft: 0 }} fullWidth>
                <InputLabel id="category-select-input-label">
                  Category
                </InputLabel>
                <Select
                  labelId="category-select-input-label"
                  id="demo-simple-select"
                  value={formik.values.category}
                  label="Category"
                  onChange={(e) => handleSelectCategory(e.target.value)}
                >
                  <MenuItem value="meal">Meal</MenuItem>
                  <MenuItem value="extra">Extra</MenuItem>
                  <MenuItem value="juice">Juice</MenuItem>
                  <MenuItem value="bulk">Bulk</MenuItem>
                  <MenuItem value="dogTreat">Dog Treat</MenuItem>
                  <MenuItem value="merch">Merch</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="macro-row">
              <TextField
                id="calories"
                name="calories"
                label="Calories"
                variant="outlined"
                margin="normal"
                value={formik.values.calories}
                onChange={formik.handleChange}
                type="number"
                fullWidth={true}
                error={formik.touched.calories && formik.errors.calories}
                helperText={formik.touched.calories && formik.errors.calories}
              />
              <TextField
                id="protein"
                name="protein"
                label="Protein (grams)"
                variant="outlined"
                margin="normal"
                value={formik.values.protein}
                onChange={formik.handleChange}
                type="number"
                fullWidth={true}
                error={formik.touched.protein && formik.errors.protein}
                helperText={formik.touched.protein && formik.errors.protein}
              />
            </div>
            <div className="macro-row">
              <TextField
                id="carbs"
                name="carbs"
                label="Carbs (grams)"
                variant="outlined"
                margin="normal"
                value={formik.values.carbs}
                onChange={formik.handleChange}
                type="number"
                fullWidth={true}
                error={formik.touched.carbs && formik.errors.carbs}
                helperText={formik.touched.carbs && formik.errors.carbs}
              />
              <TextField
                id="fat"
                name="fat"
                label="Fat (grams)"
                variant="outlined"
                margin="normal"
                value={formik.values.fat}
                onChange={formik.handleChange}
                type="number"
                fullWidth={true}
                error={formik.touched.fat && formik.errors.fat}
                helperText={formik.touched.fat && formik.errors.fat}
              />
            </div>
          </div>
          <div className="form-field-row__right">
            <ImageUpload
              onChange={formik.handleChange}
              imageSrc={formik.values.mealImage}
              value={formik.values.mealImageUrl}
              setFieldValue={formik.setFieldValue}
              newImagePreview={newImagePreview}
              newImageFile={newImageFile}
              setNewImagePreview={setNewImagePreview}
              setNewImageFile={setNewImageFile}
              setNewImageUrl={setNewImageUrl}
            />
          </div>
        </div>
        <TextField
          id="ingredients"
          name="ingredients"
          label="Ingredients"
          variant="outlined"
          margin="normal"
          value={formik.values.ingredients}
          onChange={formik.handleChange}
          fullWidth={true}
          multiline
          rows={4}
          error={formik.touched.ingredients && formik.errors.ingredients}
          helperText={formik.touched.ingredients && formik.errors.ingredients}
        />
        <TextField
          id="description"
          name="description"
          label="Description"
          variant="outlined"
          margin="normal"
          value={formik.values.description}
          onChange={formik.handleChange}
          error={formik.touched.description && formik.errors.description}
          helperText={formik.touched.description && formik.errors.description}
          fullWidth={true}
          multiline
          rows={4}
        />
      </div>
      <LoadingButton
        pending={addMealLoading}
        disabled={!formik.dirty}
        key={`${!formik.dirty}`}
        color="primary"
        variant="contained"
        size="large"
        type="submit"
      >
        Add New Meal
      </LoadingButton>
    </form>
  );
};

export default AddMealForm;
