import axios from "axios";
import { SET_DISCOUNT_MANAGER_VIEW } from "../actions/types";

// Use this to persist tab selection in gift card manager
// ADMIN ONLY
export const setDiscountManagerView = (managerView) => (dispatch) => {
  try {
    dispatch({ type: SET_DISCOUNT_MANAGER_VIEW, payload: managerView });
  } catch (err) {
    console.log(err);
  }
};
