import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ScrollToTop from "../../../Components/Layout/ScrollToTop";
import Spinner from "../../../Components/UI/Spinner/Spinner";
import Container from "../../../Components/Layout/Container";
import MultiStepCheckoutForm from "../../../Components/Checkout/CheckoutForm/MultiStepCheckout/MultiStepCheckoutForm";
import CheckoutSummary from "../../../Components/Checkout/CheckoutSummary/CheckoutSummary";
import CheckoutStepper from "../../../Components/Checkout/CheckoutStepper/CheckoutStepper";
import ErrorState from "../../../Components/UI/ErrorState/ErrorState";
import { validationGenerator } from "../../../Components/Checkout/CheckoutForm/MultiStepCheckout/Validation";
import { paymentIntentRequest } from "./utils/paymentIntentRequest";
import {
  setPaymentIntentId,
  setPaymentIntentExpiry,
} from "../../../actions/payment";
import { clearCartDiscountCode } from "../../../actions/cart";
import ThankYou from "../../../Components/Checkout/ThankYou/ThankYou";
import { trackBeginCheckout } from "../../../tracking/trackBeginCheckout";
import "./Checkout.scss";

const Checkout = () => {
  // Redux Hooks
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.cartItems);
  const cartDiscountCode = useSelector((state) => state.cart.cartDiscountCode);
  const paymentIntentId = useSelector((state) => state.payment.paymentIntentId);
  const testMode = useSelector((state) => state.admin.testMode);

  //  Checkout States
  const [checkoutStartTracked, setCheckoutStartTracked] = useState(false);
  const [checkoutLoading, setCheckoutLoading] = useState(true);
  const [checkoutError, setCheckoutError] = useState(null);
  const [checkoutComplete, setCheckoutComplete] = useState(false);

  // Muli-Step Handling States
  const [activeStep, setActiveStep] = useState(1);
  const [completedStepCount, setCompletedStepCount] = useState(0);
  const [activeValidationSchema, setActiveValidationSchema] =
    useState("contact");

  // Order Handling States
  const [orderAttemptDetails, setOrderAttemptDetails] = useState(null);
  const [stripeClientSecret, setStripeClientSecret] = useState(null);
  const [completedOrderDetails, setCompletedOrderDetails] = useState(null);

  const initializeCheckout = async (cartItems, paymentIntentId) => {
    try {
      let res = await paymentIntentRequest(
        cartItems,
        paymentIntentId,
        cartDiscountCode,
        testMode
      );
      setStripeClientSecret(res.stripeClientSecret);
      setOrderAttemptDetails(res.orderDetails);
      dispatch(setPaymentIntentId(res.stripePaymentIntentId));
      dispatch(setPaymentIntentExpiry(res.paymentIntentExpiry));
      setCheckoutLoading(false);
    } catch (err) {
      setCheckoutError(err);
      setCheckoutLoading(false);
    }
  };

  const finalizeCheckoutProcess = (orderDetails) => {
    dispatch(clearCartDiscountCode());
    setCompletedOrderDetails(orderDetails);
    setCheckoutComplete(true);
  };

  useEffect(() => {
    initializeCheckout(cartItems, paymentIntentId);
  }, []);

  // TRACKING
  // Track begin checkout
  useEffect(() => {
    if (orderAttemptDetails && !checkoutStartTracked) {
      trackBeginCheckout(cartItems, orderAttemptDetails.orderTotal.total);
      setCheckoutStartTracked(true);
    }
  }, [orderAttemptDetails]);

  // // Track completed steps in checkout
  // useEffect(() => {
  //   if (orderAttemptDetails) {
  //     if (completedStepCount === 1) {
  //       trackAddShippingInfo(
  //         cartItems,
  //         orderAttemptDetails.orderTotal.total,
  //         cartDiscountCode
  //       );
  //     } else if (completedStepCount === 2) {
  //       trackAddPaymentInfo(
  //         cartItems,
  //         orderAttemptDetails.orderTotal.total,
  //         cartDiscountCode
  //       );
  //     }
  //   }
  // }, [orderAttemptDetails, completedStepCount]);

  let generatedValidationSchema = validationGenerator(activeValidationSchema);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://getbrentobox.com/checkout" />
        <title>Checkout | Brento Box - Fresh Meal Prep Made Easy</title>
        <meta
          name="description"
          content="Complete the checkout form to place your order so we can deliver you your tasty meals!"
        />
      </Helmet>
      <ScrollToTop />
      <Container>
        {checkoutLoading ? (
          <Spinner size="large" />
        ) : checkoutError ? (
          <ErrorState />
        ) : checkoutComplete ? (
          <ThankYou completedOrderDetails={completedOrderDetails} />
        ) : (
          <div className="checkout">
            <div className="checkout__header">
              <h1 className="header-2">Checkout</h1>
              <CheckoutStepper
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                completedStepCount={completedStepCount}
                setActiveValidationSchema={setActiveValidationSchema}
              />
            </div>
            <div className="checkout__main">
              <CheckoutSummary
                orderTotal={orderAttemptDetails.orderTotal}
                discountCode={cartDiscountCode}
              />
              <MultiStepCheckoutForm
                clientSecret={stripeClientSecret}
                orderDetails={orderAttemptDetails}
                setOrderAttemptDetails={setOrderAttemptDetails}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                setCompletedStepCount={setCompletedStepCount}
                setCheckoutError={setCheckoutError}
                validationSchema={generatedValidationSchema}
                setActiveValidationSchema={setActiveValidationSchema}
                finalizeCheckoutProcess={finalizeCheckoutProcess}
                paymentIntentId={paymentIntentId}
                discountCode={cartDiscountCode}
                checkoutComplete={checkoutComplete}
                cartItems={cartItems}
              />
            </div>
            <div className="checkout__disclaimer">
              <p>
                For information on how we manage your personal information,
                visit our <Link to="/privacy-policy">Privacy Policy</Link>.
              </p>
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

export default Checkout;
