import ReactGA from "react-ga4";
import { formatAllLineItems } from "./utils/formatAllLineItems";

export const trackBeginCheckout = (
  cartItems,
  total,
  paymentType = "Credit Card"
) => {
  let eventArgs = {
    currency: "USD",
    value: total / 100,
    payment_type: paymentType,
    items: formatAllLineItems(cartItems),
  };
  ReactGA.gtag("event", "begin_checkout", eventArgs);
};

// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=10902621433338295728-NA&client_type=gtag#begin_checkout

// gtag("event", "begin_checkout", {
//   currency: "USD",
//   value: 7.77,
//   coupon: "SUMMER_FUN",
//   items: [
//     {
//       item_id: "SKU_12345",
//       item_name: "Stan and Friends Tee",
//       affiliation: "Google Merchandise Store",
//       coupon: "SUMMER_FUN",
//       discount: 2.22,
//       index: 0,
//       item_brand: "Google",
//       item_category: "Apparel",
//       item_category2: "Adult",
//       item_category3: "Shirts",
//       item_category4: "Crew",
//       item_category5: "Short sleeve",
//       item_list_id: "related_products",
//       item_list_name: "Related Products",
//       item_variant: "green",
//       location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
//       price: 9.99,
//       quantity: 1,
//     },
//   ],
// });
