export const generateDiscountStripeDataObject = (values, cardElement) => {
  let dataObject = {
    payment_method: {
      card: cardElement,
      billing_details: {
        name: values.contactName,
        email: values.contactEmail,
        phone: values.contactPhone,
        address: {
          postal_code: values.billingZipCode,
        },
      },
    },
  };
  return dataObject;
};
