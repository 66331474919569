import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/auth";
import Container from "../Layout/Container";
import {
  FormControl,
  InputLabel,
  TextField,
  Button,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./Login.scss";

const Login = ({ login, isAuthenticated }) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const { email, password } = formData;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    login(email, password);
  };

  //Redirect if logged in

  if (isAuthenticated) {
    return <Redirect to="/admin" />;
  }
  return (
    <Container>
      <div className="login">
        <h1>Sign In</h1>
        <form className={classes.root} onSubmit={(e) => onSubmit(e)}>
          <div className="form-group">
            <TextField
              type="email"
              fullWidth={true}
              variant="outlined"
              className={classes.textField}
              onChange={(e) => onChange(e)}
              required
              label="Email address"
              name="email"
            />
            <FormControl
              fullWidth={true}
              variant="outlined"
              className={classes.textField}
              required
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                label="Password"
                name="password"
                value={password}
                onChange={(e) => onChange(e)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={70}
              />
            </FormControl>
            {/* <input
              type="email"
              placeholder="Email Address"
              name="email"
              onChange={(e) => onChange(e)}
              required
            /> */}
          </div>
          <div className="form-group">
            {/* <input
              type="password"
              placeholder="Password"
              name="password"
              minLength="6"
              value={password}
              onChange={(e) => onChange(e)}
            /> */}
          </div>

          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            size="large"
            fullWidth={true}
            onClick={onSubmit}
            type="submit"
          >
            Login
          </Button>
        </form>
        {/* <p className="my-1">
          Don't have an account? <Link to="/register">Sign Up</Link>
        </p> */}
      </div>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginBottom: theme.spacing(2),
  },
}));

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Login);
