import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getGiftCardAdmin } from "../../../actions/giftCards";
import { SET_GIFT_CARD_DATA_LOADING } from "../../../actions/types";
import BrentoBackButton from "../../../Components/UI/BrentoBackButton";
import GiftCardDetailsHero from "../../../Components/Admin/GiftCardDetails/GiftCardDetailsHero/GiftCardDetailsHero";
import GiftCardDetailsTable from "../../../Components/Admin/GiftCardDetails/GiftCardDetailsTable/GiftCardDetailsTable";
import "./GiftCardDetails.scss";

const GiftCard = (props) => {
  const dispatch = useDispatch();
  const testMode = useSelector((state) => state.admin.testMode);
  const giftCardData = useSelector((state) => state.giftCards.giftCardData);
  const giftCardDataLoading = useSelector(
    (state) => state.giftCards.giftCardDataLoading
  );
  const {
    match: { params },
  } = props;

  useEffect(() => {
    dispatch({ type: SET_GIFT_CARD_DATA_LOADING, payload: true });
    dispatch(getGiftCardAdmin(testMode, params.id));
  }, []);

  return (
    <div>
      <BrentoBackButton />
      {giftCardDataLoading ? (
        <p>Loading...</p>
      ) : giftCardData ? (
        <>
          <GiftCardDetailsHero
            discountCode={giftCardData.discountCode}
            giftCardId={giftCardData._id}
          />
          <GiftCardDetailsTable giftCardData={giftCardData} />
        </>
      ) : (
        <p>No gift card found</p>
      )}
    </div>
  );
};

export default GiftCard;
