import React from "react";
import ScrollToTop from "../../Components/Layout/ScrollToTop";
import Container from "../../Components/Layout/Container";
import EmailImage from "../../img/brento-box-email.jpg";
import EmailGlyph from "../../img/brento-box-email-glyph.png";
import "./BrandAssets.scss";

const BrandAssets = () => {
  return (
    <>
      <div className="brand-assets">
        <ScrollToTop />
        <Container>
          <img src={EmailImage} alt="Brento Box Logo" />
          <img src={EmailGlyph} alt="Brento Box Glyph" />
        </Container>
      </div>
    </>
  );
};

export default BrandAssets;
