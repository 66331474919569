import axios from "axios";
import {
  GET_ORDER_DATA,
  SET_ORDER_DATA_ERROR,
  GET_PAGINATED_ORDERS,
  SET_PAGINATED_ORDERS_ERROR,
  SET_PAGINATED_ORDERS_LOADING,
  SET_ORDER_CONFIRMATION,
  SET_ORDER_INSTRUCTIONS,
} from "../actions/types";
import moment from "moment";
// import setAuthToken from "../utils/setAuthToken";

// Get order data
// ADMIN ONLY
export const getOrderData =
  (dateMin, dateMax, testMode) => async (dispatch) => {
    try {
      const requestPath = testMode
        ? "/api/test-orders/data"
        : "/api/orders/data";
      let res = await axios.get(requestPath, {
        params: {
          dateMin: moment(dateMin).toISOString(),
          dateMax: moment(dateMax).toISOString(),
        },
      });
      dispatch({
        type: GET_ORDER_DATA,
        payload: res.data,
      });
      console.log(res.data);
      return res;
    } catch (err) {
      console.log(err);
      dispatch({
        type: SET_ORDER_DATA_ERROR,
        payload: err,
      });
    }
  };

// Update an existing meal's information & details
// ADMIN ONLY
export const getPaginatedOrders =
  (dateMin, dateMax, testMode) => async (dispatch) => {
    dispatch({ type: SET_PAGINATED_ORDERS_LOADING, payload: true });
    try {
      const requestPath = testMode ? "/api/test-orders" : "/api/orders";
      let res = await axios.get(requestPath, {
        params: {
          dateMin: moment(dateMin).toISOString(),
          dateMax: moment(dateMax).toISOString(),
        },
      });
      dispatch({
        type: GET_PAGINATED_ORDERS,
        payload: res.data.length > 0 ? res.data : [],
      });
      dispatch({ type: SET_PAGINATED_ORDERS_LOADING, payload: false });
      return res;
    } catch (err) {
      console.log(err);
      dispatch({
        type: SET_PAGINATED_ORDERS_ERROR,
        payload: err,
      });
      dispatch({ type: SET_PAGINATED_ORDERS_LOADING, payload: false });
    }
  };

// Set the order confirmation details for express checkout only (at the moment)
// Public
export const setOrderConfirmationData = (orderDetails) => (dispatch) => {
  try {
    dispatch({ type: SET_ORDER_CONFIRMATION, payload: orderDetails });
  } catch (err) {
    console.log(err);
  }
};

// Updates special delivery instructions specifically for express checkout
// Public
export const setOrderDeliveryInstructions =
  (updatedInstructions) => (dispatch) => {
    try {
      dispatch({ type: SET_ORDER_INSTRUCTIONS, payload: updatedInstructions });
    } catch (err) {
      console.log(err);
    }
  };
