import DeliveryZone from "../../img/DeliveryZone.jpg";

const FAQContent = {
  general: [
    {
      question: "How do I order?",
      answer:
        "New menus are released each week, typically on Tuesday morning, and ordering is available until that Friday at 9:00 AM. Simply add items to your cart and complete the online checkout process.",
    },
    {
      question: "How do I reheat the meals?",
      answer:
        "After removing condiment cup (if there is one) place the microwave safe container in the microwave (or transfer to plate) and heat for 2-3 minutes and enjoy.",
    },
    {
      question: "Do you offer any vegetarian options?",
      answer:
        "Sometimes, but not always. Our recipes are carefully designed to keep as low of a calorie content as possible, while still supplying plenty of macro nutrients (including protein). Meat can be a great way to incorporate a complete protein source into your diet. However, we understand that many people have religious or lifestyle choices that may require a more animal-friendly diet. If you would like us to include more vegetarian options, let us know!",
    },
    {
      question: "What do I do with the containers when I am done?",
      answer:
        "The containers are recyclable so please rinse them out and recycle them if you are able.",
    },
    {
      question: "Can I customize my order?",
      answer: "At this time we do not offer custom meals, sorry!",
    },
    {
      question: "I am looking to gain muscle, are these meals good for that?",
      answer:
        "Absolutely. When paired with a rigorous exercise routine, these meals can help you perform. The meals range from 400-500 calories. If you are looking to gain lean muscle mass, the best way to do that is to eat a caloric surplus of 400-500 calories daily. These meals provide ample protein for muscle recovery, carbs for energy and fats for satiation.",
    },
    {
      question: "I am looking to lose fat, are these meals good for that?",
      answer:
        "Yes! The meals range from 400-500 calories. If you are looking to looking to lose weight, simply incorporate the meals into your daily caloric intake and aim to put yourself in a caloric deficit daily. Be sure to consult with your doctor about your weight-loss goals to find your specific needs.",
    },
    {
      question: "Where are your meals prepared?",
      answer:
        "All Brento Box meals are prepared at Brento Box Kitchen, located at 905 N 16th Street, Phoenix AZ, 85006.",
    },
  ],
  delivery: [
    {
      question: "Do you deliver?",
      answer:
        "Of course! That's just one part of what makes our service so great. We make it as easy for you as possible to enjoy delicious meals without having to go hunt them down. As long as you meet the $40 minimum per order, we are more than happy to bring it directly to you.",
    },
    {
      question: "How much is the delivery fee?",
      answer:
        "Brento Box customers who meet the $40 order minimum enjoy a flat-rate of a $5 delivery fee. Not too bad compared to some of the delivery fees the other guys charge you.",
    },
    {
      question: "How does delivery work?",
      answer:
        "By 8am Sunday morning you will have received an automated text from us. This will have your two hour delivery window. When your driver is 30 minutes away, or has just completed the stop prior to yours, you will get another automated text from us notifying you that your driver is en route and you will be able to click the link to track. If you are home, please do us a favor and answer the door for your driver and receive the meal bag, this cuts down on cooler bags left, and will get your meals inside and cooling down right away. If you live in apartment complex you may need to meet the delivery driver at the front.",
    },
    {
      question: "Where do you deliver to?",
      answer: "Our delivery zone is as follows:",
      img: {
        src: DeliveryZone,
        alt: "Delivery zone map that covers central and east Phoenix metro area.",
      },
    },
    {
      question: "What if I am not home when my order is delivered?",
      answer:
        "If you are not home at time of delivery, your driver will leave your meals in an insulated cooler with an ice pack at your door in the shade. In the summer, we recommend getting these inside and in the fridge within an hour. Some people leave a hard shell cooler with ice/ice packs out if they know they will not be home which helps keep them cool longer than the cooler bags. If we leave a cooler bag for you, please leave it out front or hand it to your driver the following week to return.",
    },
    {
      question: "Am I able to pick up my order?",
      answer:
        "Not at this time, unfortunately. In order to ensure that our business practices meet local health requirements, we follow a strict process for preparing and handling all of our meals. At this time we are unable to incorporate pick up into that process, but it may be available in the future. ",
    },
    {
      question: "Can I give my delivery driver instructions for delivery?",
      answer:
        "Yes! Thanks to our new online order processing feature, you can provide your delivery driver special instructions such as directions or gate codes. During the checkout process, there is a field that allows you to provide these instructions. It's not required, but definitely recommended to ensure a smooth delivery process if you live in an apartment complex or gated community.",
    },
  ],
  payment: [
    {
      question: "Do you accept online ordering?",
      answer:
        "Yes! All orders should be placed through the online checkout process to ensure the best possible experience.",
    },
    {
      question: "How do you handle online payments?",
      answer:
        "We handle all of our online payments through Stripe, a leading financial services provider. We don't directly handle any of your payment information, all of it is sent to Stripe and handled on their end.",
    },
    {
      question: "What payment methods do you take?",
      answer:
        "All of our online orders processed through Stripe can be paid with any major debit or credit card, such as Visa, Mastercard, American Express, and more.",
    },
  ],
};

export default FAQContent;
