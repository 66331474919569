import {
  GET_ORDER_DATA,
  SET_ORDER_DATA_ERROR,
  GET_PAGINATED_ORDERS,
  SET_PAGINATED_ORDERS_ERROR,
  SET_PAGINATED_ORDERS_LOADING,
  SET_ORDER_CONFIRMATION,
  SET_ORDER_INSTRUCTIONS,
  CLEAR_ORDER_CONFIRMATION,
} from "../actions/types";

const initialState = {
  orderData: [],
  orderDataError: false,
  paginatedOrders: [],
  paginatedOrdersError: false,
  paginatedOrdersLoading: true,
  // Replace below value with null before pushing to prod
  // orderConfirmationData: {
  //   _id: "62b09cc6b4fa3d4bb98a7791",
  //   orderId: "1655741638469-9420",
  //   lineItems: [{ mealName: "The Gobb Father", quantity: 5 }],
  //   orderTotal: 5930,
  //   specialInstructions: "",
  // },
  orderConfirmationData: {
    _id: null,
    orderId: null,
    lineItems: [],
    orderTotal: null,
    specialInstructions: null,
  },
};

export default function ordersReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ORDER_DATA:
      return {
        ...state,
        orderData: payload,
      };
    case SET_ORDER_DATA_ERROR:
      return {
        ...state,
        orderDataError: payload,
      };
    case GET_PAGINATED_ORDERS:
      return {
        ...state,
        paginatedOrders: payload,
      };
    case SET_PAGINATED_ORDERS_ERROR:
      return {
        ...state,
        paginatedOrdersError: payload,
      };
    case SET_PAGINATED_ORDERS_LOADING:
      return {
        ...state,
        paginatedOrdersLoading: payload,
      };
    case SET_ORDER_CONFIRMATION:
      return {
        ...state,
        orderConfirmationData: payload,
      };
    case SET_ORDER_INSTRUCTIONS:
      return {
        ...state,
        orderConfirmationData: {
          ...state.orderConfirmationData,
          specialInstructions: payload,
        },
      };
    case CLEAR_ORDER_CONFIRMATION:
      return {
        ...state,
        orderConfirmationData: null,
      };
    default:
      return state;
  }
}
