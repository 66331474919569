import React from "react";
import BrentoTextField from "../../../UI/BrentoTextField/BrentoTextField";
import { FormLabel } from "@mui/material";

const RecipientInfoFields = ({ formik }) => {
  return (
    <div className="create-discount-form__field-group">
      <div className="gift-card-checkout__field">
        <FormLabel>Where would you like to send the discount to?</FormLabel>
        <BrentoTextField
          fullWidth={true}
          label="Recipient email address"
          value={formik.values.recipientEmail}
          name="recipientEmail"
          onChange={formik.handleChange}
          type="email"
          error={
            formik.touched.recipientEmail && formik.errors.recipientEmail
              ? true
              : false
          }
          helperText={
            formik.touched.recipientEmail && formik.errors.recipientEmail
          }
        ></BrentoTextField>
      </div>
      <div className="gift-card-checkout__field">
        <FormLabel>
          Add a special message to be included in the email sent to the above
          address:
        </FormLabel>
        <BrentoTextField
          value={formik.values.specialMessage}
          name="specialMessage"
          onChange={formik.handleChange}
          fullWidth={true}
          multiline={true}
          rows={6}
          error={
            formik.touched.specialMessage && formik.errors.specialMessage
              ? true
              : false
          }
          helperText={
            formik.touched.specialMessage && formik.errors.specialMessage
          }
        />
        <p className="character-count">{`${formik.values.specialMessage.length}/250 characters remaining`}</p>
      </div>
    </div>
  );
};

export default RecipientInfoFields;
