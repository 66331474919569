import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 2,
    minWidth: 120,
    "& .MuiOutlinedInput-root": {
      borderRadius: 2,
    },
    "& .MuiSelect-filled": {
      borderBottom: 0,
      borderRadius: 4,
    },
  },
}));

const BrentoSelect = ({
  label,
  id,
  name,
  onChange,
  value,
  options = [],
  variant = "outlined",
  error,
  fullWidth,
  disabled,
  formHelperText,
  native = false,
}) => {
  const classes = useStyles();
  return (
    <FormControl
      className="brento-select"
      classes={{ root: classes.root }}
      variant={variant}
      error={error}
    >
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Select
        disableUnderline={variant === "filled"}
        error={error}
        native={native}
        value={value}
        label={label}
        onChange={onChange}
        variant={variant}
        inputProps={{
          name: name,
          id: id,
        }}
      >
        {native
          ? options.map((option) => {
              return (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              );
            })
          : options.map((option) => {
              return (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              );
            })}
      </Select>
      <FormHelperText>{formHelperText}</FormHelperText>
    </FormControl>
  );
};

export default BrentoSelect;
