import React, { forwardRef, useRef, useImperativeHandle } from "react";

// const CardInput = forwardRef(
//   ({ component: Component, inputRef, ...other }, ref) => {
//     const elementRef = useRef();
//     useImperativeHandle(inputRef, () => ({
//       focus: () => elementRef.current.focus,
//     }));

//     return (
//       <Component
//         onReady={(element) => (elementRef.current = element)}
//         ref={ref}
//         {...other}
//       />
//     );
//   }
// );

const CardInput = React.forwardRef((props, ref) => {
  const { component: Component, ...other } = props;

  // implement `InputElement` interface
  React.useImperativeHandle(ref, () => ({
    focus: () => ref.current.focus,
    // logic to focus the rendered component from 3rd party belongs here

    // hiding the value e.g. react-stripe-elements
  }));

  // `Component` will be your `SomeThirdPartyComponent` from below
  return <Component {...other} />;
});

export default CardInput;
