import React from "react";
import CardInput from "./CardInput";
import CheckoutFieldset from "../../CheckoutFieldset";
import BrentoTextField from "../../../../UI/BrentoTextField/BrentoTextField";
import {
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";

const CCDetails = ({
  cardNumberError,
  setCardNumberError,
  cardExpiryError,
  setCardExpiryError,
  cardCvcError,
  setCardCvcError,
}) => {
  const cardOptions = {
    // hidePostalCode: true,
    style: {
      base: {
        color: "#011212",
        fontFamily:
          '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#9da4a4",
        },
      },
      invalid: {
        color: "#ef5350",
        iconColor: "#ff867c",
      },
    },
  };

  const validateCardField = (e, setErrorHook) => {
    // formik.setFieldValue("ccnumber", );
    if (e.error) {
      setErrorHook(e.error.message);
    } else {
      setErrorHook(null);
    }
  };

  return (
    <>
      <CheckoutFieldset label="Payment details">
        <BrentoTextField
          name="ccnumber"
          variant="outlined"
          label="Credit Card Number"
          required
          fullWidth
          onChange={(e) => validateCardField(e, setCardNumberError)}
          error={Boolean(cardNumberError)}
          helperText={cardNumberError}
          InputProps={{
            inputComponent: CardInput,
            inputProps: {
              component: CardNumberElement,
              options: cardOptions,
            },
          }}
          InputLabelProps={{ shrink: true }}
        />
        <div className="checkout-form__cc-details-row">
          <BrentoTextField
            name="cc-exp"
            label="Card Expiration Date"
            variant="outlined"
            required
            fullWidth
            onChange={(e) => validateCardField(e, setCardExpiryError)}
            error={Boolean(cardExpiryError)}
            helperText={cardExpiryError}
            InputProps={{
              inputComponent: CardInput,
              inputProps: {
                component: CardExpiryElement,
                options: cardOptions,
              },
            }}
            InputLabelProps={{ shrink: true }}
          />
          <BrentoTextField
            name="ccnumber"
            variant="outlined"
            label="Card CVC"
            required
            fullWidth
            onChange={(e) => validateCardField(e, setCardCvcError)}
            error={Boolean(cardCvcError)}
            helperText={cardCvcError}
            InputProps={{
              inputComponent: CardInput,
              inputProps: {
                component: CardCvcElement,
                options: cardOptions,
              },
            }}
            InputLabelProps={{ shrink: true }}
          />
        </div>
      </CheckoutFieldset>
    </>
  );
};

export default CCDetails;
