import axios from "axios";

export const verifyEmailRequest = async (
  emailValue,
  discountCode,
  promoCodeId,
  testMode
) => {
  let emailRequestData = {
    emailValue: emailValue,
    discountCode: discountCode,
  };

  let res = await axios({
    method: "post",
    url: testMode
      ? `/api/test-promo-code/verify/${promoCodeId}`
      : `/api/promo-code/verify/${promoCodeId}`,
    data: emailRequestData,
    headers: { "Content-Type": "application/json" },
  });

  return res.data;
};
