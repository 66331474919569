import React from "react";
import { Button } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 2,
    padding: "0.75rem 1.5rem",
    "&.MuiButton-outlined": {
      borderWidth: 2,
    },
    "&.MuiButton-contained": {
      "&.Mui-disabled": {
        backgroundColor: theme.palette.primary.lightest,
        color: theme.palette.primary.light,
      },
    },
    "&.MuiButton-containedSizeLarge": {
      padding: "1rem 2rem",
    },
    "&.MuiButton-outlinedSizeSmall": {
      padding: "0.5rem 1rem",
    },
    "&.MuiButton-textSizeLarge": {
      textTransform: "none",
    },
  },
}));

const BrentoButton = ({
  startIcon,
  component,
  to,
  endIcon,
  children,
  onClick,
  size,
  fullWidth,
  variant = "contained",
  disabled,
}) => {
  const classes = useStyles();
  return (
    <Button
      classes={{ root: classes.root, outlined: classes.outlined }}
      onClick={onClick ? () => onClick() : null}
      variant={variant}
      color="primary"
      component={component}
      to={to}
      startIcon={startIcon}
      endIcon={endIcon}
      size={size}
      fullWidth={fullWidth}
      key={`${disabled}`}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

export default BrentoButton;
