import React from "react";
import "./GiftCardDetailsHero.scss";

const GiftCardDetailsHero = ({ discountCode, giftCardId }) => {
  return (
    <div className="gift-card-details-hero">
      <p className="eyebrow-1">Discount code:</p>
      <h1 className="header-2">{discountCode}</h1>
    </div>
  );
};

export default GiftCardDetailsHero;
