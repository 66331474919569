import {
  ADD_NEW_MEAL,
  DELETE_MEAL,
  GET_MEAL,
  ADD_NEW_MEAL_LOADING,
  MEAL_LOADING,
  GET_ALL_MEALS,
  UPDATE_MEAL,
  UPDATE_MEAL_LOADING,
  SELECT_MEAL,
} from "../actions/types";

const initialState = {
  meal: null,
  mealLoading: true,
  addMealLoading: false,
  meals: [],
  mealsLoading: true,
  updateMealLoading: false,
  selectedMeal: null,
};

export default function mealReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_NEW_MEAL:
      return {
        ...state,
        meals: [...state.meals, payload],
      };
    case GET_MEAL:
      return {
        ...state,
        meal: payload,
      };
    case MEAL_LOADING:
      return {
        ...state,
        mealLoading: payload,
      };
    case ADD_NEW_MEAL_LOADING:
      return {
        ...state,
        addMealLoading: payload,
      };
    case GET_ALL_MEALS:
      return {
        ...state,
        meals: payload,
        mealsLoading: false,
      };
    case UPDATE_MEAL:
      return {
        ...state,
        meal: payload,
      };
    case UPDATE_MEAL_LOADING:
      return {
        ...state,
        updateMealLoading: payload,
      };
    case DELETE_MEAL:
      return {
        ...state,
        meals: state.meals.filter((meal) => meal._id !== payload),
      };
    case SELECT_MEAL:
      return {
        ...state,
        selectedMeal: payload,
      };
    default:
      return state;
  }
}
