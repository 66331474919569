export const filterAnalyticsData = (
  filterBy,
  analyticsData,
  thisYear,
  thisQuarter,
  thisMonth
) => {
  if (filterBy === "all-time") {
    return analyticsData.data;
  } else if (filterBy === "this-year") {
    return analyticsData[thisYear].data;
  } else if (filterBy === "this-quarter") {
    if (analyticsData[thisYear][thisQuarter]) {
      return analyticsData[thisYear][thisQuarter].data;
    }
  } else {
    if (
      analyticsData[thisYear][thisQuarter] &&
      analyticsData[thisYear][thisQuarter][thisMonth]
    ) {
      return analyticsData[thisYear][thisQuarter][thisMonth].data;
    }
  }
};
