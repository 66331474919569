import { getRightNow } from "./getRightNow";
import moment from "moment";

export const checkIfToday = (dateToCheck) => {
  let rightNow = getRightNow();
  let recipientEmailDate = dateToCheck;
  console.log(rightNow);
  const isToday =
    moment(recipientEmailDate).format("YYYY-MM-DD") ===
    moment(rightNow).format("YYYY-MM-DD");
  console.log(rightNow);
  console.log(recipientEmailDate);
  console.log(isToday);
  return isToday;
};
