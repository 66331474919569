import ReactGA from "react-ga4";
import { formatLineItem } from "./utils/formatLineItem";
// For tracking category lists
// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=10902621433338295728-NA&client_type=gtag#view_item_list

export const trackViewItemList = (listItems, listId, listTitle) => {
  let eventArgs = {
    item_list_id: listId,
    item_list_name: listTitle,
    items: listItems.forEach((item, index) => formatLineItem(item, 1, index)),
  };
  ReactGA.gtag("event", "view_item_list", eventArgs);
};
// gtag("event", "view_item_list", {
//   item_list_id: "related_products",
//   item_list_name: "Related products",
//   items: [
//     {
//       item_id: "SKU_12345",
//       item_name: "Stan and Friends Tee",
//       affiliation: "Google Merchandise Store",
//       coupon: "SUMMER_FUN",
//       discount: 2.22,
//       index: 0,
//       item_brand: "Google",
//       item_category: "Apparel",
//       item_category2: "Adult",
//       item_category3: "Shirts",
//       item_category4: "Crew",
//       item_category5: "Short sleeve",
//       item_list_id: "related_products",
//       item_list_name: "Related Products",
//       item_variant: "green",
//       location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
//       price: 9.99,
//       quantity: 1,
//     },
//   ],
// });
