import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { TextField } from "@mui/material";
import moment from "moment";
import axios from "axios";
import "./OrderManagerHeader.scss";
import { CSVLink } from "react-csv";

const OrderManagerHeader = ({ dateMin, dateMax, setDateMin, setDateMax }) => {
  const handleChangeDate = (e, dateHook) => {
    dateHook(moment(e.target.value).toISOString());
  };

  useEffect(() => {
    console.log("Order start date: " + dateMin);
  });

  return (
    <div className="order-manager-header">
      <h2 className="order-manager-header__title">Order Manager</h2>
      <p className="order-manager-header__label">
        Review your orders between the selected dates below
      </p>
      <div className="order-manager-header__date-selection-panel">
        <div>
          <TextField
            label="Order Start"
            type="datetime-local"
            value={moment(dateMin).format("YYYY-MM-DDTHH:mm")}
            onChange={(e) => handleChangeDate(e, setDateMin)}
            margin="normal"
            variant="standard"
          />
          <TextField
            label="Order End"
            type="datetime-local"
            inputProps={{
              min: moment(dateMin).format("YYYY-MM-DDTHH:mm"),
            }}
            value={moment(dateMax).format("YYYY-MM-DDTHH:mm")}
            onChange={(e) => handleChangeDate(e, setDateMax)}
            margin="normal"
            variant="standard"
          />
        </div>
        <div>
          <DownloadCSV dateMin={dateMin} dateMax={dateMax} />
        </div>
      </div>
    </div>
  );
};

const DownloadCSV = ({ dateMin, dateMax }) => {
  const [csvData, setCsvData] = useState([]);
  const testMode = useSelector((state) => state.admin.testMode);
  const csvLink = useRef();

  useEffect(() => {
    console.log(dateMin);
  });

  const handleCSVRequest = async (dateMin, dateMax, testMode) => {
    let requestUrl = testMode ? "/api/test-orders/csv" : "/api/orders/csv";
    console.log(dateMin);
    try {
      let res = await axios.get(requestUrl, {
        params: { dateMin: dateMin, dateMax: dateMax },
      });
      setCsvData(res.data);
      csvLink.current.link.click();
      console.log(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <button
        className="order-manager-header__csv-btn"
        onClick={() => handleCSVRequest(dateMin, dateMax, testMode)}
      >
        Download as CSV
      </button>
      <CSVLink
        data={csvData}
        filename="orders.csv"
        className="hidden"
        ref={csvLink}
        target="_blank"
      />
    </>
  );
};

export default OrderManagerHeader;
