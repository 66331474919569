import React from "react";
import { Link } from "react-router-dom";
import "./GiftCardTable.scss";
import BrentoButton from "../../../../Components/UI/BrentoButton";
import moment from "moment";

const GiftCardTable = ({ tableTitle, giftCards, showCreateButton = false }) => {
  return (
    <div className="gift-card-table">
      <h3 className="gift-card-table__title header-6">{tableTitle}</h3>
      <div className="gift-card-table__headers">
        <p>Code</p>
        <p>Initial Balance</p>
        <p>Current Balance</p>
        <p className="hide-sm">Created</p>
        <p>Email sent</p>
      </div>
      <ul className="gift-card-table__list">
        {giftCards && giftCards.length > 0 ? (
          giftCards
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
            .map((giftCard) => (
              <GiftCardItem
                giftCardId={giftCard._id}
                discountCode={giftCard.discountCode}
                initialBalance={giftCard.initialBalance}
                currentBalance={giftCard.currentBalance}
                emailSent={giftCard.emailSent}
                deliveryDate={giftCard.deliveryDate}
                createdAt={giftCard.createdAt}
              />
            ))
        ) : (
          <p className="empty-state">No gift cards found</p>
        )}
      </ul>
      <div className="gift-card-table__controls">
        <p className="gift-card-table__card-count">
          <b>{giftCards && giftCards.length}</b> gift cards found
        </p>
        {showCreateButton && (
          <Link to="/admin/discount/gift-card/create-gift-card">
            <BrentoButton>Create Gift Card code</BrentoButton>
          </Link>
        )}
      </div>
    </div>
  );
};

const GiftCardItem = ({
  giftCardId,
  discountCode,
  initialBalance,
  currentBalance,
  emailSent,
  createdAt,
  deliveryDate,
}) => {
  const formatAsDollar = (numberCents) => {
    const valueInDollars = numberCents / 100;
    return valueInDollars.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };
  return (
    <li className="gift-card-item" key={discountCode}>
      <Link
        className="gift-card-item__link"
        to={`/admin/discount/gift-card/${giftCardId}`}
      >
        <p className="gift-card-item__discount-code">{discountCode}</p>
      </Link>
      <p className="gift-card-item__field">{formatAsDollar(initialBalance)}</p>
      <p className="gift-card-item__field">{formatAsDollar(currentBalance)}</p>
      <p className="gift-card-item__field hide-sm">
        <span className="from-now">
          {createdAt ? moment(createdAt).fromNow() : null}
        </span>
      </p>
      <p className="gift-card-item__field">
        {emailSent ? (
          <span className="yes">Yes</span>
        ) : (
          <span className="no">No</span>
        )}
      </p>
    </li>
  );
};

export default GiftCardTable;
