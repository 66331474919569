import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import Section from "../../Layout/Section/Section";
import Container from "../../Layout/Container";
import BrentoIcon from "../../UI/BrentoIcon";
import "./Testimonials.scss";

const testimonialContent = [
  {
    quote:
      "Brento Boxes have become a staple in my weekly meal plans. I’m saving time and energy not having to worry about preparing my meals, they cost less than eating out, and they’re delicious! Fresh ingredients, and different meals each week so I never get tired of eating the same old thing. And, they make it easy to track my macros; I’m leaning out and building muscle the healthy way.",
    name: "Ian P.",
  },
  {
    quote:
      "I first tried a Brento Box about six months ago, and now it is what I eat for lunch everyday of the week (and sometimes for dinner!). There hasn’t been one Brento box that was not absolutely delicious, and on top of that, they turned me into a vegetable lover!...Healthy, delicious, convenient, and well worth the money!",
    name: "Sharon K.",
  },
  {
    quote:
      "I have been ordering Brento Boxes for months now and absolutely love it! The meals are filling and have great taste. Every meal I’ve had has been awesome. I have seen my physical performance improve and highly recommend replacing your current eating habits with Brento Boxes. You won’t regret it!",
    name: "Brian K.",
  },

  {
    quote:
      "Not only do Brento Boxes taste really good and I know that what I’m eating is clean & healthy, but they also save me so much time during the week...Every week I look forward to seeing what my different options are and I love that each one comes with how many calories are in it. I’m really bad at keeping track of my caloric intake but that helps a lot that it’s on the box!",
    name: "Ali M.",
  },
];

const Testimonials = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [slideDirection, setSlideDirection] = useState("right");

  const handleIncrement = () => {
    setSlideDirection("right");
    if (activeIndex === testimonialContent.length - 1) {
      setActiveIndex(0);
    } else {
      setActiveIndex(activeIndex + 1);
    }
  };

  const handleDecrement = () => {
    setSlideDirection("left");
    if (activeIndex === 0) {
      setActiveIndex(testimonialContent.length - 1);
    } else {
      setActiveIndex(activeIndex - 1);
    }
  };

  return (
    <Section sectionTitle="What People Are Saying" sectionColor="light">
      <Container>
        <div className="testimonials">
          <TestimonialControls
            activeIndex={activeIndex}
            handleIncrement={handleIncrement}
            handleDecrement={handleDecrement}
          />
          {testimonialContent.map((item, index) => {
            return (
              <Testimonial
                inProp={index === activeIndex}
                slideDirection={slideDirection}
                quote={item.quote}
                name={item.name}
                key={item.name}
              />
            );
          })}

          <p className="body-1 testimonials__indicators">
            {activeIndex + 1} / {testimonialContent.length}
          </p>
        </div>
      </Container>
    </Section>
  );
};

const Testimonial = ({ quote, name, slideDirection, inProp }) => {
  return (
    <CSSTransition
      mountOnEnter
      unmountOnExit
      in={inProp}
      timeout={500}
      className="testimonial"
      classNames={`testimonial--${slideDirection}`}
    >
      <div>
        <div className="testimonial__content">
          <p className="testimonial__quote">"{quote}"</p>
          <p className="testimonial__name">– {name}</p>
        </div>
      </div>
    </CSSTransition>
  );
};

const TestimonialControls = ({
  activeIndex,
  handleIncrement,
  handleDecrement,
}) => {
  return (
    <div className="testimonials__controls">
      <button onClick={() => handleDecrement()}>
        <BrentoIcon icon="leftArrow" />
      </button>
      <button onClick={() => handleIncrement()}>
        <BrentoIcon icon="rightArrow" />
      </button>
    </div>
  );
};

export default Testimonials;
