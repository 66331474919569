import axios from "axios";
// @route   POST api/test-payment/update-payment-intent/:id

export const handleRemoveDiscountRequest = async (
  cartItems,
  paymentIntentId,
  testMode
) => {
  let requestBody = {
    cartItems: cartItems,
  };
  try {
    let res = await axios({
      method: "post",
      data: requestBody,
      url: testMode
        ? `/api/test-payment/update-payment-intent/${paymentIntentId}`
        : `/api/payment/update-payment-intent/${paymentIntentId}`,
      // headers: { "Content-Type": "application/json" },
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};
