import React from "react";
import Hero from "../Components/LandingPage/Hero/Hero";
import HowItWorks from "../Components/LandingPage/HowItWorks/HowItWorks";
import WhyBrentoBox from "../Components/LandingPage/WhyBrentoBox/WhyBrentoBox";
import Testimonials from "../Components/LandingPage/Testimonials/Testimonials";
import ScrollToTop from "../Components/Layout/ScrollToTop";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Brento Box | Fresh Meal Prep Made Easy</title>
        <link rel="canonical" href="https://getbrentobox.com" />
        <meta
          name="description"
          content="Brento Box is a meal prep service based in Phoenix, Arizona. We craft delicious meals each week and deliver them right to your doorstep."
        />
      </Helmet>
      <ScrollToTop />
      <Hero />
      <HowItWorks />
      <WhyBrentoBox />
      <Testimonials />
    </>
  );
};

export default Home;
