import * as yup from "yup";

export const validationSchema = yup.object({
  discountCode: yup
    .string("Enter a unique promo code")
    .required("You must enter a unique promo code")
    .matches(
      "^[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$",
      "Start and end with letters or numbers, use - for spaces"
    )
    .min(8, "Promo code must be at least 8 characters")
    .max(20, "Promo code cannot exceed 20 characters"),
  percentOff: yup
    .number()
    .min(1, "Percentage must be over 1")
    .max(100, "Percentage cannot exceeed 100"),
  discountType: yup.string("Select a discount type"),
  maxUseCount: yup.number().min(1, "Max use cannot be less than 1"),
  teamMemberEmails: yup.array().when("discountType", {
    is: "team-member-discount",
    then: yup
      .array()
      .of(
        yup
          .string()
          .required("Email address is required")
          .email("Enter a valid email address")
      ),
  }),
  greetingMessage: yup
    .string("Enter a custom greeting message")
    .max(50, "Custom greeting cannot exceed 50 characters"),
});
