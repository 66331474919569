import React from "react";
import CategoryTemplate from "../CategoryTemplate";
import { Helmet } from "react-helmet";

const Bulk = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://getbrentobox.com/menu/dog-treats" />
        <title>
          Menu - Dog Treats | Brento Box - Fresh Meal Prep Made Easy
        </title>
        <meta
          name="description"
          content="Brento Box has teamed up with AZ Dog Treats to offer tasty treats for your furry friends as well!"
        />
      </Helmet>
      <CategoryTemplate
        categoryTitle="Dog Treats"
        categoryValue={"dogTreat"}
        categoryTrackingId={"dog-treats"}
      />
    </>
  );
};

export default Bulk;
