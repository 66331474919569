import React from "react";
import CategoryTemplate from "../CategoryTemplate";
import { Helmet } from "react-helmet";

const Bulk = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://getbrentobox.com/menu/a-la-carte" />
        <title>
          Menu - A La Carte | Brento Box - Fresh Meal Prep Made Easy
        </title>
        <meta
          name="description"
          content="Brento Box now offers a la carte options if you would like to purchase proteins in bulk for your own meal prep ideas."
        />
      </Helmet>
      <CategoryTemplate
        categoryTitle="A La Carte"
        categoryValue={"bulk"}
        categoryTrackingId={"bulk"}
      />
    </>
  );
};

export default Bulk;
