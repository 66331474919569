import moment from "moment";

export const determineMenuStatus = (menuStartDate, menuOrderByDate) => {
  const currentTime = moment().format();
  if (
    moment(currentTime).isAfter(menuStartDate) &&
    moment(currentTime).isBefore(menuOrderByDate)
  ) {
    return true;
  } else {
    return false;
  }
  // set menu status as "active"
  // if current time is before publish date, set menu status as "pending"
};
