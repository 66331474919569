import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllMeals } from "../../../../actions/meal";
import "./OrderMealBreakdown.scss";

const OrderMealBreakdown = ({ orderData }) => {
  const meals = useSelector((state) => state.meal.meals);
  const mealsLoading = useSelector((state) => state.meal.mealsLoading);
  const dispatch = useDispatch();
  const [mealBreakdownData, setMealBreakdownData] = useState([]);

  const generateMealBreakdown = (mealTotals, meals) => {
    let dataArray = [];
    if (meals && mealTotals) {
      meals.forEach((meal) => {
        console.log(meal);
        if (mealTotals[meal._id] !== undefined) {
          dataArray.push({ ...meal, qty: mealTotals[meal._id].quantity });
        }
      });
    }
    return dataArray;
  };

  useEffect(() => {
    if (meals && meals.length > 0) {
      setMealBreakdownData(
        generateMealBreakdown(orderData.mealBreakdown, meals)
      );
    } else {
      dispatch(getAllMeals());
    }
  }, [meals]);

  return (
    <div className="meal-breakdown">
      {mealsLoading ? (
        <p>Loading...</p>
      ) : mealBreakdownData.length > 0 ? (
        <ul>
          {mealBreakdownData
            .sort((a, b) => b.qty - a.qty)
            .map((meal) => {
              return (
                <BreakdownItem
                  mealImageUrl={meal.mealImageUrl}
                  mealName={meal.mealName}
                  qty={meal.qty}
                />
              );
            })}
        </ul>
      ) : (
        <p>No results</p>
      )}
    </div>
  );
};

const BreakdownItem = ({ mealName, mealImageUrl, qty }) => {
  return (
    <li className="meal-breakdown__item">
      <img src={mealImageUrl} alt={mealName} />
      <div className="meal-breakdown__item-content">
        <p className="meal-breakdown__item-name">{mealName}</p>
        <p className="meal-breakdown__item-qty">{qty}</p>
      </div>
    </li>
  );
};

export default OrderMealBreakdown;
