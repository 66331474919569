import React from "react";
import MenuItem from "../MenuItem/MenuItem";
import { useSelector } from "react-redux";
import PlaceholderItem from "../MenuItem/PlaceholderItem/PlaceholderItem";
import GiftCardOption from "../../GiftCard/GiftCardOption/GiftCardOption";
import { variables } from "../../../variables/variables";
import "./MenuItemGroup.scss";

const MenuItemGroup = ({
  menuItems,
  menuLoading,
  categoryLabel,
  includeGiftCard = false,
  id,
}) => {
  const isMobile = useSelector(
    (state) => state.layout.displayWidth < variables.breakpoints.lrg
  );

  return (
    <div className="menu-item-group">
      {categoryLabel && <h2 className="header-4">{categoryLabel}</h2>}
      {menuLoading ? (
        <InitialPlaceholderGroup isMobile={isMobile} id={id} />
      ) : (
        <ul className="menu-items" id={id}>
          {menuItems && menuItems.length > 0
            ? menuItems.map((meal) => (
                <MenuItem
                  menuItem={meal}
                  key={meal._id}
                  isMobile={isMobile}
                  listId={id}
                  listName={categoryLabel}
                />
              ))
            : null}
          {includeGiftCard && <GiftCardOption />}
        </ul>
      )}
    </div>
  );
};

const InitialPlaceholderGroup = ({ isMobile, id }) => {
  return (
    <ul className="menu-items" id={id}>
      <li className="menu-item" key={1}>
        <PlaceholderItem isMobile={isMobile} />
      </li>
      <li className="menu-item" key={2}>
        <PlaceholderItem isMobile={isMobile} />
      </li>
      <li className="menu-item" key={3}>
        <PlaceholderItem isMobile={isMobile} />
      </li>
      <li className="menu-item" key={4}>
        <PlaceholderItem isMobile={isMobile} />
      </li>
      <li className="menu-item" key={5}>
        <PlaceholderItem isMobile={isMobile} />
      </li>
      <li className="menu-item" key={6}>
        <PlaceholderItem isMobile={isMobile} />
      </li>
    </ul>
  );
};

export default MenuItemGroup;
