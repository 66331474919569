import React from "react";
import Skeleton from "@mui/material/Skeleton";
import "./PlaceholderItem.scss";

const PlaceholderItem = ({ isMobile }) => {
  return (
    <>
      <Skeleton variant="rectangular" height={isMobile ? 160 : 256} />
      <Skeleton variant="text" width="100%" height={isMobile ? 20 : 24} />
      <Skeleton variant="text" width="65%" height={isMobile ? 20 : 24} />
    </>
  );
};

export default PlaceholderItem;
