import React, { useState } from "react";
import { useSelector } from "react-redux";
import AnalyticsFilter from "./AnalyticsFilter/AnalyticsFilter";
import AnalyticsStats from "./AnalyticsStats/AnalyticsStats";
import { getDateValues } from "./utils/getDateValues";
import AnalyticsBreakdown from "./AnalyticsBreakdown/AnalyticsBreakdown";
import "./Analytics.scss";

const Analytics = () => {
  const dateValues = getDateValues();
  const analyticsData = useSelector((state) => state.analytics.analyticsData);
  const filterBy = useSelector((state) => state.analytics.analyticsFilterBy);

  const analyticsDataLoading = useSelector(
    (state) => state.analytics.analyticsDataLoading
  );

  return (
    <div className="analytics">
      {!analyticsDataLoading && analyticsData ? (
        <>
          <AnalyticsFilter
            analyticsData={analyticsData}
            filterBy={filterBy}
            dateValues={dateValues}
          />
          <AnalyticsStats
            analyticsData={analyticsData}
            filterBy={filterBy}
            dateValues={dateValues}
          />
          <AnalyticsBreakdown
            analyticsData={analyticsData}
            filterBy={filterBy}
            dateValues={dateValues}
          />
        </>
      ) : null}
    </div>
  );
};

export default Analytics;
