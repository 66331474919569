// LEGACY ACTIONS

export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const GET_REPOS = "GET_REPOS";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const ACCOUNT_DELETED = "ACCOUNT_DELETED";
export const GET_POSTS = "GET_POSTS";
export const POST_ERROR = "POST_ERROR";
export const UPDATE_LIKES = "UPDATE_LIKES";
export const DELETE_POST = "DELETE_POST";
export const ADD_POST = "ADD_POST";
export const GET_POST = "GET_POST";
export const ADD_COMMENT = "ADD_COMMENT";
export const REMOVE_COMMENT = "REMOVE_COMMENT";

// BRENTO-BOX ACTIONS ----------------------------------//

// ANALYTICS
export const GET_ANALYTICS_DATA = "GET_ANALYTICS_DATA";
export const GET_ANALYTICS_DATA_LOADING = "GET_ANALYTICS_DATA_LOADING";
export const GET_ANALYTICS_DATA_ERROR = "GET_ANALYTICS_DATA_ERROR";
export const SET_ANALYTICS_FILTER_BY = "SET_ANALYTICS_FILTER_BY";
export const SET_ANALYTICS_BREAKDOWN_BY = "SET_ANALYTICS_BREAKDOWN_BY";

// MEALS
export const ADD_NEW_MEAL = "ADD_NEW_MEAL";
export const ADD_NEW_MEAL_ERROR = "ADD_NEW_MEAL_ERROR";
export const ADD_NEW_MEAL_LOADING = "ADD_NEW_MEAL_LOADING";
export const UPDATE_MEAL = "UPDATE_MEAL";
export const UPDATE_MEAL_SUCCESS = "UPDATE_MEAL_SUCCESS";
export const UPDATE_MEAL_ERROR = "UPDATE_MEAL_ERROR";
export const UPDATE_MEAL_LOADING = "UPDATE_MEAL_LOADING";
export const DELETE_MEAL = "DELETE_MEAL";
export const DELETE_MEAL_SUCCESS = "DELETE_MEAL_SUCCESS";
export const DELETE_MEAL_ERROR = "DELETE_MEAL_ERROR";
export const GET_MEAL = "GET_MEAL";
export const GET_MEAL_ERROR = "GET_MEAL_ERROR";
export const MEAL_LOADING = "MEAL_LOADING";
export const GET_ALL_MEALS = "GET_ALL_MEALS";
export const GET_ALL_MEALS_ERROR = "GET_ALL_MEALS_ERROR";
export const SELECT_MEAL = "SELECT_MEAL";

// MENU
export const GET_FULL_MENU = "GET_FULL_MENU";
export const GET_MENU_DATA = "GET_MENU_DATA";
export const MENU_LOADING = "MENU_LOADING";
export const MENU_DATA_LOADING = "MENU_DATA_LOADING";
export const GET_MENU_ERROR = "GET_MENU_ERROR";
export const GET_MENU_DATA_ERROR = "GET_MENU_DATA_ERROR";
export const GET_MENU_SUCCESS = "GET_MENU_SUCCESS";
export const SET_ORDERING_AVAILABLE = "SET_ORDERING_AVAILABLE";
export const UPDATE_MENU = "UPDATE_MENU";
export const UPDATE_MENU_SUCCESS = "UPDATE_MENU_SUCCESS";
export const UPDATE_MENU_ERROR = "UPDATE_MENU_ERROR";
export const SET_PREF_MENU_VIEW = "SET_PREF_MENU_VIEW";

// LAYOUT / DISPLAY
export const GET_DISPLAY_WIDTH = "GET_DISPLAY_WIDTH";
export const SET_DRAWER_OPEN = "SET_DRAWER_OPEN";
export const SET_DRAWER_COMPONENT = "SET_DRAWER_COMPONENT";
export const SET_MODAL_OPEN = "SET_MODAL_OPEN";
export const SET_MODAL_COMPONENT = "SET_MODAL_COMPONENT";

// SHOPPING CART
export const GET_CART_ITEMS = "GET_CART_ITEMS";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CHANGE_CART_QUANTITY = "CHANGE_CART_QUANTITY";
export const INCREASE_CART_QUANTITY = "INCREASE_CART_QUANTITY";
export const CLEAR_ALL_CART_ITEMS = "CLEAR_ALL_CART_ITEMS";
export const SET_CART_LOADING = "SET_CART_LOADING";
export const SET_CART_EXPIRATION_DATE = "SET_CART_EXPIRATION_DATE";
export const SET_CART_INDICATOR_ACTIVE = "SET_CART_INDICATOR_ACTIVE";
export const SET_CART_DISCOUNT_CODE = "SET_CART_DISCOUNT_CODE";
export const CLEAR_CART_DISCOUNT_CODE = "CLEAR_CART_DISCOUNT_CODE";

// CUSTOMER
export const SET_STRIPE_CUSTOMER_ID = "SET_STRIPE_CUSTOMER_ID";
export const CREATE_NEW_CUSTOMER = "CREATE_NEW_CUSTOMER";

// PAYMENT
export const SET_PAYMENT_INTENT_ID = "SET_PAYMENT_INTENT_ID";
export const SET_GIFTCARD_PAYMENT_INTENT_ID = "SET_GIFTCARD_PAYMENT_INTENT_ID";
export const SET_PAYMENT_INTENT_EXPIRATION = "SET_PAYMENT_INTENT_EXPIRATION";
export const CLEAR_PAYMENT_INTENT = "CLEAR_PAYMENT_INTENT";
export const CLEAR_GIFTCARD_PAYMENT_INTENT = "CLEAR_GIFTCARD_PAYMENT_INTENT";

// ORDERS
export const GET_ORDER_DATA = "GET_ORDER_DATA";
export const SET_ORDER_DATA_ERROR = "SET_ORDER_DATA_ERROR";
export const GET_PAGINATED_ORDERS = "GET_PAGINATED_ORDERS";
export const SET_PAGINATED_ORDERS_ERROR = "SET_PAGINATED_ORDERS_ERROR";
export const SET_PAGINATED_ORDERS_LOADING = "SET_PAGINATED_ORDERS_LOADING";
export const SET_ORDER_CONFIRMATION = "SET_ORDER_CONFIRMATION";
export const SET_ORDER_INSTRUCTIONS = "SET_ORDER_INSTRUCTIONS";
export const CLEAR_ORDER_CONFIRMATION = "CLEAR_ORDER_CONFIRMATION";

// MISC / ADMIN
export const SET_TEST_MODE = "SET_TEST_MODE";

// DISCOUNTS
export const SET_DISCOUNT_MANAGER_VIEW = "SET_DISCOUNT_MANAGER_VIEW";

// GIFT CARDS
export const GET_ALL_GIFT_CARDS = "GET_ALL_GIFT_CARDS";
export const GET_GIFT_CARD_DATA = "GET_GIFT_CARD_DATA";
export const SET_GIFT_CARD_DATA_LOADING = "SET_GIFT_CARD_DATA_LOADING";
export const SET_GIFT_CARD_MANAGER_VIEW = "SET_GIFT_CARD_MANAGER_VIEW";

// PROMO CODES
export const GET_ALL_PROMO_CODES = "GET_ALL_PROMO_CODES";
export const GET_PROMO_CODE_DATA = "GET_PROMO_CODE_DATA";
export const SET_PROMO_CODE_DATA_LOADING = "SET_PROMO_CODE_DATA_LOADING";

// ZIP CODES
export const GET_ALL_ZIP_CODES = "GET_ALL_ZIP_CODES";
export const GET_ALL_ZIP_CODES_LOADING = "GET_ALL_ZIP_CODES_LOADING";
export const SET_ADD_ZIP_CODE_INPUT_OPEN = "SET_ADD_ZIP_CODE_INPUT_OPEN";
export const ADD_NEW_ZIP_CODE = "ADD_NEW_ZIP_CODE";
export const ADD_NEW_ZIP_CODE_ERROR = "ADD_NEW_ZIP_CODE_ERROR";
export const ADD_NEW_ZIP_CODE_LOADING = "ADD_NEW_ZIP_CODE_LOADING";
export const DELETE_ZIP_CODE = "DELETE_ZIP_CODE";
