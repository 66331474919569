import React, { useState } from "react";
import moment from "moment";
import { deleteMeal, setSelectedMeal } from "../../../actions/meal";
import { Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { alpha } from "@mui/material/styles";
import "./ActionPanel.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  formControl: {
    margin: theme.spacing(3),
  },
  deleteButton: {
    color: theme.palette.error.dark,
    borderColor: theme.palette.error.dark,
    "&:hover": {
      backgroundColor: alpha(
        theme.palette.error.main,
        theme.palette.action.hoverOpacity
      ),
    },
  },
  deleteConfirmButton: {
    color: "#FFF",
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

const ActionPanel = ({ meal, selectedMeal }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const mealsOnMenu = useSelector((state) => state.menu.activeMenu.meals);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const handleEditMeal = (selectedMealId) => {
    history.push(`/admin/meals/edit-meal/${selectedMealId}`);
  };

  const handleDeleteMeal = (mealName, mealId) => {
    dispatch(deleteMeal(mealName, mealId));
    dispatch(setSelectedMeal(null));
    setConfirmDelete(false);
  };

  return (
    <div
      className={`meal-manager__action-panel action-panel--${
        selectedMeal ? "active" : "inactive"
      }`}
    >
      {selectedMeal ? (
        <ActionPanelSelectedState
          mealName={meal.mealName}
          _id={meal._id}
          dateAdded={meal.dateAdded}
          mealImageUrl={meal.mealImageUrl}
          setConfirmDelete={setConfirmDelete}
          confirmDelete={confirmDelete}
          handleDeleteMeal={handleDeleteMeal}
          handleEditMeal={handleEditMeal}
          classes={classes}
          mealsOnMenu={mealsOnMenu}
          analytics={meal.analytics}
        />
      ) : (
        <ActionPanelEmptyState />
      )}
    </div>
  );
};

const ActionPanelEmptyState = () => {
  return (
    <div className="meal-manager__empty-state">
      <p className="meal-manager__empty-state__header">No meal selected</p>
      <p className="meal-manager__empty-state__body">
        Select a meal in the list to make changes.
      </p>
    </div>
  );
};

const ActionPanelSelectedState = ({
  handleDeleteMeal,
  handleEditMeal,
  confirmDelete,
  setConfirmDelete,
  mealName,
  _id,
  dateAdded,
  mealImageUrl,
  mealsOnMenu,
  classes,
  analytics,
}) => {
  return (
    <>
      {confirmDelete ? (
        <ActionPanelDeleteConfirmation
          handleDeleteMeal={handleDeleteMeal}
          setConfirmDelete={setConfirmDelete}
          mealId={_id}
          mealName={mealName}
        />
      ) : (
        <div className="action-panel__selected">
          <div className="action-panel__top">
            <img
              src={mealImageUrl}
              alt={mealName}
              className="action-panel__meal-thumbnail"
            />
            <div className="action-panel__details">
              <h3 className="action-panel__meal-name">{mealName}</h3>
              <p className="action-panel__date-added">{`Added ${moment(
                dateAdded
              ).fromNow()}`}</p>
            </div>
          </div>
          <div className="action-panel__controls">
            <Button
              variant="outlined"
              fullWidth={true}
              onClick={() => handleEditMeal(_id)}
            >
              Edit Meal
            </Button>
            <Button
              variant="outlined"
              fullWidth={true}
              className={classes.deleteButton}
              onClick={() => setConfirmDelete(true)}
              disabled={mealsOnMenu.includes(_id)}
            >
              Delete Meal
            </Button>
          </div>
          <MealAnalytics analytics={analytics} />
        </div>
      )}
    </>
  );
};

const ActionPanelDeleteConfirmation = ({
  handleDeleteMeal,
  setConfirmDelete,
  mealName,
  mealId,
}) => {
  const classes = useStyles();
  return (
    <div>
      <p>Are you sure you want to delete this?</p>
      <p>This cannot be reversed.</p>
      <div className="action-panel__confirm-delete-controls">
        <Button
          variant="outlined"
          onClick={() => setConfirmDelete()}
          fullWidth={true}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          fullWidth={true}
          className={classes.deleteConfirmButton}
          onClick={() => handleDeleteMeal(mealName, mealId)}
        >
          Delete Meal
        </Button>
      </div>
    </div>
  );
};

const MealAnalytics = ({ analytics }) => {
  const { timesOnMenu, totalOrdered, orderedAverage } = analytics;

  return (
    <div className="action-panel__meal-analytics">
      <h3 className="meal-analytics__header">Meal analytics</h3>
      <div className="meal-analytics__stat">
        <p className="meal-analytics__stat-label">Times on menu:</p>
        <p className="meal-analytics__stat-value">{timesOnMenu}</p>
      </div>
      <div className="meal-analytics__stat">
        <p className="meal-analytics__stat-label">Total ordered:</p>
        <p className="meal-analytics__stat-value">{totalOrdered}</p>
      </div>
      <div className="meal-analytics__stat">
        <p className="meal-analytics__stat-label">Average times ordered:</p>
        <p className="meal-analytics__stat-value">
          {Math.round(orderedAverage * 100) / 100}
        </p>
      </div>
    </div>
  );
};

export default ActionPanel;
