import React, { useState, useEffect } from "react";
import "./FAQ.scss";

const FAQ = ({ title, children, img }) => {
  const [expanded, setExpanded] = useState(false);
  const [contentSize, setContentSize] = React.useState(null);
  const contentRef = React.createRef();

  useEffect(() => {
    setContentSize(contentRef.current.scrollHeight);
  }, [contentRef]);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="faq">
      <button
        className={`faq__toggle ${
          expanded ? "faq__toggle--expanded" : "faq__toggle--collapsed"
        } body-1 font-bold font-primary`}
        onClick={() => handleToggleExpand()}
        aria-expanded={expanded}
      >
        {title}
      </button>
      <div
        className="faq__content"
        ref={contentRef}
        style={{ maxHeight: expanded ? `${contentSize}px` : "0px" }}
        role="region"
      >
        {children}
      </div>
    </div>
  );
};

export default FAQ;
