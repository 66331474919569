import React, { useState, useEffect } from "react";
import { FormikConsumer, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Prompt } from "react-router-dom";
import { updateMeal } from "../../actions/meal";
import { setDrawerComponent } from "../../actions/layout";
import {
  TextField,
  FormGroup,
  FormControlLabel,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import LoadingButton from "@mui/lab/LoadingButton";
import BrentoBackButton from "../UI/BrentoBackButton";
import ImageUpload from "./ImageUpload/ImageUpload";
import "./EditMealForm.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      // margin: theme.spacing(1),
    },
    "& .MuiFormGroup-root": {
      width: "25ch",
    },
    "& .MuiTextField-root": {
      marginRight: "1rem",
    },
    "& .MuiButton-root": {
      borderRadius: 2,
    },
  },
}));

const EditMealForm = ({ mealData, mealLoading }) => {
  const [newImagePreview, setNewImagePreview] = useState(null);
  const [newImageFile, setNewImageFile] = useState(null);
  const [newImageUrl, setNewImageUrl] = useState(null);
  const [submitSuccessful, setSubmitSuccessful] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(setDrawerComponent(null));
  });

  const handleSelectCategory = (value) => {
    formik.setFieldValue("category", value);
  };

  const updateMealLoading = useSelector(
    (state) => state.meal.updateMealLoading
  );

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      mealName: mealData.mealName,
      mealSubtitle: mealData.mealSubtitle,
      category: mealData.category,
      mealIsNew: mealData.mealIsNew,
      mealIsVegetarian: mealData.mealIsVegetarian,
      mealIsGlutenFree: mealData.mealIsGlutenFree,
      price: mealData.price,
      calories: mealData.calories,
      protein: mealData.protein,
      fat: mealData.fat,
      carbs: mealData.carbs,
      ingredients: mealData.ingredients,
      mealImageUrl: mealData.mealImageUrl,
      description: mealData.description,
    },
    onSubmit: (values) => {
      try {
        dispatch(
          updateMeal(mealData._id, values, newImageFile, newImageUrl)
        ).then((res) => {
          setSubmitSuccessful(true);
          history.push("/admin/meals");
        });
      } catch (err) {
        setSubmitSuccessful(false);
        console.log(err);
      }
    },
  });

  // const [state, setState] = useState(
  //   {
  //     mealName: activeMeal.name,
  //     mealSubtitle: activeMeal.subTitle,
  //     mealIsNew: false,
  //     mealIsVegetarian: true,
  //     calories: 0,
  //     protein: 0,
  //     fat: 0,
  //     carbs: 0,
  //     ingredients: "",
  //     mealImage: "",
  //   },
  //   []
  // );

  // const handleTextInput = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.value });
  // };

  // const handleCheckBox = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };

  return mealLoading ? (
    <p>Loading meal...</p>
  ) : (
    <form className={classes.root} onSubmit={formik.handleSubmit}>
      <Prompt
        when={formik.dirty && !submitSuccessful}
        message="Are you sure you want to leave this page? Any unsaved changes will be lost"
      />
      <div className="form-container">
        <BrentoBackButton />
        <h2 className="edit-meal__title">
          Edit meal: <span>{mealData.mealName}</span>
        </h2>
        <TextField
          id="mealName"
          name="mealName"
          label="Meal Name"
          variant="outlined"
          margin="normal"
          fullWidth={true}
          value={formik.values.mealName}
          onChange={formik.handleChange}
        />
        <TextField
          id="mealSubtitle"
          name="mealSubtitle"
          label="Meal Sub-Title"
          variant="outlined"
          margin="normal"
          fullWidth={true}
          value={formik.values.mealSubtitle}
          onChange={formik.handleChange}
        />
        <div className="form-field-row">
          <div className="form-field-row__left">
            <FormGroup>
              <FormControlLabel
                checked={formik.values.mealIsVegetarian}
                onChange={() =>
                  formik.setFieldValue(
                    "mealIsVegetarian",
                    !formik.values.mealIsVegetarian
                  )
                }
                control={<Checkbox color="primary" />}
                label="Vegetarian"
              />
              <FormControlLabel
                checked={formik.values.mealIsNew}
                onChange={() =>
                  formik.setFieldValue("mealIsNew", !formik.values.mealIsNew)
                }
                control={<Checkbox color="primary" />}
                label="New"
              />
              <FormControlLabel
                checked={formik.values.mealIsGlutenFree}
                onChange={() =>
                  formik.setFieldValue(
                    "mealIsGlutenFree",
                    !formik.values.mealIsGlutenFree
                  )
                }
                control={<Checkbox color="primary" />}
                label="Gluten Free"
              />
            </FormGroup>
            <div className="macro-row">
              <TextField
                fullWidth
                id="price"
                name="price"
                label="Price"
                variant="outlined"
                margin="normal"
                value={formik.values.price}
                onChange={formik.handleChange}
                type="number"
                inputProps={{ step: "any", min: 0 }}
              />
              <FormControl sx={{ margin: 2, marginLeft: 0 }} fullWidth>
                <InputLabel id="category-select-input-label">
                  Category
                </InputLabel>
                <Select
                  labelId="category-select-input-label"
                  id="demo-simple-select"
                  value={formik.values.category}
                  label="Category"
                  onChange={(e) => handleSelectCategory(e.target.value)}
                >
                  <MenuItem value="meal">Meal</MenuItem>
                  <MenuItem value="extra">Extra</MenuItem>
                  <MenuItem value="juice">Juice</MenuItem>
                  <MenuItem value="bulk">Bulk</MenuItem>
                  <MenuItem value="dogTreat">Dog Treat</MenuItem>
                  <MenuItem value="merch">Merch</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="macro-row">
              <TextField
                id="calories"
                name="calories"
                label="Calories"
                variant="outlined"
                margin="normal"
                value={formik.values.calories}
                onChange={formik.handleChange}
                type="number"
                fullWidth={true}
              />
              <TextField
                id="protein"
                name="protein"
                label="Protein (grams)"
                variant="outlined"
                margin="normal"
                value={formik.values.protein}
                onChange={formik.handleChange}
                type="number"
                fullWidth={true}
              />
            </div>
            <div className="macro-row">
              <TextField
                id="carbs"
                name="carbs"
                label="Carbs (grams)"
                variant="outlined"
                margin="normal"
                value={formik.values.carbs}
                onChange={formik.handleChange}
                type="number"
                fullWidth={true}
              />
              <TextField
                id="fat"
                name="fat"
                label="Fat (grams)"
                variant="outlined"
                margin="normal"
                value={formik.values.fat}
                onChange={formik.handleChange}
                type="number"
                fullWidth={true}
              />
            </div>
          </div>
          <div className="form-field-row__right">
            <ImageUpload
              onChange={formik.handleChange}
              imageSrc={formik.values.mealImage}
              value={formik.values.mealImageUrl}
              setFieldValue={formik.setFieldValue}
              newImagePreview={
                newImagePreview ? newImagePreview : formik.values.mealImageUrl
              }
              setNewImagePreview={setNewImagePreview}
              setNewImageFile={setNewImageFile}
              newImageFile={newImageFile}
              setNewImageUrl={setNewImageUrl}
            />
          </div>
        </div>
        <TextField
          id="ingredients"
          name="ingredients"
          label="Ingredients"
          variant="outlined"
          margin="normal"
          value={formik.values.ingredients}
          onChange={formik.handleChange}
          fullWidth={true}
          multiline
          rows={4}
        />
        <TextField
          id="description"
          name="description"
          label="Description"
          variant="outlined"
          margin="normal"
          value={formik.values.description}
          onChange={formik.handleChange}
          fullWidth={true}
          multiline
          rows={4}
        />
      </div>
      <LoadingButton
        pending={updateMealLoading}
        disabled={!formik.dirty}
        key={`${!formik.dirty}`}
        color="primary"
        variant="contained"
        size="large"
        type="submit"
      >
        Update Meal
      </LoadingButton>
    </form>
  );
};

export default EditMealForm;
