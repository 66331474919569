import React, { useRef, useState, useEffect } from "react";
import "./MealDetailsAddToCart.scss";
import BrentoStepper from "../UI/Stepper/BrentoStepper";
import { Button } from "@mui/material";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useSelector, useDispatch } from "react-redux";
import {
  addToCart,
  increaseCartQuantity,
  setCartExpirationDate,
} from "../../actions/cart.js";
import { setModalOpen, setModalComponent } from "../../actions/layout.js";
import { trackAddToCart } from "../../tracking/trackAddToCart";

const MealDetailsAddToCart = ({ meal }) => {
  const [isSticky, setIsSticky] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const ref = useRef();
  const cartItems = useSelector((state) => state.cart.cartItems);
  const expirationDate = useSelector(
    (state) => state.menu.activeMenu.orderByDate
  );
  const dispatch = useDispatch();

  // mount
  useEffect(() => {
    const cachedRef = ref.current,
      observer = new IntersectionObserver(
        ([e]) => setIsSticky(e.intersectionRatio < 1),
        { threshold: [1] }
      );

    observer.observe(cachedRef);

    // unmount
    return function () {
      observer.unobserve(cachedRef);
    };
  }, []);

  const handleIncrementQuantity = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrementQuantity = () => {
    setQuantity(quantity - 1);
  };

  const handleQuantityChange = (e) => {
    setQuantity(parseInt(e.target.value));
  };

  const handleAddToCart = () => {
    // If the meal is already in the cart
    // Increase the value instead of adding a new cart item
    if (
      cartItems &&
      cartItems.some((cartItem) => cartItem.meal._id === meal._id)
    ) {
      dispatch(increaseCartQuantity(meal._id, quantity));
    } else {
      dispatch(addToCart(meal, quantity));
      dispatch(setCartExpirationDate(expirationDate));
    }
    trackAddToCart(meal, quantity);
    dispatch(setModalOpen(true));
    dispatch(
      setModalComponent("confirm-add-cart", { meal: meal, qty: quantity })
    );
  };

  return (
    <div
      className={`meal-add-to-cart ${
        isSticky ? "meal-add-to-cart--sticky" : "meal-add-to-cart--default"
      }`}
      ref={ref}
    >
      <BrentoStepper
        stepperValue={quantity}
        onDecrement={() => handleDecrementQuantity()}
        onIncrement={() => handleIncrementQuantity()}
        onInputChange={(e) => handleQuantityChange(e)}
      />
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddShoppingCartIcon />}
        disabled={quantity <= 0}
        key={`${quantity <= 0}`}
        onClick={() => handleAddToCart()}
      >
        Add to cart
      </Button>
    </div>
  );
};

export default MealDetailsAddToCart;
