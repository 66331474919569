import React, { useState } from "react";
import { Dialog, IconButton, Slide } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setModalOpen } from "../../../actions/layout";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmRemoveCart from "./ModalComponents/ConfirmRemoveCart/ConfirmRemoveCart";
import ConfirmAddCart from "./ModalComponents/ConfirmAddCart/ConfirmAddCart";
import MinimumProcessingAmount from "./ModalComponents/MinimumProcessingAmount/MinimumProcessingAmount";
import PromoCodeEmail from "./ModalComponents/PromoCodeEmail/PromoCodeEmail";
import "./BrentoModal.scss";

const BrentoModal = () => {
  const dispatch = useDispatch();
  const modalOpen = useSelector((state) => state.layout.modalOpen);
  const modalComponent = useSelector((state) => state.layout.modalComponent);

  const handleCloseModal = () => {
    dispatch(setModalOpen(false));
  };

  const renderModalComponent = (componentId) => {
    switch (componentId) {
      case "confirm-add-cart":
        return <ConfirmAddCart data={modalComponent.data} />;
      case "confirm-remove-cart":
        return <ConfirmRemoveCart data={modalComponent.data} />;
      case "minimum-processing-amount":
        return <MinimumProcessingAmount />;
      case "promo-code-email-capture":
        return <PromoCodeEmail data={modalComponent.data} />;
      default:
        return;
    }
  };

  return (
    <Dialog
      open={modalOpen}
      className="brento-modal"
      maxWidth="lg"
      TransitionComponent={Slide}
      TransitionProps={{ direction: "up" }}
      onClose={() => handleCloseModal()}
    >
      <IconButton onClick={() => handleCloseModal()} size="large">
        <CloseIcon className="brento-modal__close-btn" />
      </IconButton>
      <div className="brento-modal__component">
        {renderModalComponent(modalComponent.id)}
      </div>
    </Dialog>
  );
};

export default BrentoModal;
