import React from "react";
import "./AnimatedCheckmark.scss";

const AnimatedCheckmark = () => {
  return (
    <div>
      <svg x="0px" y="0px" className="anim-check" viewBox="0 0 48 48">
        <circle cx="24" cy="24" r="20" />
        <polyline points="15,23.7 21.1,29.8 35,16 " />
      </svg>
    </div>
  );
};

export default AnimatedCheckmark;
