import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import CheckoutFieldset from "../../CheckoutFieldset";
import Alert from "../../../../UI/Alert/Alert";
import BrentoTextField from "../../../../UI/BrentoTextField/BrentoTextField";
import BrentoLoadingButton from "../../../../UI/BrentoLoadingButton";
import { handleApplyDiscountRequest } from "./utils/handleApplyDiscountRequest";
import DiscountCodeSuccess from "./DiscountCodeSuccess/DiscountCodeSuccess";
import { setCartDiscountCode } from "../../../../../actions/cart";
import { variables } from "../../../../../variables/variables";

const DiscountCode = ({ formik, orderTotal, setOrderAttemptDetails }) => {
  const [promoInputValue, setPromoInputValue] = useState("");
  const [promoInputErrorText, setPromoInputErrorText] = useState(null);
  const [promoResponseError, setPromoResponseError] = useState(false);
  const [applyPromoLoading, setApplyPromoLoading] = useState(false);
  const dispatch = useDispatch();
  const testMode = useSelector((state) => state.admin.testMode);
  const cartDiscountCode = useSelector((state) => state.cart.cartDiscountCode);
  const paymentIntentId = useSelector((state) => state.payment.paymentIntentId);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const isMobile = useSelector(
    (state) => state.layout.displayWidth < variables.breakpoints.lrg
  );

  const handleSetInputValue = (value) => {
    setPromoInputErrorText(false);
    setPromoInputValue(value);
  };

  const handlePromoValueError = (error) => {};

  const handleSubmitPromoValue = async (value) => {
    console.log("Sending promo code...");
    if (value.length < 6) {
      setPromoInputErrorText("Invalid discount code");
    } else {
      setPromoInputErrorText(null);
      setApplyPromoLoading(true);
      // *Add request for discount code here
      let requestResponse = await handleApplyDiscountRequest(
        value,
        cartItems,
        formik.values.email,
        paymentIntentId,
        testMode
      );
      if (requestResponse && requestResponse.discountCode) {
        setOrderAttemptDetails(requestResponse.orderDetails);
        dispatch(setCartDiscountCode(requestResponse.discountCode));
        console.log(requestResponse.orderDetails);
        setApplyPromoLoading(false);
        setPromoResponseError(false);
      } else {
        setPromoResponseError(true);
        setApplyPromoLoading(false);
      }

      //   if (requestResponse && requestResponse.discountCategory) {
      //     if (requestResponse.discountCategory === "gift-card") {
      //       handleApplyGiftCard(requestResponse);
      //     } else {
      //       handleApplyPromoCode(requestResponse);
      //     }
      //   } else {
      //     handleSubmitPromoError();
      //   }
      // }
      console.log(requestResponse);
    }
  };

  const handleSubmitPromoError = () => {
    setPromoResponseError(true);
    setPromoInputErrorText("Invalid discount code");
    setApplyPromoLoading(false);
  };

  console.log(orderTotal);
  console.log(cartDiscountCode);

  return (
    <>
      {cartDiscountCode ? (
        <DiscountCodeSuccess
          discountCodeDetails={cartDiscountCode}
          discountApplied={orderTotal.discountApplied / 100}
          totalAfterDiscount={orderTotal.totalAfterDiscount}
          minimumFeeCharged={orderTotal.carryOver}
          setOrderAttemptDetails={setOrderAttemptDetails}
          setPromoInputValue={setPromoInputValue}
        />
      ) : (
        <>
          <CheckoutFieldset label="Discount or gift card code">
            <div className="checkout-form__discount-code">
              <BrentoTextField
                error={promoInputErrorText ? true : false}
                helperText={promoInputErrorText}
                name="cc-exp"
                size="small"
                label="Enter promo or gift card code"
                variant="outlined"
                onChange={(e) => handleSetInputValue(e.target.value)}
                inputProps={{ style: { textTransform: "uppercase" } }}
                fullWidth
              />
              <BrentoLoadingButton
                pending={applyPromoLoading}
                onClick={() => handleSubmitPromoValue(promoInputValue)}
                size="small"
                variant="outlined"
                type="button"
                disabled={!promoInputValue || promoInputValue.length <= 0}
                sx={{ flex: "none" }}
              >
                {isMobile ? "Apply" : "Apply code"}
              </BrentoLoadingButton>
            </div>
            {promoResponseError && <ApplyPromoCodeError />}
          </CheckoutFieldset>
        </>
      )}
    </>
  );
};

const ApplyPromoCodeError = () => {
  return (
    <div className="apply-promo__error">
      <Alert
        type="error"
        header="Invalid discount code"
        content="Either the promo code you entered does not exist, or there are insufficient funds to apply to this order."
      />
    </div>
  );
};

export default DiscountCode;
