import React, { useEffect } from "react";
import EditMealForm from "../../Components/Admin/EditMealForm";
import { useDispatch, useSelector } from "react-redux";
import { getMeal } from "../../actions/meal";
import { MEAL_LOADING } from "../../actions/types";

const EditMeal = (props) => {
  const dispatch = useDispatch();
  const mealData = useSelector((state) => state.meal.meal);
  const mealLoading = useSelector((state) => state.meal.mealLoading);
  const {
    match: { params },
  } = props;

  useEffect(() => {
    dispatch({ type: MEAL_LOADING, payload: true });
    dispatch(getMeal(params.id));
  }, [dispatch, params.id]);

  return (
    <>
      {mealLoading ? (
        <p>Loading...</p>
      ) : (
        <EditMealForm mealData={mealData} mealLoading={mealLoading} />
      )}
    </>
  );
};

export default EditMeal;
