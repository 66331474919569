import React from "react";
import Container from "../../Components/Layout/Container";
import ScrollToTop from "../../Components/Layout/ScrollToTop";
import BrentoBackButton from "../../Components/UI/BrentoBackButton";
import BrentoButton from "../../Components/UI/BrentoButton";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import FAQ from "../../Components/FAQs/FAQ/FAQ";
import GiftCardImg from "../../img/gift-card-1.png";
import "./GiftCard.scss";

const GiftCard = () => {
  return (
    <>
      <ScrollToTop />
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://getbrentobox.com/gift-cards" />
        <title>
          Digital Gift Cards | Brento Box - Fresh Meal Prep Made Easy
        </title>
        <meta
          name="description"
          content="Send a personalized digital gift card to your friends and family! They'll be emailed a discount code that they can apply towards future Brento Box orders."
        />
      </Helmet>
      <div className="gift-card-page">
        <Container>
          <main>
            <BrentoBackButton />
            <div class="gift-card-page__content">
              <div className="gift-card-page__img-container">
                <img
                  src={GiftCardImg}
                  alt="Illustration of a gift box"
                  className="gift-card-page__img"
                />
              </div>
              <div className="gift-card-page__primary-content">
                <h1 className="header-2">Digital Gift Cards</h1>
                <p className="body-1">
                  Send a personalized digital gift card to your friends and
                  family! They'll be emailed a discount code that they can apply
                  towards future Brento Box orders*. Complete the form below to
                  purchase and deliver your personalized Brento Box digital gift
                  card!
                </p>
                <div className="gift-card-page__learn-more">
                  <p className="body-2">
                    <b>*Certain limitations apply.</b> If you have any
                    questions, read through the Frequently Asked Questions
                    section below to learn how Brento Box digital gift cards
                    work.
                  </p>
                </div>
                <Link to="/gift-cards/purchase-gift-card">
                  <BrentoButton>Buy a gift card now</BrentoButton>
                </Link>
                <div id="gift-card-faqs">
                  <h2 className="header-4">Frequently Asked Questions</h2>
                  <FAQ title="How do gift cards work?">
                    <p className="body-2">
                      Great question! Digital gift cards are sent to the{" "}
                      <b>Recipient Email Address</b> that you provide during
                      purchase. Inside the email is a <b>Discount Code</b> that
                      is unique to the one you purchased. The lucky recipient of
                      the gift card can copy/paste the code from the email into
                      the Discount Code field on the <b>Shopping cart page.</b>{" "}
                      The gift card will be applied towards the subtotal for
                      their order. Taxes and delivery fee still apply per usual,
                      as well as the standard $40 minimum. However, the $40
                      minimum only applies to the subtotal <i>before</i> the
                      gift card is applied.
                    </p>
                  </FAQ>
                  <FAQ title="How do I apply a gift card to my order?">
                    <p className="body-2">
                      When placing an order, first add the items that you want
                      to your <b>Shopping Cart.</b> Once you have items in your
                      cart, go to your cart to begin your
                      <b> Checkout</b> process. On the last page during the
                      Checkout process, we'll ask for your <b> Payment </b>
                      information. On the Payment screen, you'll see a field
                      labeled <b> Discount or Gift Card code.</b> Add your code
                      there and click <b>Apply</b> to add it to your order.
                    </p>
                  </FAQ>
                  <FAQ title="Do you offer physical gift cards?">
                    <p className="body-2">
                      Sorry, not at this time! We only offer digital gift cards
                      which are delivered via email.
                    </p>
                  </FAQ>
                  <FAQ title="Can I schedule when the gift card email is delivered?">
                    <p className="body-2">
                      Yes! You can either send the gift card immediately once
                      you complete the gift card purchase, or you can schedule
                      for a future date such as a holiday or special occasion.
                      If you schedule for a future date, we'll send it the
                      morning of whichever date you select, usually around
                      8:00am.
                    </p>
                  </FAQ>
                  {/* <FAQ title="What if I sent a gift card to someone who is outside of the delivery area?">
                    <p className="body-2">
                      Oh no! If that happens, please let us know and we will see
                      what we can do. If you are concerned about this, we have
                      made it easy for you to check the supported ZIP code
                      during the checkout process for buying a gift card. Simply
                      enter the ZIP code of the lucky family member or friend
                      whom you are sending the gift card to, and we will let you
                      know right then and there whether or not they are in the
                      supported delivery area.
                    </p>
                  </FAQ> */}
                  <FAQ title="Can I apply my gift card across multiple orders?">
                    <p className="body-2">
                      Yes, you can add your gift card as many times as you would
                      like across multiple orders until your gift card balance
                      runs out. You can check the remaining balance by entering
                      in your code on the Shopping Cart page.
                    </p>
                  </FAQ>
                  <FAQ title="Can I apply multiple gift cards to a single order?">
                    <p className="body-2">
                      Not at this time, sorry about that! We only allow one gift
                      card to be applied per order. This helps us keep things
                      running smoothly on our end.
                    </p>
                  </FAQ>
                  <FAQ title="Why was a minimum processing amount added to my order when I applied a gift card?">
                    <p className="body-2">
                      Great question. This <b>minimum processing amount</b> is
                      added only for orders in which the gift card amount
                      exceeds the overall total. Why do we need this? Our
                      ordering system is built around our third-party payment
                      processor <b>Stripe</b>, who requires a $0.50 minimum for
                      all transactions (we can't create an order for $0.00). By
                      still using our payment processor, this ensures that your
                      order will be handled the same way as all the others on
                      our end.{" "}
                      <b>
                        Don't worry, this 50 cents will be added back on to your
                        gift card balance once the order goes through.
                      </b>{" "}
                      We apologize for any inconvenience or confusion while we
                      work on a better solution, but if you have any questions
                      or concerns about this, please feel free to{" "}
                      <a href="https://getbrentobox.com/contact">contact us.</a>
                    </p>
                  </FAQ>
                </div>
              </div>
            </div>
          </main>
        </Container>
      </div>
    </>
  );
};

export default GiftCard;
