import React, { useState } from "react";
import { Prompt } from "react-router-dom";
import moment from "moment";
import { Formik, FieldArray } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { updateMenu } from "../../../actions/menu";
import { sortItems } from "../../../utils/sortItems";
import {
  TextField,
  Button,
  Checkbox,
  ListItemText,
  ListItemIcon,
  List,
  ListItem,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import "./UpdateMenuForm.scss";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    ".form-contaner": {
      width: "50ch",
    },
    "& .MuiFormGroup-root": {
      width: "25ch",
    },
    "& .MuiTextField-root": {
      marginRight: "1rem",
      "& :hover": {
        cursor: "text",
      },
    },
    "& .MuiList-root": {
      border: "1px solid #eaeaea",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const handleAutoConfigureDate = (dayIso, hourOfDay, minuteOfDay) => {
  // ISO Reference: ------ //
  // Sunday = 0
  // Monday = 1
  // Tuesday = 2
  // Wednesday = 3
  // Thursday = 4
  // Friday = 5
  // Saturday = 6
  const today = moment().isoWeekday();
  if (today < dayIso) {
    // then just give me this week's instance of that day
    return moment()
      .isoWeekday(dayIso)
      .set({ h: hourOfDay, m: minuteOfDay })
      .format("YYYY-MM-DDTHH:mm");
  } else {
    // otherwise, give me *next week's* instance of that same day
    return moment()
      .add(1, "weeks")
      .isoWeekday(dayIso)
      .set({ h: hourOfDay, m: minuteOfDay })
      .format("YYYY-MM-DDTHH:mm");
  }
};

const UpdateMenuForm = ({ menu, availableMeals }) => {
  const [textFilter, setTextFilter] = useState(null);
  const [sortByValue, setSortByValue] = useState("Most recent");
  const [showActiveItems, setShowActiveItems] = useState(false);
  const [submitSuccessful, setSubmitSuccessful] = useState(false);
  const testMode = useSelector((state) => state.admin.testMode);
  const dispatch = useDispatch();
  const classes = useStyles();
  console.log(menu.orderStartDate);
  return (
    <div className="update-menu-form">
      <div className={classes.root}>
        <Formik
          enableReinitialize={true}
          initialValues={{
            menuName: menu.name,
            publishDate: moment(menu.publishDate).format("YYYY-MM-DDTHH:mm"),
            orderStartDate: moment(menu.orderStartDate).format(
              "YYYY-MM-DDTHH:mm"
            ),
            orderByDate: moment(menu.orderByDate).format("YYYY-MM-DDTHH:mm"),
            meals: menu.meals,
          }}
          onSubmit={(values, { setSubmitting }) => {
            try {
              const newMenuData = values;
              console.log(newMenuData);
              dispatch(updateMenu({ newMenuData }, testMode));
              setSubmitting(false);
              setSubmitSuccessful(true);
            } catch (err) {
              setSubmitSuccessful(false);
            }
          }}
        >
          {({
            values,
            errors,
            dirty,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Prompt
                when={dirty && !submitSuccessful}
                message="Are you sure you want to leave this page? Any unsaved changes will be lost"
              />
              <TextField
                name="menuName"
                type="text"
                variant="outlined"
                fullWidth={true}
                label="Menu Title"
                value={values.menuName}
                onChange={handleChange}
                onBlur={handleBlur}
                margin="normal"
              />
              {/* {errors.email && touched.email && errors.email} */}
              <h2 className="body-1 font-light">Set dates</h2>
              <div className="update-menu-form__date-fields">
                <TextField
                  name="orderStartDate"
                  label="Order Start Date"
                  type="datetime-local"
                  variant="outlined"
                  value={values.orderStartDate}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  margin="normal"
                />
                <TextField
                  name="orderByDate"
                  label="Order-By Date"
                  type="datetime-local"
                  variant="outlined"
                  value={values.orderByDate}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  margin="normal"
                />
                <Button
                  variant="outlined"
                  size="large"
                  margin="normal"
                  onClick={() => {
                    setFieldValue(
                      "orderStartDate",
                      handleAutoConfigureDate(4, 11, 0)
                    );
                    setFieldValue(
                      "orderByDate",
                      handleAutoConfigureDate(5, 12, 0)
                    );
                  }}
                >
                  Auto-Set
                </Button>
              </div>
              <h2 className="body-1 font-light">Select meals</h2>
              <MenuManagerFilter
                textFilter={textFilter}
                setTextFilter={setTextFilter}
                sortByValue={sortByValue}
                setSortByValue={setSortByValue}
                showActiveItems={showActiveItems}
                setShowActiveItems={setShowActiveItems}
              />
              <MenuManagerSelectionPanel
                availableMeals={
                  showActiveItems
                    ? sortItems(availableMeals, sortByValue).filter((meal) =>
                        values.meals.includes(meal._id)
                      )
                    : sortItems(availableMeals, sortByValue)
                }
                textFilter={textFilter}
                values={values}
                sortByValue={sortByValue}
              />
              <Button
                color="primary"
                variant="contained"
                size="large"
                type="submit"
                disabled={isSubmitting || !dirty}
              >
                Update Menu
              </Button>
              {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const MenuManagerFilter = ({
  textFilter,
  setTextFilter,
  sortByValue,
  setSortByValue,
  showActiveItems,
  setShowActiveItems,
}) => {
  const [filterExpanded, setFilterExpanded] = useState(false);

  const handleTextChange = (e) => {
    setTextFilter(e.target.value);
  };

  const handleToggleFilter = () => {
    setFilterExpanded(!filterExpanded);
  };

  const handleSetSorting = (e) => {
    setSortByValue(e.target.value);
  };

  const handleShowActiveItems = (e) => {
    setShowActiveItems(!showActiveItems);
  };

  return (
    <div className="update-menu-form__filter">
      <div className="update-menu-form__filter-main">
        <input
          type="text"
          className="update-menu-form__text-filter"
          placeholder="Search"
          value={textFilter}
          onChange={handleTextChange}
        ></input>
        <Button
          variant={filterExpanded ? "contained" : "outlined"}
          color="primary"
          onClick={() => handleToggleFilter()}
          startIcon={filterExpanded ? <CloseIcon /> : <FilterListIcon />}
        >
          Filters
        </Button>
      </div>
      {filterExpanded && (
        <div className="update-menu-form__sub-filter">
          <FormControl>
            <InputLabel id="sortby-label">Sort by</InputLabel>
            <Select
              labelId="sortby-label"
              id="sortby-field"
              value={sortByValue}
              onChange={handleSetSorting}
            >
              <MenuItem value={"Most recent"}>Most recent</MenuItem>
              <MenuItem value={"A-Z"}>A-Z</MenuItem>
              <MenuItem value={"Z-A"}>Z-A</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={showActiveItems}
                onChange={handleShowActiveItems}
                name="show-active-items"
                color="primary"
              />
            }
            label="Show active items"
          />
        </div>
      )}
    </div>
  );
};

const MenuManagerSelectionPanel = ({
  availableMeals,
  textFilter,
  values,
  sortByValue,
}) => {
  return (
    <div className="update-menu-form__meal-selection-panel">
      <FieldArray
        name="meals"
        render={(arrayHelpers) => (
          <List disablePadding>
            {availableMeals && textFilter
              ? availableMeals
                  .filter((meal) =>
                    meal.mealName
                      .toLowerCase()
                      .includes(textFilter.toLowerCase())
                  )
                  .map((meal, index) => {
                    const labelId = `checkbox-list-label-${index}`;
                    return (
                      <ListItem
                        key={meal._id}
                        button
                        divider
                        onClick={() => {
                          if (!values.meals.includes(meal._id))
                            arrayHelpers.push(meal._id);
                          else {
                            const idx = values.meals.indexOf(meal._id);
                            arrayHelpers.remove(idx);
                          }
                        }}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={values.meals.includes(meal._id)}
                            color="primary"
                            tabIndex={-1}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                            // onChange={(e) => {
                            //   if (e.target.checked) arrayHelpers.push(meal._id);
                            //   else {
                            //     const idx = values.meals.indexOf(meal._id);
                            //     arrayHelpers.remove(idx);
                            //   }
                            // }}
                          />
                        </ListItemIcon>
                        <ListItemText>{meal.mealName}</ListItemText>
                      </ListItem>
                    );
                  })
              : availableMeals.map((meal, index) => {
                  const labelId = `checkbox-list-label-${index}`;
                  return (
                    <ListItem
                      key={index}
                      button
                      divider
                      onClick={() => {
                        if (!values.meals.includes(meal._id))
                          arrayHelpers.push(meal._id);
                        else {
                          const idx = values.meals.indexOf(meal._id);
                          arrayHelpers.remove(idx);
                        }
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={values.meals.includes(meal._id)}
                          color="primary"
                          tabIndex={-1}
                          inputProps={{ "aria-labelledby": labelId }}
                          // onChange={(e) => {
                          //   if (e.target.checked) arrayHelpers.push(meal._id);
                          //   else {
                          //     const idx = values.meals.indexOf(meal._id);
                          //     arrayHelpers.remove(idx);
                          //   }
                          // }}
                        />
                      </ListItemIcon>
                      <ListItemText>{meal.mealName}</ListItemText>
                    </ListItem>
                  );
                })}
          </List>
        )}
      />
    </div>
  );
};

export default UpdateMenuForm;
