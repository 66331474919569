import React, { useEffect } from "react";
import ScrollToTop from "../../Layout/ScrollToTop";
import AnimatedCheckmark from "./AnimatedCheckmark/AnimatedCheckmark";
import "./ThankYou.scss";
import { trackPurchase } from "../../../tracking/trackPurchase";

const ThankYou = ({ completedOrderDetails }) => {
  useEffect(() => {
    trackPurchase(
      completedOrderDetails.lineItems,
      completedOrderDetails.orderTotal,
      completedOrderDetails.discountCode,
      completedOrderDetails.paymentIntentId
    );
  }, []);

  return (
    <>
      <ScrollToTop />
      <div className="thank-you">
        <AnimatedCheckmark />
        <div className="thank-you__header">
          <h1 className="header-2">Thank you!</h1>
          <span className="thank-you__order-number">
            <p className="thank-you__label">Order Number:</p>
            <p className="thank-you__value">{completedOrderDetails.orderId}</p>
          </span>
          <p className="body-2">
            Your order has been received, and tasty Brento Boxes are coming
            soon. Hang on to your order number for reference. If you have any
            issues, <a href="/contact">contact us</a>.
          </p>
        </div>
        <div className="thank-you__order-details">
          <h2 className="eyebrow-1">Order details</h2>
          <div className="thank-you__line-items">
            <ul>
              {completedOrderDetails.lineItems.map((lineItem) => {
                return (
                  <li className="thank-you__line-item">
                    {lineItem.mealName}
                    <span className="thank-you__line-item-quantity">
                      × {lineItem.quantity}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="thank-you__order-details-group">
            <h3>Order Total:</h3>
            <div className="thank-you__order-details-group-fields">
              <p>
                <b>{convertToDollars(completedOrderDetails.orderTotal)}</b>
              </p>
            </div>
          </div>
          <div className="thank-you__order-details-group">
            <h3>Contact info:</h3>
            <div className="thank-you__order-details-group-fields">
              <p>{completedOrderDetails.name}</p>
              <p>{completedOrderDetails.phone}</p>
              <p>{completedOrderDetails.email}</p>
            </div>
          </div>
          <div className="thank-you__order-details-group">
            <h3>Delivery Address:</h3>
            <div className="thank-you__order-details-group-fields">
              <p>{completedOrderDetails.deliveryAddressLine1}</p>
              {completedOrderDetails.deliveryAddressLine2.length > 0 && (
                <p>{completedOrderDetails.phone}</p>
              )}
              <p>
                {`${completedOrderDetails.deliveryCity}, ${completedOrderDetails.deliveryState}, ${completedOrderDetails.deliveryZipCode}`}
              </p>
            </div>
          </div>
          {completedOrderDetails.includeDeliveryField && (
            <div className="thank-you__order-details-group">
              <h3>Special delivery instructions:</h3>
              <div className="thank-you__order-details-group-fields">
                <p>{completedOrderDetails.specialInstructions}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const convertToDollars = (value) => {
  var valueInDollars = value / 100;
  valueInDollars = valueInDollars.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
  return valueInDollars;
};

// _id: '618b20139590447dc0e39d73', orderId: '1636507667620-24455', createdAtISO: '2021-11-10', createdAt: '2021-11-10T01:27:47.620Z', paymentIntentId: 'pi_3Ju5aqL5faruNVO21efrokkW_secret_ITnk1IS0R3ffGEzNubBFia4rc', …}
// billingAddressLine1: ""
// billingAddressLine2: ""
// billingCity: ""
// billingState: "AZ"
// billingZipCode: ""
// ccCvc: ""
// ccExpiry: ""
// ccnumber: ""
// createdAt: "2021-11-10T01:27:47.620Z"
// createdAtISO: "2021-11-10"
// deliveryAddressLine1: "5642 N 7th Dr"
// deliveryAddressLine2: ""
// deliveryCity: "Phoenix"
// deliveryState: "AZ"
// deliveryZipCode: "85013"
// email: "jackmakesart@gmail.com"
// includeDeliveryField: true
// lineItems: [{…}]
// name: "Jack Gannon"
// orderId: "1636507667620-24455"
// orderTotal: 4844
// paymentIntentId: "pi_3Ju5aqL5faruNVO21efrokkW_secret_ITnk1IS0R3ffGEzNubBFia4rc"
// phone: "6024786842"
// specialInstructions: "This one will work for sure!"
// useDifferentBillingAddress: "false"
// __v: 0
// _id: "618b20139590447dc0e39d73"

export default ThankYou;
