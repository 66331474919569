import axios from "axios";

export const validateZipCodeRequest = async (zipCode) => {
  try {
    const res = await axios.get(`/api/zipcodes/validate/${zipCode}`);
    return res.data;
  } catch (err) {
    return err;
  }
};
