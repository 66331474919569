import React from "react";
import "./VideoHero.scss";

const VideoHero = () => {
  return (
    <div className="about__video-hero">
      <iframe
        src="https://www.youtube.com/embed/IZzdw4N94hE"
        // src="https://www.youtube.com/embed/IZzdw4N94hE?&autoplay=1"
        title="YouTube video player"
        frameborder="0"
        width="100%"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  );
};

export default VideoHero;
