import React from "react";
import { useSelector } from "react-redux";
import { variables } from "../../../variables/variables";
import Grid from "@mui/material/Grid";
import CategoryLink from "../CategoryLink/CategoryLink";
import SignatureMealsImage from "../../../img/categories/signature-meals-7.jpg";
import DogTreatsImage from "../../../img/categories/dogtreats.jpg";
import BulkImage from "../../../img/categories/bulk-5.jpg";
import ExtrasImage from "../../../img/categories/extras.jpg";
import JuicesImage from "../../../img/categories/juices.jpg";
import GiftCardsImage from "../../../img/categories/gift-cards.jpg";
import MerchImage from "../../../img/categories/merch-4.jpg";

const categories = [
  {
    title: "Signature Meals",
    url: "menu/signature-meals",
    img: SignatureMealsImage,
    grid: { xs: 12, lg: 12 },
  },
  {
    title: "A La Carte",
    url: "menu/a-la-carte",
    img: BulkImage,
    grid: { xs: 6, lg: 4 },
  },
  {
    title: "Treats",
    url: "menu/treats",
    img: ExtrasImage,
    grid: { xs: 6, lg: 4 },
  },
  {
    title: "Juices & Coffee",
    url: "menu/juices-and-coffee",
    img: JuicesImage,
    grid: { xs: 6, lg: 4 },
  },
  {
    title: "Merch & More",
    url: "/merch-and-more",
    img: MerchImage,
    grid: { xs: 6, lg: 6 },
  },
  {
    title: "Digital Gift Cards",
    url: "/gift-cards",
    img: GiftCardsImage,
    grid: { xs: 12, lg: 6 },
  },
];

const MenuCategories = () => {
  const isMobile = useSelector(
    (state) => state.layout.displayWidth < variables.breakpoints.lrg
  );
  return (
    <>
      <Grid
        container
        spacing={isMobile ? 1.5 : 3}
        sx={{ paddingBottom: isMobile ? 2 : 4 }}
      >
        {categories.map((category) => {
          return (
            <Grid item xs={category.grid.xs} lg={category.grid.lg}>
              <CategoryLink
                categoryURL={category.url}
                categoryTitle={category.title}
                categoryImage={category.img}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default MenuCategories;
