import React, { useEffect } from "react";
import "./Cart.scss";
import Container from "../../Components/Layout/Container";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  setCartIndicatorActive,
  clearCartDiscountCode,
} from "../../actions/cart";
import ScrollToTop from "../../Components/Layout/ScrollToTop";
import CartItem from "../../Components/Cart/CartItem";
import CartSummary from "../../Components/Cart/CartSummary";
import CartEmptyStateIllo from "../../img/Illustrations/EmptyState/Empty-State-Cart.svg";
import CartEmptyStateUnavailIllo from "../../img/Illustrations/EmptyState/Empty-State-Cart-Unavail.svg";
import BrentoButton from "../../Components/UI/BrentoButton";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { trackViewCart } from "../../tracking/trackViewCart";

const Cart = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.cartItems);
  const cartDiscountCode = useSelector((state) => state.cart.cartDiscountCode);
  const orderingAvailable = useSelector(
    (state) => state.menu.orderingAvailable
  );
  useEffect(() => {
    dispatch(setCartIndicatorActive(false));
    // TO-DO:
    // Remove below dispatch when trying to bring discounts back into cart
    dispatch(clearCartDiscountCode());
    trackViewCart(cartItems, cartDiscountCode);
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://getbrentobox.com/cart" />
        <title>Shopping Cart | Brento Box - Fresh Meal Prep Made Easy</title>
        <meta
          name="description"
          content="Here you can review the delicious Brento Box meals you have selected before you checkout."
        />
      </Helmet>
      <ScrollToTop />
      <Container>
        <div className="shopping-cart">
          <h1 className="header-2">Shopping cart</h1>
          {cartItems && cartItems.length > 0 ? (
            <div className="shopping-cart__main">
              <div className="shopping-cart__sidebar">
                <CartSummary cartItems={cartItems} />
              </div>
              <ul className="shopping-cart__items">
                {cartItems.map((cartItem) => {
                  return (
                    <CartItem
                      meal={cartItem.meal}
                      quantity={cartItem.qty}
                      key={cartItem.meal._id}
                    />
                  );
                })}
              </ul>
            </div>
          ) : orderingAvailable ? (
            <CartEmptyStateAvailable />
          ) : (
            <CartEmptyStateUnavailable />
          )}
        </div>
      </Container>
    </>
  );
};

const CartEmptyStateAvailable = () => {
  return (
    <div className="cart-empty-state cart-empty-state--available">
      <img
        src={CartEmptyStateIllo}
        alt="shopping cart illustration"
        className="cart-empty-state__img"
      />
      <div className="cart-empty-state__text-content">
        <h2 className="cart-empty-state__header">Your cart is empty</h2>
        <p className="cart-empty-state__text body-2">
          Check out the meals on this week's menu and add items to your cart.
        </p>
        <Link to="/menu">
          <BrentoButton variant="outlined">Go to menu</BrentoButton>
        </Link>
      </div>
    </div>
  );
};

const CartEmptyStateUnavailable = () => {
  const history = useHistory();

  return (
    <div className="cart-empty-state cart-empty-state--unavailable">
      <img
        src={CartEmptyStateUnavailIllo}
        alt="shopping cart illustration"
        className="cart-empty-state__img"
      />
      <div className="cart-empty-state__text-content">
        <h2 className="cart-empty-state__header">Ordering is unavailable</h2>
        <p className="cart-empty-state__text body-2">
          New menus are usually released on Wednesday mornings, so check back
          then!
        </p>
        <BrentoButton variant="outlined" onClick={() => history.goBack()}>
          Go back
        </BrentoButton>
      </div>
    </div>
  );
};

export default Cart;
