import React from "react";
import { useHistory } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import LoadingButton from "@mui/lab/LoadingButton";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 2,
    padding: "0.75rem 1.5rem",
    "&.MuiButton-outlined": {
      borderWidth: 2,
    },
    "&.MuiButton-contained": {
      "&.Mui-disabled": {
        backgroundColor: theme.palette.primary.lightest,
        color: theme.palette.primary.light,
      },
    },
    "&.MuiButton-containedSizeLarge": {
      padding: "1rem 2rem",
    },
  },
}));

const BrentoLoadingButton = ({
  startIcon,
  endIcon,
  children,
  pending,
  size,
  color = "primary",
  fullWidth = false,
  onClick,
  disabled,
  variant = "contained",
  type,
}) => {
  const classes = useStyles();
  let history = useHistory();
  return (
    <LoadingButton
      classes={{ root: classes.root, outlined: classes.outlined }}
      onClick={onClick}
      variant={variant}
      color={color}
      startIcon={startIcon}
      endIcon={endIcon}
      loading={pending}
      size={size}
      fullWidth={fullWidth}
      disabled={disabled}
      key={`${pending}`}
      type={type}
    >
      {children}
    </LoadingButton>
  );
};

export default BrentoLoadingButton;
