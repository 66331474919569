import React from "react";
import "./ConfirmAddCart.scss";
import ModalControls from "../../ModalControls";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setModalOpen } from "../../../../../actions/layout";

const ConfirmAddCart = ({ data }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleGotoCart = () => {
    history.push("/cart");
    dispatch(setModalOpen(false));
  };

  const handleGotoMenu = () => {
    history.push("/menu");
    dispatch(setModalOpen(false));
  };

  return (
    <div className="confirm-add-cart">
      <div className="confirm-add-cart__main">
        <img
          src={data.meal.mealImageUrl}
          alt={data.meal.mealName}
          className="confirm-add-cart__img"
        />
        <h3 className="confirm-add-cart__header">
          <span className="confirm-add-cart__meal-name">
            {data.meal.mealName}
          </span>{" "}
          was added to your cart
        </h3>
      </div>
      <ModalControls
        primaryText="Go to cart"
        primaryAction={() => handleGotoCart()}
        secondaryText="Back to menu"
        secondaryAction={() => handleGotoMenu()}
      />
    </div>
  );
};

export default ConfirmAddCart;
