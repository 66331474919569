import React from "react";
import Container from "../../Layout/Container";
import Section from "../../Layout/Section/Section";
import "./WhyBrentoBox.scss";
import WhyBrentoImage from "../../../img/hero2.jpg";

const WhyBrentoBox = () => {
  return (
    <Section sectionColor="none" backgroundImage={`url(${WhyBrentoImage})`}>
      <Container>
        <div className="why-brento-box">
          {/* <div className="why-brento-box__content">
            <h3 className="header-4 font-primary">Why Choose Brento Box?</h3>
          </div> */}
        </div>
      </Container>
    </Section>
  );
};

export default WhyBrentoBox;
