import React from "react";
import { Link } from "react-router-dom";
import "./QuickLinks.scss";

const quickLinkContents = [
  { url: "/admin/meals", label: "Meals" },
  { url: "/admin/menu", label: "Menu" },
  { url: "/admin/orders", label: "Orders" },
  { url: "/admin/discount", label: "Discounts" },
  { url: "/admin/zip-codes", label: "Zip Codes" },
];

const QuickLink = ({ pathString, label }) => {
  return (
    <Link to={pathString} className="quick-links__link">
      <h3>{label}</h3>
    </Link>
  );
};

const QuickLinks = () => {
  return (
    <div className="quick-links">
      <h2 className="header-5">Quick Links</h2>
      <div className="quick-links__content">
        {quickLinkContents.map((quickLink) => {
          return (
            <QuickLink
              pathString={quickLink.url}
              label={quickLink.label}
              key={quickLink.pathString}
            />
          );
        })}
      </div>
    </div>
  );
};

export default QuickLinks;
