import axios from "axios";

export const updateInstructionsRequest = async (instructions, id, testMode) => {
  console.log(instructions);
  console.log(id);
  console.log(testMode);
  try {
    let baseUrl = testMode ? "test-orders" : "orders";
    let res = await axios.post(
      `/api/${baseUrl}/update-order-instructions/${id}`,
      { specialInstructions: instructions }
      // {
      //   headers: { "Content-Type": "application/json" },
      // }
    );
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};
