import React, { useEffect, useState } from "react";
import { List, ListItem, ListItemText } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useDispatch, useSelector } from "react-redux";
import { getAllMeals, setSelectedMeal } from "../../../actions/meal";
import { setDrawerComponent, setDrawerOpen } from "../../../actions/layout";
import { Link } from "react-router-dom";
import { alpha } from "@mui/material/styles";
import "./MealManager.scss";
import BrentoButton from "../../UI/BrentoButton";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(3),
  },
  deleteButton: {
    color: theme.palette.error.dark,
    borderColor: theme.palette.error.dark,
    "&:hover": {
      backgroundColor: alpha(
        theme.palette.error.main,
        theme.palette.action.hoverOpacity
      ),
    },
  },
  deleteConfirmButton: {
    color: "#FFF",
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

const MealManager = ({ selectedMeal }) => {
  const [textFilter, setTextFilter] = useState(null);
  const dispatch = useDispatch();
  const classes = useStyles();
  const availableMeals = useSelector((state) => state.meal.meals);
  const mealsLoading = useSelector((state) => state.meal.mealsLoading);
  const drawerOpen = useSelector((state) => state.layout.drawerOpen);

  useEffect(() => {
    dispatch(getAllMeals());
    dispatch(setDrawerComponent("meal-mgr-action-panel"));
  }, [dispatch]);

  const handleSelectMeal = (selectedMealObject) => {
    dispatch(setDrawerOpen(true));
    if (selectedMealObject === selectedMeal) {
      dispatch(setSelectedMeal(null));
    } else {
      dispatch(setSelectedMeal(selectedMealObject));
    }
  };

  return (
    <div className={classes.root}>
      <MealManagerHeader />
      <div className="meal-manager">
        {availableMeals && availableMeals.length > 0 ? (
          <>
            <div className="meal-manager__main">
              <MealManagerFilter setTextFilter={setTextFilter} />
              <div
                className={`meal-manager__selection-panel ${
                  drawerOpen
                    ? "meal-manager__selection-panel--shrink"
                    : "meal-manager__selection-panel--default"
                }`}
              >
                <List>
                  {mealsLoading ? (
                    <p>Loading...</p>
                  ) : availableMeals && textFilter ? (
                    availableMeals
                      .filter((meal) =>
                        meal.mealName
                          .toLowerCase()
                          .includes(textFilter.toLowerCase())
                      )
                      .map((meal) => {
                        return (
                          <ListItem
                            button
                            selected={
                              selectedMeal && selectedMeal._id === meal._id
                            }
                            onClick={() => handleSelectMeal(meal)}
                            divider={true}
                            key={meal._id}
                          >
                            <ListItemText
                              sx={"width:  90%"}
                              primary={meal.mealName + " " + meal.mealSubtitle}
                              primaryTypographyProps={{ noWrap: true }}
                            />
                            <ListItemText
                              className="ordered-average"
                              primary={
                                Math.round(
                                  meal.analytics.orderedAverage * 100
                                ) / 100
                              }
                              primaryTypographyProps={{ noWrap: true }}
                            />
                          </ListItem>
                        );
                      })
                  ) : (
                    availableMeals.map((meal) => {
                      return (
                        <ListItem
                          button
                          selected={
                            selectedMeal && selectedMeal._id === meal._id
                          }
                          onClick={() => handleSelectMeal(meal)}
                          divider={true}
                          key={meal._id}
                        >
                          <ListItemText
                            sx={"width:  80%"}
                            primary={meal.mealName + " " + meal.mealSubtitle}
                            primaryTypographyProps={{ noWrap: true }}
                          />
                          <ListItemText
                            className="ordered-average"
                            primary={
                              Math.round(meal.analytics.orderedAverage * 100) /
                              100
                            }
                            primaryTypographyProps={{ noWrap: true }}
                          />
                        </ListItem>
                      );
                    })
                  )}
                </List>
              </div>
              {/* <Drawer
                open={drawerOpen}
                anchor="right"
                onClose={() => handleCloseDrawer()}
                variant="permanent"
              >
                <ActionPanel
                  meal={selectedMeal}
                  selectedMeal={selectedMeal}
                  setSelectedMeal={setSelectedMeal}
                  mealsOnMenu={mealsOnMenu}
                />
              </Drawer> */}
              <div className="meal-manager__toolbar">
                <p className="meal-manager__meal-count">
                  <span className="meal-count-value">
                    {availableMeals.length}
                  </span>{" "}
                  meals total
                </p>
                <Link to="/admin/meals/add-meal">
                  <BrentoButton startIcon={<AddOutlinedIcon />}>
                    Add Meal
                  </BrentoButton>
                </Link>
              </div>
            </div>
          </>
        ) : (
          <SelectionPanelEmptyState />
        )}
      </div>
    </div>
  );
};

const SelectionPanelEmptyState = () => {
  return (
    <div className="sel-pan-empty-state">
      <p className="sel-pan-empty-state__header">No meals available</p>
      <p className="sel-pan-empty-state__body">
        Use the button below to get started
      </p>
      <Link to="/admin/meals/add-meal">
        <BrentoButton startIcon={<AddOutlinedIcon />}>Add Meal</BrentoButton>
      </Link>
    </div>
  );
};

const MealManagerHeader = () => {
  return (
    <div className="menu-manager-header">
      <div>
        <h2 className="meal-manager-header__title">Meal Manager</h2>
        <p className="meal-manager-header__label">
          Add, remove, or edit your meals
        </p>
      </div>
    </div>
  );
};

const MealManagerFilter = ({ textFilter, setTextFilter }) => {
  const handleTextChange = (e) => {
    setTextFilter(e.target.value);
  };
  return (
    <div className="meal-manager__filter">
      <input
        type="text"
        className="meal-manager__text-filter"
        placeholder="Search"
        value={textFilter}
        onChange={handleTextChange}
      ></input>
    </div>
  );
};

export default MealManager;
