import React from "react";
import { TextField } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 2,
    },
    "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall input": {
      padding: "12px",
    },
    "& .MuiInputLabel-sizeSmall": {
      lineHeight: "1.75em",
    },
  },
}));

const BrentoTextField = ({
  label,
  variant,
  size,
  type,
  error,
  multiline,
  rows,
  fullWidth,
  disabled,
  placeholder,
  value,
  onChange,
  onBlur,
  onWheel,
  name,
  helperText,
  InputProps,
  inputProps,
  InputLabelProps,
}) => {
  const classes = useStyles();
  return (
    <TextField
      classes={{ root: classes.root }}
      className="brento-text-input"
      label={label}
      type={type}
      disabled={disabled}
      variant={variant}
      color="primary"
      size={size}
      error={error}
      fullWidth={fullWidth}
      key={`${disabled}`}
      multiline={multiline}
      rows={rows}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onWheel={onWheel}
      name={name}
      helperText={helperText}
      InputProps={InputProps}
      inputProps={inputProps}
      InputLabelProps={InputLabelProps}
    />
  );
};

export default BrentoTextField;
