import axios from "axios";

export const editPromoCodeRequest = async (
  testMode,
  promoCodeId,
  formValues
) => {
  console.log("Editing promo code...");
  try {
    let requestUrl = testMode
      ? `/api/test-promo-code/edit-promo-code/${promoCodeId}`
      : `/api/promo-code/edit-promo-code/${promoCodeId}`;

    let res = await axios.post(requestUrl, formValues, {
      headers: { "Content-Type": "application/json" },
    });
    return res.data;
  } catch (err) {
    console.log(err.response);
    return err;
  }
};
