import React, { useState, useEffect } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import CheckoutFieldSet from "./CheckoutFieldset";
import BrentoSelect from "../../UI/BrentoSelect/BrentoSelect";
import { stateValueOptions } from "../CheckoutForm/stateValueOptions";
import BrentoLoadingButton from "../../UI/BrentoLoadingButton";
import BrentoButton from "../../UI/BrentoButton";
import BrentoTextField from "../../UI/BrentoTextField/BrentoTextField";
import { errorsInCurrentStep } from "./utils/errorsInCurrentStep";
import { allErrorsTouched } from "./utils/allErrorsTouched";
import Alert from "../../UI/Alert/Alert";
import DetectPaymentIntent from "../DetectPaymentIntent/DetectPaymentIntent";
import CCDetails from "./Payment/CCDetails/CCDetails";
import BillingAddress from "./Payment/BillingAddress/BillingAddress";
import DiscountCode from "./Payment/DiscountCode/DiscountCode";

const PaymentFields = ({
  formik,
  setOrderAttemptDetails,
  orderTotal,
  setActiveStep,
  setActiveValidationSchema,
  validationSchema,
  pending,
  checkoutComplete,
  paymentError,
  paymentSucceeded,
}) => {
  const [cardNumberError, setCardNumberError] = useState(null);
  const [cardExpiryError, setCardExpiryError] = useState(null);
  const [cardCvcError, setCardCvcError] = useState(null);
  let schemaKeys = Object.keys(validationSchema.fields);
  let errorKeys = Object.keys(formik.errors);

  const handlePreviousStep = () => {
    setActiveValidationSchema("contact");
    window.scrollTo(0, 0);
    setActiveStep(1);
  };

  const validateCardField = (e, setErrorHook) => {
    // formik.setFieldValue("ccnumber", );
    if (e.error) {
      setErrorHook(e.error.message);
    } else {
      setErrorHook(null);
    }
  };

  useEffect(() => {
    if (formik.values.useDifferentBillingAddress === "true") {
      setActiveValidationSchema("paymentWithBilling");
    } else {
      setActiveValidationSchema("payment");
    }
  });

  return (
    <>
      <DetectPaymentIntent checkoutComplete={checkoutComplete} />
      <CCDetails
        cardNumberError={cardNumberError}
        setCardNumberError={setCardNumberError}
        cardExpiryError={cardExpiryError}
        setCardExpiryError={setCardExpiryError}
        cardCvcError={cardCvcError}
        setCardCvcError={setCardCvcError}
      />
      <BillingAddress formik={formik} />
      <DiscountCode
        formik={formik}
        orderTotal={orderTotal}
        setOrderAttemptDetails={setOrderAttemptDetails}
      />
      {errorsInCurrentStep(errorKeys, schemaKeys) &&
      allErrorsTouched(errorKeys, formik.touched) ? (
        <Alert
          header="We have found the following errors:"
          type="error"
          content={
            <ul>
              {Object.keys(formik.errors)
                .filter((key) => {
                  return formik.touched[key] === true;
                })
                .map((key) => {
                  return (
                    <li key={key} className="checkout-alert-error">
                      {formik.errors[key]}
                    </li>
                  );
                })}
            </ul>
          }
        />
      ) : paymentError ? (
        <Alert
          header="We have found the following error:"
          type="error"
          content={<p>{paymentError}</p>}
        />
      ) : null}
      <BrentoLoadingButton
        size="large"
        fullWidth={true}
        type="submit"
        pending={pending || paymentSucceeded}
        onClick={formik.handleSubmit}
        disabled={
          (errorsInCurrentStep(errorKeys, schemaKeys) &&
            allErrorsTouched(errorKeys, formik.touched)) ||
          cardNumberError ||
          cardExpiryError ||
          cardCvcError
        }
      >
        Place your order
      </BrentoLoadingButton>
      <BrentoButton
        variant="text"
        fullWidth={true}
        size="large"
        onClick={() => handlePreviousStep()}
      >
        Return to Contact & Delivery
      </BrentoButton>
    </>
  );
};

export default PaymentFields;
