import axios from "axios";
import moment from "moment";

export const purchaseGiftCardRequest = async (testMode, giftCardData) => {
  let newGiftCardData = { ...giftCardData };
  newGiftCardData.recipientDeliveryDate = moment(
    newGiftCardData.recipientDeliveryDate
  ).toISOString();

  let res = await axios({
    method: "post",
    url: testMode
      ? "/api/test-discount/create-discount"
      : "/api/discount/create-discount",
    data: newGiftCardData,
    headers: { "Content-Type": "application/json" },
  });

  return res.data;
};
