import React from "react";
import "./PromoCodeDetailsHero.scss";
import BrentoButton from "../../../UI/BrentoButton";
import { Link } from "react-router-dom";

const PromoCodeDetailsHero = ({ discountCode, promoCodeId }) => {
  return (
    <div className="promo-code-details-hero">
      <div className="promo-code-details-hero__text-content">
        <p className="eyebrow-1">Promo code:</p>
        <h1 className="header-3">{discountCode}</h1>
      </div>
      <Link to={`/admin/discount/promo-code/edit-promo-code/${promoCodeId}`}>
        <BrentoButton variant="outlined" size="small">
          Edit promo code
        </BrentoButton>
      </Link>
    </div>
  );
};

export default PromoCodeDetailsHero;
