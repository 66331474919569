import React, { useState } from "react";
import "./MenuItem.scss";
import { Link } from "react-router-dom";
import MenuItemDietaryTag from "./MenuItemDietaryTag/MenuItemDietaryTag";
import { trackSelectItem } from "../../../tracking/trackSelectItem";
import PlaceholderItem from "./PlaceholderItem/PlaceholderItem";

const MenuItem = ({ menuItem, isMobile, listId, listName }) => {
  const {
    mealName,
    mealSubtitle,
    _id,
    price,
    mealImageUrl,
    mealIsNew,
    mealIsVegetarian,
    mealIsGlutenFree,
  } = menuItem;

  const [isHovered, setHovered] = useState(false);
  const [isLoaded, setLoaded] = useState(false);

  const handleImageLoaded = () => {
    setLoaded(true);
  };

  const handleSelectMeal = (meal, listId, listName) => {
    trackSelectItem(menuItem, listId, listName);
  };

  return (
    <li
    // className={`menu-item ${
    //   isHovered ? "menu-item--hover" : "menu-item--default"
    // } ${isLoaded ? "menu-item--loaded" : "menu-item--unloaded"}`}
    >
      {isLoaded ? null : (
        <>
          <PlaceholderItem isMobile={isMobile} />
        </>
      )}
      <div
        className={`menu-item ${
          isHovered ? "menu-item--hover" : "menu-item--default"
        } ${isLoaded ? "menu-item--loaded" : "menu-item--unloaded"}`}
      >
        <Link
          to={`/menu/meals/${_id}`}
          onMouseOver={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onClick={() => handleSelectMeal(menuItem, listId, listName)}
        >
          <div className="menu-item__image-container">
            {mealIsNew ? <p className="menu-item__new">New</p> : null}
            <img
              className="menu-item__image"
              src={mealImageUrl}
              alt={`${mealName} ${mealSubtitle}`}
              onLoad={() => handleImageLoaded()}
            />
          </div>
          <div className="menu-item__contents">
            <div className="menu-item__title">
              <h3 className="menu-item__name">{mealName}</h3>
              {mealSubtitle && (
                <h4 className="menu-item__subtitle">{mealSubtitle}</h4>
              )}
            </div>
            <p className="menu-item__price">${price}</p>
          </div>
        </Link>
        <div className="menu-item__dietary-tags">
          {mealIsGlutenFree && (
            <MenuItemDietaryTag acronym="GF" fullName="Gluten-free" />
          )}
          {mealIsVegetarian && (
            <MenuItemDietaryTag acronym="VEG" fullName="Vegetarian" />
          )}
        </div>
      </div>
    </li>
  );
};

export default MenuItem;
