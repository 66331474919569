import { SET_ALERT, REMOVE_ALERT } from "./types";
import uuid from "uuid";
// Test

export const setAlert = (alertTitle, alertMsg, alertType, timeout = 28000) => (
  dispatch
) => {
  try {
    const id = uuid.v4();
    dispatch({
      type: SET_ALERT,
      payload: { alertTitle, alertMsg, alertType, id },
    });
    setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
  } catch (err) {
    console.log(err);
  }
};
