import React from "react";
import { Button, IconButton, TextField, InputAdornment } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

const TeamMemberEmails = ({ formik }) => {
  const handleRemoveEmail = (emailIndex) => {
    let newArray = [
      ...formik.values.teamMemberEmails.filter(
        (value, index) => index !== emailIndex
      ),
    ];
    formik.setFieldValue("teamMemberEmails", newArray);
  };

  const handleAddEmail = () => {
    let newArray = [...formik.values.teamMemberEmails, ""];
    formik.setFieldValue("teamMemberEmails", newArray);
  };

  return (
    <div className="create-promo-code-form__field-group">
      <h2>Team member emails</h2>
      <p className="supporting-text">
        Add the email addresses for all of your team members who you want to
        have access to this discount code.
      </p>
      <ul className="team-member-emails">
        {formik.values.teamMemberEmails.map((memberEmail, index) => {
          return (
            <TeamMemberEmail
              formik={formik}
              emailAddress={memberEmail}
              handleRemoveEmail={handleRemoveEmail}
              index={index}
            />
          );
        })}
      </ul>
      <Button
        startIcon={<AddIcon />}
        variant="outlined"
        onClick={() => handleAddEmail()}
      >
        Add email
      </Button>
    </div>
  );
};

const TeamMemberEmail = ({
  formik,
  emailAddress,
  index,
  handleRemoveEmail,
}) => {
  return (
    <li className="team-member-emails__email-item">
      <TextField
        type="email"
        fullWidth
        name={`teamMemberEmails[${index}]`}
        value={emailAddress}
        placeholder="brento-team-member@gmail.com"
        onChange={formik.handleChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{index + 1}</InputAdornment>
          ),
        }}
        error={
          formik.touched.teamMemberEmails &&
          formik.errors.teamMemberEmails &&
          formik.touched.teamMemberEmails[index] &&
          formik.errors.teamMemberEmails[index]
        }
        helperText={
          formik.touched.teamMemberEmails &&
          formik.errors.teamMemberEmails &&
          formik.touched.teamMemberEmails[index] &&
          formik.errors.teamMemberEmails[index]
            ? formik.errors.teamMemberEmails[index]
            : null
        }
      />
      <IconButton
        onClick={() => handleRemoveEmail(index)}
        disabled={formik.values.teamMemberEmails.length === 1}
      >
        <DeleteIcon />
      </IconButton>
    </li>
  );
};

export default TeamMemberEmails;
