import React from "react";
import BrentoTextField from "../../../UI/BrentoTextField/BrentoTextField";

const ContactInfoFields = ({ formik }) => {
  return (
    <div className="gift-card-checkout__field-group">
      <h2 className="header-5">Your contact information</h2>
      <p className="body-2">
        Enter your contact information below. We will use this information to
        process the payment and contact you in case of any issues. We'll also
        use the name provided here to include in the email with the gift card.
      </p>
      <div className="gift-card-checkout__field">
        <BrentoTextField
          fullWidth={true}
          label="First and last name"
          required
          value={formik.values.contactName}
          name="contactName"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.contactName && formik.errors.contactName
              ? true
              : false
          }
          helperText={formik.touched.contactName && formik.errors.contactName}
        ></BrentoTextField>
      </div>
      <div className="gift-card-checkout__field">
        <BrentoTextField
          fullWidth={true}
          label="Email address"
          required
          value={formik.values.contactEmail}
          name="contactEmail"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type="email"
          error={
            formik.touched.contactEmail && formik.errors.contactEmail
              ? true
              : false
          }
          helperText={formik.touched.contactEmail && formik.errors.contactEmail}
        ></BrentoTextField>
      </div>
      <div className="gift-card-checkout__field">
        <BrentoTextField
          fullWidth={true}
          label="Phone number"
          required
          value={formik.values.contactPhone}
          name="contactPhone"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          type="phone"
          error={
            formik.touched.contactPhone && formik.errors.contactPhone
              ? true
              : false
          }
          helperText={formik.touched.contactPhone && formik.errors.contactPhone}
        ></BrentoTextField>
      </div>
    </div>
  );
};

export default ContactInfoFields;
