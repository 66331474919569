import React from "react";
import "./BrentoModal.scss";
import BrentoButton from "../../UI/BrentoButton";

const ModalControls = ({
  primaryAction,
  primaryText,
  secondaryAction,
  secondaryText,
}) => {
  return (
    <div className="brento-modal__modal-controls">
      <BrentoButton fullWidth={true} onClick={primaryAction}>
        {primaryText}
      </BrentoButton>
      <BrentoButton
        fullWidth={true}
        variant="outlined"
        onClick={secondaryAction}
      >
        {secondaryText}
      </BrentoButton>
    </div>
  );
};

export default ModalControls;
