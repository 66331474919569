import React from "react";
import "./AnalyticsStats.scss";
import { filterAnalyticsData } from "../utils/filterAnalyticsData";

const AnalyticsStats = ({ analyticsData, filterBy, dateValues }) => {
  const filteredData = filterAnalyticsData(
    filterBy,
    analyticsData,
    dateValues.current.year,
    dateValues.current.quarter,
    dateValues.current.month
  );
  return filteredData && filteredData.totalRevenue ? (
    <div className="analytics-stats">
      <AnalyticsStat
        value={Number(filteredData.totalRevenue / 100).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}
        label="total revenue"
        dateValues={dateValues}
      />
      <AnalyticsStat
        value={filteredData.totalOrders}
        label="total orders"
        dateValues={dateValues}
      />
      <AnalyticsStat
        value={filteredData.totalMeals}
        label="total meals made"
        dateValues={dateValues}
      />
      <AnalyticsStat
        value={Number(filteredData.averageRevenuePerOrder / 100).toLocaleString(
          "en-US",
          {
            style: "currency",
            currency: "USD",
          }
        )}
        label="avg. revenue per order"
        dateValues={dateValues}
      />
    </div>
  ) : null;
};

const AnalyticsStat = ({ value, label, dateValues }) => {
  return (
    <div className="analytics-stats__stat">
      <p className="analytics-stats__value">{value}</p>
      <p className="analytics-stats__label">{label}</p>
    </div>
  );
};

export default AnalyticsStats;
