import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMenuData } from "../../../actions/menu";
import { getAllMeals } from "../../../actions/meal";
import { setDrawerComponent } from "../../../actions/layout";
import UpdateMenuForm from "../UpdateMenuForm/UpdateMenuForm";
import "./MenuManager.scss";

const MenuManager = () => {
  const dispatch = useDispatch();
  const testMode = useSelector((state) => state.admin.testMode);
  const activeMenu = useSelector((state) => state.menu.activeMenu);
  const menuDataLoading = useSelector((state) => state.menu.menuDataLoading);
  const meals = useSelector((state) => state.meal.meals);

  useEffect(() => {
    dispatch(setDrawerComponent(null));
    dispatch(getAllMeals());
    dispatch(getMenuData(testMode));
  }, [dispatch]);

  return (
    <div className="menu-manager">
      <MenuManagerHeader dateUpdated={activeMenu.dateUpdated} />
      {menuDataLoading ? (
        "Loading..."
      ) : (
        <UpdateMenuForm menu={activeMenu} availableMeals={meals} />
      )}
    </div>
  );
};

const MenuManagerHeader = ({ dateUpdated }) => {
  return (
    <div className="menu-manager-header">
      <div>
        <h2 className="menu-manager-header__title">Menu Manager</h2>
        <p className="menu-manager-header__label">
          Update your menu for this week
        </p>
      </div>
      {/* <div className="menu-manager-header__date-field">
        <p className="menu-manager-header__date-label">Menu last updated:</p>
        {dateUpdated ? (
          <p className="menu-manager-header__date-value">
            {moment(dateUpdated).format("dddd, MMMM Do YYYY, h:mm:ss a")}
          </p>
        ) : null}
      </div> */}
    </div>
  );
};

export default MenuManager;
