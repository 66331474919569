import React, { useEffect } from "react";
import "./PromoCodeManager.scss";
import BrentoButton from "../../UI/BrentoButton";
import { Link } from "react-router-dom";
import { getAllPromoCodes } from "../../../actions/promoCodes";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { formatDiscountAudience } from "./utils/formatDiscountAudience";
import moment from "moment";

const PromoCodeManager = () => {
  const dispatch = useDispatch();
  const testMode = useSelector((state) => state.admin.testMode);
  const promoCodes = useSelector((state) => state.promoCodes.promoCodes);
  const promoCodesLoading = useSelector(
    (state) => state.promoCodes.promoCodesLoading
  );

  useEffect(() => {
    console.log("Getting promo codes...");
    dispatch(getAllPromoCodes(testMode));
  }, []);

  return (
    <div className="promo-code-manager">
      <div>
        <div className="promo-code-manager__header">
          <h2 className="header-5">Promo codes</h2>
        </div>
        {promoCodesLoading ? (
          <p>Loading...</p>
        ) : promoCodes && promoCodes.length > 0 ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Promo code</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Audience</TableCell>
                  <TableCell>Created</TableCell>
                </TableRow>
              </TableHead>
              {promoCodes.map((promoCode) => {
                return (
                  <TableRow>
                    <TableCell>
                      <Link to={`/admin/discount/promo-code/${promoCode._id}`}>
                        {promoCode.discountCode}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {promoCode.isActive ? "Active" : "Inactive"}
                    </TableCell>
                    <TableCell>
                      {formatDiscountAudience(promoCode.discountAudience)}
                    </TableCell>
                    <TableCell>
                      {promoCode.createdAt
                        ? moment(promoCode.createdAt).fromNow()
                        : null}
                    </TableCell>
                  </TableRow>
                );
              })}
            </Table>
          </TableContainer>
        ) : (
          <p>No promo codes found</p>
        )}
      </div>
      <div className="promo-code-manager__footer">
        <p>
          <b>{promoCodes.length}</b> promo codes found
        </p>
        <Link to="/admin/discount/promo-code/create-promo-code">
          <BrentoButton>Create promo code</BrentoButton>
        </Link>
      </div>
    </div>
  );
};

export default PromoCodeManager;
