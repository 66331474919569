import React from "react";
import {
  Button,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  InputAdornment,
} from "@mui/material";
import BrentoTextField from "../../../UI/BrentoTextField/BrentoTextField";

const SelectAmountFields = ({
  customGiftCardAmount,
  setCustomGiftCardAmount,
  handleSetFixedAmount,
  formik,
  isMobile,
}) => {
  return (
    <div className="create-discount-form__field-group">
      <br />
      <FormControl>
        <FormLabel>Select an amount:</FormLabel>
        <RadioGroup name="giftCardAmount" row={!isMobile}>
          <FormControlLabel
            onClick={() => handleSetFixedAmount(25)}
            checked={
              !customGiftCardAmount && formik.values.giftCardAmount === 25
            }
            control={<Radio />}
            label="$25"
          />
          <FormControlLabel
            onClick={() => handleSetFixedAmount(50)}
            checked={
              !customGiftCardAmount && formik.values.giftCardAmount === 50
            }
            control={<Radio />}
            label="$50"
          />
          <FormControlLabel
            onClick={() => handleSetFixedAmount(100)}
            checked={
              !customGiftCardAmount && formik.values.giftCardAmount === 100
            }
            control={<Radio />}
            label="$100"
          />
          <FormControlLabel
            onClick={() => setCustomGiftCardAmount(true)}
            checked={customGiftCardAmount}
            control={<Radio />}
            label="Custom"
          />
        </RadioGroup>
      </FormControl>
      {/* <ButtonGroup size="large" variant="outlined">
        <Button
          onClick={() => handleSetFixedAmount(25)}
          // variant={
          //   !customGiftCardAmount &&
          //   formik.values.giftCardAmount === 25 &&
          //   "contained"
          // }
        >
          $25
        </Button>
        <Button
          onClick={() => handleSetFixedAmount(50)}
          // variant={
          //   !customGiftCardAmount &&
          //   formik.values.giftCardAmount === 50 &&
          //   "contained"
          // }
        >
          $50
        </Button>
        <Button
          onClick={() => handleSetFixedAmount(100)}
          // variant={
          //   !customGiftCardAmount &&
          //   formik.values.giftCardAmount === 100 &&
          //   "contained"
          // }
        >
          $100
        </Button>
        <Button
          onClick={() => setCustomGiftCardAmount(true)}
          // variant={customGiftCardAmount && "contained"}
        >
          Custom
        </Button>
      </ButtonGroup> */}
      {customGiftCardAmount ? <CustomAmountField formik={formik} /> : null}
    </div>
  );
};

const CustomAmountField = ({ formik }) => {
  return (
    <div className="gift-card-checkout__custom-amount-field">
      <FormLabel>Enter a custom dollar amount:</FormLabel>
      <br />
      <BrentoTextField
        fullWidth
        name="giftCardAmount"
        type="number"
        onChange={formik.handleChange}
        onBlur={(e) => {
          formik.handleBlur(e);
          formik.setFieldValue(
            "giftCardAmount",
            Number.parseInt(e.target.value)
          );
        }}
        onWheel={(e) => e.target.blur()}
        error={formik.touched.giftCardAmount && formik.errors.giftCardAmount}
        value={formik.values.giftCardAmount}
        helperText={
          formik.errors.giftCardAmount
            ? formik.errors.giftCardAmount
            : "Enter a dollar amount between $10 and $500"
        }
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
        inputProps={{
          min: 20,
          max: 500,
        }}
      ></BrentoTextField>
    </div>
  );
};

export default SelectAmountFields;
