import * as yup from "yup";
// import { zipCodeWhitelist } from "../../../../utils/isZipInZone";

const phoneRegEx =
  /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

// export const validationGenerator = (schemaType) => {
//   switch (schemaType) {
//     case "contact":
//       console.log("contact schema");
//       return contactSchema;
//     case "payment":
//       console.log("payment schema without billing");
//       return paymentSchema;
//     case "paymentWithBilling":
//       console.log("payment schema WITH billing");
//       return paymentWithBillingSchema;
//     default:
//       return;
//   }
// };

const today = new Date();
today.setHours(0, 0, 0, 0);

export const giftCardSchema = yup.object({
  giftCardAmount: yup
    .number()
    .min(20, "Dollar amount must be at least $20")
    .max(500, "Dollar amount cannot be greater than $500"),
  contactName: yup
    .string("Enter your first and last name")
    .min(4, "Name should be at least 4 characters")
    .required("Your first and last name is required"),
  contactEmail: yup
    .string("Enter your email address")
    .email("Must be a valid email")
    .max(255)
    .required("Contact email is required"),
  contactPhone: yup
    .string("Enter your phone number")
    .matches(phoneRegEx, "Invalid phone number")
    .required("Contact phone number is required"),
  recipientEmail: yup
    .string("Enter the email address where you want the gift card sent to")
    .email("Must be a valid email")
    .max(255)
    .required("Recipient email address is required"),
  recipientDeliveryDate: yup
    .date()
    .min(today, "You cannot schedule email delivery in the past"),
  specialMessage: yup
    .string("Enter a special message to be included in the email")
    .max(250, "Special message contains too many characters"),
});

// const paymentSchema = yup.object({
//   name: yup
//     .string("Enter your first and last name")
//     .min(4, "Name should be at least 4 characters")
//     .required("Name is required"),
//   email: yup
//     .string("Enter your email address")
//     .email("Must be a valid email")
//     .max(255)
//     .required("Email is required"),
//   phone: yup
//     .string("Enter your phone number")
//     .matches(phoneRegEx, "Invalid phone number")
//     .required("Phone number is required"),
//   addressLine1: yup
//     .string("Enter line one of your address")
//     .required("Address Line 1 is required"),
//   addressLine2: yup.string("Enter line two of your address"),
//   city: yup.string("Enter your city").required("City is required"),
//   state: yup
//     .string("Enter your state")
//     .matches("AZ", "Outside of supported state")
//     .required("State is required"),
//   zipCode: yup
//     .mixed()
//     .oneOf(zipCodeWhitelist, "Outside of delivery zone")
//     .required("ZIP Code is required"),
//   specialInstructions: yup.string("Enter special delivery instructions"),
// });

// const paymentSchema = yup.object({
//   useDifferentBillingAddress: yup.string("Select billing option"),
// });

// const paymentWithBillingSchema = yup.object({
//   billingAddressLine1: yup
//     .string("Enter line one of your address")
//     .required("Address Line 1 is required"),
//   billingAddressLine2: yup.string("Enter line two of your address"),
//   billingCity: yup.string("Enter your city").required("City is required"),
//   billingState: yup.string("Enter your state").required("State is required"),
//   billingZipCode: yup.mixed().required("ZIP Code is required"),
//   specialInstructions: yup.string("Enter special delivery instructions"),
// });
