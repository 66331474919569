import moment from "moment";

const getAllNodes = (dataPoint) => {
  let nodes = Object.keys(dataPoint).filter((key) => key !== "data");
  return nodes;
};

// const getYearNodes = (analyticsData, callBack) => {
//   let yearNodes = getAllNodes(analyticsData);
//   yearNodes.forEach((yearNode) => callBack(yearNode, analyticsData[yearNode]));
// };

// const getQuarterNodes = (analyticsData, callBack) => {
//   let yearNodes = getAllNodes(analyticsData);
//   yearNodes.forEach((yearNode) => {
//     let quarterNodes = getAllNodes(analyticsData[yearNode]);
//     quarterNodes.forEach((quarterNode) => {
//       callBack(
//         `${quarterNode} - ${yearNode}`,
//         analyticsData[yearNode][quarterNode]
//       );
//     });
//   });
// };

// const getMonthNodes = (analyticsData, callBack) => {
//   let yearNodes = getAllNodes(analyticsData);
//   yearNodes.forEach((yearNode) => {
//     let quarterNodes = getAllNodes(analyticsData[yearNode]);
//     quarterNodes.forEach((quarterNode) => {
//       let monthNodes = getAllNodes(analyticsData[yearNode][quarterNode]);
//       monthNodes.forEach((monthNode) => {
//         callBack(
//           `${moment(monthNode).format("MMMM")} - ${yearNode}`,
//           analyticsData[yearNode][quarterNode][monthNode]
//         );
//       });
//     });
//   });
// };

// export const getSubDataNodes = (
//   analyticsData,
//   filterBy,
//   dateValues,
//   breakdownBy
// ) => {
//   let { year, month, quarter } = dateValues.current;
//   console.log(year);
//   let results = [];

//   const addDataNode = (node, data) => {
//     results.push({ name: node, data: { ...data.data } });
//   };

//   if (filterBy === "all-time") {
//     if (breakdownBy === "year") {
//       getYearNodes(analyticsData, addDataNode);
//     } else if (breakdownBy === "quarter") {
//       getQuarterNodes(analyticsData, addDataNode);
//     } else if (breakdownBy === "month") {
//       getMonthNodes(analyticsData, addDataNode);
//     }
//   }
//   if (filterBy === "this-year") {
//     if (breakdownBy === "quarter") {
//       getQuarterNodes(analyticsData, addDataNode);
//     } else if (breakdownBy === "month") {
//       getMonthNodes(analyticsData, addDataNode);
//     }
//   }
//   if (filterBy === "this-quarter") {
//     let dataNodes = getAllNodes(analyticsData[year][quarter]);
//     dataNodes.forEach((node) => {
//       results.push({ name: node, data: analyticsData[year][quarter][node] });
//     });
//   }
//   if (filterBy === "this-month") {
//     let dataNodes = getAllNodes(analyticsData[year][quarter][month]);
//     dataNodes.forEach((node) => {
//       results.push({
//         name: node,
//         data: analyticsData[year][quarter][month][node],
//       });
//     });
//   }
//   return results;
// };

const pushDataNode = (nodeName, dataPoint, array) => {
  array.push({ name: nodeName, data: { ...dataPoint.data } });
};

const createResultsBreakdown = (filterBy) => {
  let results = {};
  if (filterBy === "all-time") {
    results = { year: [], quarter: [], month: [] };
  } else if (filterBy === "this-year") {
    results = { quarter: [], month: [], window: [] };
  } else if (filterBy === "this-quarter") {
    results = { month: [], window: [] };
  } else if (filterBy === "this-month") {
    results = { window: [] };
  }
  return results;
};

export const getSubDataNodes = (analyticsData, filterBy, dateValues) => {
  let results = createResultsBreakdown(filterBy);
  let { year, month, quarter } = dateValues.current;
  // All Time
  if (filterBy === "all-time") {
    let yearNodes = getAllNodes(analyticsData);
    yearNodes.forEach((yearNode) => {
      pushDataNode(yearNode, analyticsData[yearNode], results.year);
      let quarterNodes = getAllNodes(analyticsData[yearNode]);
      quarterNodes.forEach((quarterNode) => {
        console.log(quarterNode);
        pushDataNode(
          `${quarterNode} - ${yearNode}`,
          analyticsData[yearNode][quarterNode],
          results.quarter
        );
        let monthNodes = getAllNodes(analyticsData[yearNode][quarterNode]);
        monthNodes.forEach((monthNode) => {
          pushDataNode(
            `${moment(monthNode, "MM").format("MMMM")} - ${yearNode}`,
            analyticsData[yearNode][quarterNode][monthNode],
            results.month
          );
        });
      });
    });
    // This year
  } else if (filterBy === "this-year") {
    if (analyticsData[year]) {
      let quarterNodes = getAllNodes(analyticsData[year]);
      quarterNodes.forEach((quarterNode) => {
        pushDataNode(
          `${quarterNode}`,
          analyticsData[year][quarterNode],
          results.quarter
        );
        let monthNodes = getAllNodes(analyticsData[year][quarterNode]);
        monthNodes.forEach((monthNode) => {
          pushDataNode(
            `${moment(monthNode, "MM").format("MMMM")}`,
            analyticsData[year][quarterNode][monthNode],
            results.month
          );
          let windowNodes = getAllNodes(
            analyticsData[year][quarterNode][monthNode]
          );
          windowNodes.forEach((windowNode) => {
            pushDataNode(
              moment(windowNode).format("MMMM DD"),
              analyticsData[year][quarterNode][monthNode][windowNode],
              results.window
            );
          });
        });
      });
    }
    // This quarter
  } else if (filterBy === "this-quarter") {
    if (analyticsData[year][quarter]) {
      let monthNodes = getAllNodes(analyticsData[year][quarter]);
      monthNodes.forEach((monthNode) => {
        pushDataNode(
          `${moment(monthNode, "MM").format("MMMM")}`,
          analyticsData[year][quarter][monthNode],
          results.month
        );
        let windowNodes = getAllNodes(analyticsData[year][quarter][monthNode]);
        windowNodes.forEach((windowNode) => {
          pushDataNode(
            moment(windowNode).format("MMMM DD"),
            analyticsData[year][quarter][monthNode][windowNode],
            results.window
          );
        });
      });
    }

    // This month
  } else if (filterBy === "this-month") {
    if (analyticsData[year][quarter] && analyticsData[year][quarter][month]) {
      let windowNodes = getAllNodes(analyticsData[year][quarter][month]);
      windowNodes.forEach((windowNode) => {
        pushDataNode(
          moment(windowNode).format("MMMM DD"),
          analyticsData[year][quarter][month][windowNode],
          results.window
        );
      });
    }
  }
  return results;
};
