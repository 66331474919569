import React, { useState } from "react";
import "./DiscountCodeSuccess.scss";
import GiftCardSuccess from "./GiftCardSuccess/GiftCardSuccess";
import PromoCodeSuccess from "./PromoCodeSuccess/PromoCodeSuccess";
import { clearCartDiscountCode } from "../../../../../../actions/cart";
import { handleRemoveDiscountRequest } from "../utils/handleRemoveDiscountRequest";
import { useDispatch, useSelector } from "react-redux";

const DiscountCodeSuccess = ({
  discountCodeDetails,
  discountApplied,
  totalAfterDiscount,
  minimumFeeCharged,
  setOrderAttemptDetails,
  setPromoInputValue,
}) => {
  const [removeDiscountLoading, setRemoveDiscountLoading] = useState(false);

  const dispatch = useDispatch();
  console.log(discountCodeDetails);
  const paymentIntentId = useSelector((state) => state.payment.paymentIntentId);
  const testMode = useSelector((state) => state.admin.testMode);
  const cartItems = useSelector((state) => state.cart.cartItems);

  const handleRemoveDiscountCode = async () => {
    setRemoveDiscountLoading(true);
    const updatedOrderDetails = await handleRemoveDiscountRequest(
      cartItems,
      paymentIntentId,
      testMode
    );
    if (updatedOrderDetails) {
      console.log(updatedOrderDetails);
      dispatch(clearCartDiscountCode());
      setOrderAttemptDetails(updatedOrderDetails.orderDetails);
      setPromoInputValue("");
      setRemoveDiscountLoading(false);
    }
  };

  return (
    <>
      {discountCodeDetails &&
      discountCodeDetails.discountCategory === "gift-card" ? (
        <GiftCardSuccess
          discountCodeDetails={discountCodeDetails}
          discountApplied={discountApplied}
          totalAfterDiscount={totalAfterDiscount}
          minimumFeeCharged={minimumFeeCharged}
          handleRemoveDiscountCode={handleRemoveDiscountCode}
          removeDiscountLoading={removeDiscountLoading}
        />
      ) : (
        <PromoCodeSuccess
          discountCodeDetails={discountCodeDetails}
          handleRemoveDiscountCode={handleRemoveDiscountCode}
          removeDiscountLoading={removeDiscountLoading}
        />
      )}
    </>
  );
};

export default DiscountCodeSuccess;
