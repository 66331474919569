import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import BrentoBackButton from "../../../../Components/UI/BrentoBackButton";
import CreateGiftCardForm from "../../../../Components/Admin/CreateGiftCardForm/CreateGiftCardForm";
import { createAdminDiscountRequest } from "./utils/createAdminDiscountRequest";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../../actions/alert";
import ScrollToTop from "../../../../Components/Layout/ScrollToTop";

const CreateGiftCard = () => {
  const [discountCreationSuccessful, setDiscountCreationSuccessful] =
    useState(false);
  const [giftCardData, setGiftCardData] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const finalizeDiscountCreation = () => {
    setDiscountCreationSuccessful(true);
  };

  useEffect(() => {
    if (discountCreationSuccessful) {
      dispatch(
        setAlert(
          "Discount code created successfully",
          `Gift card ${giftCardData.discountCode} has been created successfully`,
          "success"
        )
      );
      history.push("/admin/discount");
    }
  });

  return (
    <div className="admin-create-gift-card">
      <ScrollToTop />
      <div class="admin-create-gift-card__header">
        <BrentoBackButton />
        <h1 className="header-3">Create admin gift card</h1>
      </div>
      <CreateGiftCardForm
        createAdminDiscountRequest={createAdminDiscountRequest}
        discountCreationSuccessful={discountCreationSuccessful}
        finalizeDiscountCreation={finalizeDiscountCreation}
        giftCardData={giftCardData}
        setGiftCardData={setGiftCardData}
      />
    </div>
  );
};

export default CreateGiftCard;
