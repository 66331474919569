import React from "react";
import { useDispatch } from "react-redux";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { calculateNewBalance } from "../../utils/calculateNewBalance";
import { clearCartDiscountCode } from "../../../../../../../actions/cart";
import {
  setModalComponent,
  setModalOpen,
} from "../../../../../../../actions/layout";

const GiftCardSuccess = ({
  discountCodeDetails,
  discountApplied,
  totalAfterDiscount,
  minimumFeeCharged,
  handleRemoveDiscountCode,
  removeDiscountLoading,
}) => {
  const dispatch = useDispatch();

  const handleMinimumAmountModal = () => {
    dispatch(setModalComponent("minimum-processing-amount"));
    dispatch(setModalOpen(true));
  };

  return (
    <>
      <div className="apply-promo-details">
        <div className="apply-promo-details__primary">
          <div className="apply-promo-details__header">
            <h3>
              Digital Gift Card
              <span>{` ${discountCodeDetails.discountCode} `}</span>
              added
            </h3>
          </div>
          <div className="apply-promo-details__contents">
            <p className="body-2">Remaining balance after order:</p>
            <p className="apply-promo-details__balance">{`${calculateNewBalance(
              discountCodeDetails.currentBalance,
              discountApplied,
              totalAfterDiscount,
              minimumFeeCharged
            )}`}</p>
          </div>
          {removeDiscountLoading ? (
            <p>Removing gift card...</p>
          ) : (
            <button
              className="remove-code"
              type="button"
              onClick={() => handleRemoveDiscountCode()}
            >
              Remove gift card
            </button>
          )}
        </div>
        {minimumFeeCharged && (
          <div className="apply-promo-min-fee">
            <p>Minimum processing amount added</p>
            <button onClick={() => handleMinimumAmountModal()} type="button">
              <InfoOutlinedIcon />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default GiftCardSuccess;
