import React from "react";
import Container from "../Layout/Container";
import "./SupportingHero.scss";

const SupportingHero = ({ title, backgroundImage = "none" }) => {
  return (
    <div
      className="supporting-hero"
      style={{ backgroundImage: backgroundImage }}
    >
      <Container>
        <h1 className="header-1">{title}</h1>
      </Container>
    </div>
  );
};

export default SupportingHero;
