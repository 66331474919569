import React from "react";
import "./Section.scss";

const Section = ({
  children,
  sectionTitle,
  sectionColor = "white",
  backgroundImage = "none",
}) => {
  return (
    <section
      className={`section section--${sectionColor}`}
      style={{ backgroundImage: backgroundImage }}
    >
      {sectionTitle ? (
        <h3 className={`section__title section__title--${sectionColor}`}>
          {sectionTitle}
        </h3>
      ) : null}
      {children}
    </section>
  );
};

export default Section;
