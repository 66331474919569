import { validateZipCodeRequest } from "../../../../utils/validateZipCodeRequest";

export const isZipValid = async (zipString) => {
  if (zipString.includes("-")) {
    return await validateZipCodeRequest(zipString.split("-")[0]).isValid;
    // return isZipInZone(zipString.split("-")[0]);
  } else {
    return await validateZipCodeRequest(zipString).isValid;
    // return isZipInZone(zipString);
  }
};
