import { SET_STRIPE_CUSTOMER_ID } from "../actions/types";

const initialState = {
  stripeCustomerId: JSON.parse(
    localStorage.getItem("stripeCustomerId") || null
  ),
};

export default function customerReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_STRIPE_CUSTOMER_ID:
      return {
        ...state,
        stripeClientSecret: payload,
      };
    default: {
      return state;
    }
  }
}
