import React, { useEffect } from "react";
import GiftCardManager from "../../../Components/Admin/GiftCardManager/GiftCardManager";
import PromoCodeManager from "../../../Components/Admin/PromoCodeManager/PromoCodeManager";
import BrentoTab from "../../../Components/UI/BrentoTab/BrentoTab";
import BrentoTabs from "../../../Components/UI/BrentoTabs";
import { useSelector, useDispatch } from "react-redux";
import { setDrawerComponent } from "../../../actions/layout";
import { setDiscountManagerView } from "../../../actions/discounts";

import "./DiscountAdmin.scss";

const DiscountAdmin = () => {
  const dispatch = useDispatch();
  const discountManagerView = useSelector(
    (state) => state.discounts.discountManagerView
  );

  const handleChangeView = (event, newValue) => {
    dispatch(setDiscountManagerView(newValue));
  };

  useEffect(() => {
    dispatch(setDrawerComponent(null));
  });

  return (
    <div className="discount-admin">
      <div className="discount-admin-header">
        <h2 className="discount-admin-header__title">Discount Manager</h2>
        <p className="discount-admin-header__label body-2">
          Manage gift cards and promo codes for customers to apply to their
          orders.
        </p>
      </div>
      <div className="discount-admin__controls">
        <div className="discount-admin__tabs">
          <BrentoTabs value={discountManagerView} onChange={handleChangeView}>
            <BrentoTab value="gift-card" label="Gift Cards" />
            <BrentoTab value="promo-codes" label="Promo codes" />
          </BrentoTabs>
        </div>
      </div>
      {discountManagerView === "gift-card" ? (
        <GiftCardManager />
      ) : (
        <PromoCodeManager />
      )}
    </div>
  );
};

export default DiscountAdmin;
