import React from "react";

const yelp = () => {
  return (
    <svg className="social-icon--yelp" x="0px" y="0px" viewBox="0 0 72 72">
      <circle cx="36" cy="36" r="36" />
      <path
        d="M36-0.2c-19.9,0-36,16.1-36,36c0,19.9,16.1,36,36,36c19.9,0,36-16.1,36-36
	C72,15.9,55.9-0.2,36-0.2z M19.3,42.8c-0.8,0-1.5-0.5-1.8-1.2c-0.2-0.5-0.4-1.2-0.5-2.2c-0.3-2.7,0.1-6.8,0.9-8.2
	c0.5-0.7,1.2-1.1,1.9-1.1c0.5,0,0.5,0,8.8,3.4l0,0c0.2,0.1,0.8,0.3,2.3,0.9c1,0.4,1.6,1.4,1.6,2.6c-0.1,1.2-0.8,2.1-1.8,2.3
	c-0.2,0.1-0.8,0.3-3.2,1c-6.9,2.2-7.6,2.4-8,2.4C19.4,42.8,19.3,42.8,19.3,42.8z M36.5,47.3c0,8,0,8-0.2,8.5
	c-0.3,0.7-0.9,1.2-1.7,1.4c-0.1,0-0.3,0-0.5,0c-2.5,0-8-2.1-9.3-3.6c-0.3-0.4-0.5-0.8-0.6-1.3c0-0.3,0-0.6,0.1-0.9
	c0.2-0.5,0.2-0.5,6-7.3l0.1-0.2c0.4-0.5,1.5-1.7,1.5-1.7c0.7-0.8,1.9-1.1,3-0.7c1.1,0.4,1.7,1.4,1.7,2.4
	C36.5,44,36.5,47.3,36.5,47.3z M34.8,31.6c-0.2,0.1-0.5,0.1-0.7,0.1c-1,0-2-0.7-2.9-2.2c-1-1.6-10.1-15.9-10.3-16.4
	c-0.2-0.8,0-1.6,0.6-2.2c1.6-1.7,9.9-4,12.2-3.4c0.9,0.2,1.5,0.8,1.7,1.6l0,0c0.1,0.7,1.1,15.4,1.3,18.7
	C36.7,29.9,36.1,31.2,34.8,31.6z M39.4,31.7c0.1-0.1,2-2.7,2-2.7c4.6-6.3,4.7-6.5,5.1-6.7c0.7-0.4,1.4-0.5,2.2-0.1
	c1.9,0.9,5.6,6.4,5.8,8.6l0,0l0,0.1c0.1,0.8-0.3,1.5-0.9,2c-0.4,0.3-0.5,0.3-9.1,2.4c0,0-2.4,0.7-3.6,0.6c-0.6-0.2-1.2-0.6-1.6-1.2
	C38.8,33.6,38.8,32.5,39.4,31.7z M54.9,45.1c-0.3,2.1-4.5,7.4-6.5,8.2c-0.3,0.1-0.6,0.2-0.9,0.2c-0.5,0-0.9-0.1-1.3-0.4
	c-0.4-0.3-0.6-0.6-5.1-7.9l-1.3-2.1c-0.6-0.9-0.5-2.1,0.3-3c0.7-0.9,1.8-1.2,2.8-0.9c0.1,0.1,2,0.7,3.2,1.1c7.6,2.5,7.6,2.5,8,2.8
	C54.7,43.5,55,44.3,54.9,45.1z"
      />
    </svg>
  );
};

export default yelp;
