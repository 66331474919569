import React, { useState, useEffect } from "react";
import "./Collapsible.scss";

const Collapsible = ({
  children,
  className,
  id,
  isExpanded = false,
  unMountOnCollapse,
  timeout,
}) => {
  const contentRef = React.createRef();
  const [contentSize, setContentSize] = useState(null);
  const [renderChildren, setRenderChildren] = useState(unMountOnCollapse);

  useEffect(() => {
    let timer;
    setContentSize(contentRef.current.scrollHeight);
    if (unMountOnCollapse && !isExpanded) {
      timer = setTimeout(() => {
        setRenderChildren(false);
      }, timeout);
    }
    if (isExpanded) {
      setRenderChildren(true);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [contentRef]);

  return (
    <div
      role="region"
      id={id}
      ref={contentRef}
      className={`collapsible collapsible--${
        isExpanded ? "expanded" : "collapsed"
      } ${className}`}
      style={{
        maxHeight: isExpanded ? `${contentSize}px` : "0px",
        transition: `max-height ${timeout}ms ease-in-out`,
      }}
    >
      {renderChildren && children}
    </div>
  );
};

export default Collapsible;
