import React from "react";
import "./MealDetailsMacros.scss";

const MealDetailsMacros = ({ fat, calories, protein, carbs }) => {
  return (
    <div className="meal-macros">
      <div className="meal-macros__macro">
        <p className="meal-macros__value">{calories}</p>
        <p className="meal-macros__label">Calories</p>
      </div>
      <div className="meal-macros__macro">
        <p className="meal-macros__value">{`${protein}g`}</p>
        <p className="meal-macros__label">Protein</p>
      </div>
      <div className="meal-macros__macro">
        <p className="meal-macros__value">{`${carbs}g`}</p>
        <p className="meal-macros__label">Carbs</p>
      </div>
      <div className="meal-macros__macro">
        <p className="meal-macros__value">{`${fat}g`}</p>
        <p className="meal-macros__label">Fat</p>
      </div>
    </div>
  );
};

export default MealDetailsMacros;
