import { combineReducers } from "redux";
import alert from "./alert";
import analytics from "./analytics";
import auth from "./auth";
import profile from "./profile";
import post from "./post";
import admin from "./admin";
import meal from "./meal";
import menu from "./menu";
import layout from "./layout";
import cart from "./cart";
import customer from "./customer";
import orders from "./orders";
import payment from "./payment";
import discounts from "./discounts";
import giftCards from "./giftCards";
import promoCodes from "./promoCodes";
import zipCode from "./zipCode";

export default combineReducers({
  admin,
  analytics,
  alert,
  auth,
  profile,
  post,
  meal,
  menu,
  layout,
  cart,
  customer,
  orders,
  payment,
  discounts,
  giftCards,
  promoCodes,
  zipCode,
});
