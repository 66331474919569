import axios from "axios";
import {
  GET_ALL_PROMO_CODES,
  GET_PROMO_CODE_DATA,
  SET_PROMO_CODE_DATA_LOADING,
} from "../actions/types";

// Get all gift cards
// ADMIN ONLY
export const getAllPromoCodes = (testMode) => async (dispatch) => {
  try {
    const requestPath = testMode ? "/api/test-promo-code" : "/api/promo-code";
    let res = await axios.get(requestPath);
    dispatch({
      type: GET_ALL_PROMO_CODES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

// Get gift card based on ID
// ADMIN ONLY
export const getPromoCodeAdmin =
  (testMode, promoCodeId) => async (dispatch) => {
    try {
      const requestPath = testMode
        ? `/api/test-promo-code/${promoCodeId}`
        : `/api/promo-code/${promoCodeId}`;
      let res = await axios.get(requestPath);
      dispatch({ type: GET_PROMO_CODE_DATA, payload: res.data });
    } catch (err) {
      console.log(err);
    }
  };
