import React from "react";
import "./OrderStats.scss";

const OrderStats = ({ orderData }) => {
  return orderData ? (
    <div className="order-stats">
      <OrderStat stat={orderData.overallOrderCount} label="Orders" />
      <OrderStat stat={orderData.overallMealCount} label="Meals" />
      <OrderStat
        stat={convertToDollars(orderData.overallTotal)}
        label="Revenue"
      />
    </div>
  ) : null;
};

const OrderStat = ({ stat, label }) => {
  return (
    <div className="order-stat">
      <p className="order-stat__stat">{stat}</p>
      <p className="order-stat__label">{label}</p>
    </div>
  );
};

const convertToDollars = (value) => {
  var valueInDollars = value / 100;
  valueInDollars = valueInDollars.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
  return valueInDollars;
};

export default OrderStats;
