import axios from "axios";
import {
  GET_ALL_ZIP_CODES,
  ADD_NEW_ZIP_CODE,
  ADD_NEW_ZIP_CODE_LOADING,
  ADD_NEW_ZIP_CODE_ERROR,
  DELETE_ZIP_CODE,
  SET_ADD_ZIP_CODE_INPUT_OPEN,
} from "./types";
import { setAlert } from "./alert";

// Get all zip codes
// ADMIN ONLY
export const getAllZipCodes = () => async (dispatch) => {
  try {
    let res = await axios.get("/api/zipcodes/");
    dispatch({
      type: GET_ALL_ZIP_CODES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err);
  }
};

// Add new Zip Code
// ADMIN ONLY
export const addNewZipCode = (zipCode) => async (dispatch) => {
  dispatch({ type: ADD_NEW_ZIP_CODE_LOADING, payload: true });
  try {
    let res = await axios({
      method: "post",
      url: "/api/zipcodes/add-zip-code/",
      data: { zipCode: zipCode },
    });
    dispatch({
      type: ADD_NEW_ZIP_CODE,
      payload: res.data,
    });
    dispatch(setAlert("Zip Code added successfully:", `${zipCode}`, "success"));
    dispatch({ type: SET_ADD_ZIP_CODE_INPUT_OPEN, payload: false });
  } catch (err) {
    console.log(err.response.data.msg);
    dispatch({
      type: ADD_NEW_ZIP_CODE_ERROR,
      payload: err.response.data.msg,
    });
  }
};

// Delete Meal
// ADMIN ONLY
export const deleteZipCode = (zipCode, zipCodeId) => async (dispatch) => {
  try {
    const res = await axios.delete(`/api/zipcodes/${zipCodeId}`);
    dispatch({
      type: DELETE_ZIP_CODE,
      payload: res.data,
    });
    dispatch(
      setAlert("Zip Code removed successfully:", `${zipCode}`, "success")
    );
  } catch (err) {
    // dispatch({
    //   type: DELETE_MEAL_ERROR,
    // });
    console.log(err);
  }
};
