import React from "react";
import "./Footer.scss";
import Container from "../Container";
import logo from "../../../img/logo-primary-white.svg";
import { NavLink, Link } from "react-router-dom";
import SocialIcons from "./SocialIcons/SocialIcons";

const Footer = () => {
  const d = new Date();

  return (
    <footer className="footer">
      <Container>
        <div className="footer__content">
          <div className="footer__logo-container">
            <img className="footer__logo" src={logo} alt="Brento Box logo" />
          </div>
          <div className="footer__links">
            <nav className="footer__site-links">
              <NavLink to="/menu" className="footer__nav-link">
                Menu
              </NavLink>
              <NavLink to="/about" className="footer__nav-link">
                About
              </NavLink>
              <NavLink to="/faqs" className="footer__nav-link">
                FAQs
              </NavLink>
              <NavLink to="/contact" className="footer__nav-link">
                Contact
              </NavLink>
            </nav>
            <SocialIcons />
          </div>
        </div>
        <p className="footer__legal">
          © Brento Box LLC – {d.getFullYear()} |{" "}
          <Link to="/privacy-policy">Privacy Policy</Link>
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
