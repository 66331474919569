import React from "react";
import ScrollToTop from "../../Layout/ScrollToTop";
import AnimatedCheckmark from "../../Checkout/ThankYou/AnimatedCheckmark/AnimatedCheckmark";
import moment from "moment";
import { Link } from "react-router-dom";
import "./GiftCardThankYou.scss";

const GiftCardThankYou = ({ giftCardOrderDetails }) => {
  console.log("Gift card details...");
  console.log(giftCardOrderDetails);
  const {
    deliveryDate,
    discountCode,
    specialMessage,
    recipientEmail,
    initialBalance,
    contactName,
    contactPhone,
    contactEmail,
  } = giftCardOrderDetails;

  const formatDiscountBalance = (number) => {
    const dollarAmount = number / 100;
    return dollarAmount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };

  return (
    <>
      <ScrollToTop />
      <div className="gift-card-thank-you">
        <div className="gift-card-thank-you__content">
          <div className="gift-card-thank-you__header">
            <AnimatedCheckmark />
            <h1 className="header-1">Thank you!</h1>
            <p className="body-2">
              You've successfully purchased a{" "}
              <b>Brento Box Digital Gift Card.</b> Review the details below, and
              if anything does not look correct, please{" "}
              <Link to="/contact">contact us.</Link>
            </p>
          </div>
          <div className="gift-card-thank-you__discount">
            <h2 className="eyebrow-1">Gift-Card Code:</h2>
            <p className="header-2">{discountCode}</p>
            <div className="discount-balance">
              <p className="label">Gift-Card Balance:</p>
              <p className="balance">{formatDiscountBalance(initialBalance)}</p>
            </div>
          </div>
          <div className="gift-card-thank-you__details">
            <h2 className="eyebrow-1">Delivery details:</h2>
            <div className="gift-card-thank-you__detail-group">
              <h3 className="body-1">To:</h3>
              <div className="detail-field">
                <p className="label">Email:</p>
                <p>{recipientEmail}</p>
              </div>
              <div className="detail-field">
                <p className="label">Email delivery date:</p>
                <p>{moment(deliveryDate).format("MMMM Do YYYY, h:mm a")}</p>
              </div>
              <div className="detail-field">
                <p className="label">Special message:</p>
                <p className="special-message">"{specialMessage}"</p>
              </div>
            </div>
            <div className="gift-card-thank-you__detail-group">
              <h3 className="body-1">From:</h3>
              <div className="detail-field">
                <p className="label">Your name:</p>
                <p>{contactName}</p>
              </div>
              <div className="detail-field">
                <p className="label">Your phone:</p>
                <p>{contactPhone}</p>
              </div>
              <div className="detail-field">
                <p className="label">Your email:</p>
                <p>{contactEmail}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GiftCardThankYou;
