import {
  GET_ALL_ZIP_CODES,
  SET_ADD_ZIP_CODE_INPUT_OPEN,
  ADD_NEW_ZIP_CODE,
  ADD_NEW_ZIP_CODE_LOADING,
  ADD_NEW_ZIP_CODE_ERROR,
  DELETE_ZIP_CODE,
} from "../actions/types";

const initialState = {
  zipCodes: [],
  zipCodesLoading: true,
  zipCodeInputOpen: false,
  addZipCodeLoading: false,
  addZipCodeError: false,
};

export default function profileReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_ZIP_CODES:
      return { ...state, zipCodes: [...payload], zipCodesLoading: false };
    case SET_ADD_ZIP_CODE_INPUT_OPEN:
      return { ...state, zipCodeInputOpen: payload };
    case ADD_NEW_ZIP_CODE_LOADING:
      return { ...state, addZipCodeLoading: payload };
    case ADD_NEW_ZIP_CODE:
      return {
        ...state,
        zipCodes: [...state.zipCodes, payload],
        addZipCodeLoading: false,
        addZipCodeError: false,
      };
    case ADD_NEW_ZIP_CODE_ERROR:
      return {
        ...state,
        addZipCodeLoading: false,
        addZipCodeError: payload,
      };
    case DELETE_ZIP_CODE:
      return {
        ...state,
        zipCodes: state.zipCodes.filter((zipCode) => zipCode._id !== payload),
      };
    default:
      return state;
  }
}
