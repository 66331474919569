import React from "react";
import { Button, InputBase } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import "./BrentoStepper.scss";

const BrentoStepper = ({
  stepperValue = 0,
  onInputFocus,
  onInputBlur,
  onInputChange,
  onIncrement,
  onDecrement,
  size = "lrg",
  isEditable = true,
}) => {
  // const useStyles = makeStyles((theme) => ({
  //   root: {
  //     padding: 0,
  //     width: "3rem",
  //     minWidth: "3rem",
  //     backgroundColor: "#2c3838",
  //     "&:active": {
  //       backgroundColor: "#556262",
  //     },
  //     "&:focus": {
  //       backgroundColor: "#556262",
  //     },
  //   },
  //   decrement: {
  //     borderRadius: "1 0 0 1",
  //   },
  // }));
  // const classes = useStyles();

  const handleInputChange = (e) => {
    if (onInputChange) {
      onInputChange(e);
    }
  };

  const handleInputFocus = (e) => {
    if (onInputFocus) {
      onInputFocus(e);
    }
  };

  const handleInputBlur = (e) => {
    if (onInputBlur) {
      onInputBlur(e);
    }
  };

  const handleIncrement = () => {
    if (onIncrement) {
      onIncrement();
    }
  };

  const handleDecrement = () => {
    if (onIncrement) {
      onDecrement();
    }
  };

  return (
    <div className={`brento-stepper brento-stepper--${size}`}>
      <Button
        className="brento-stepper__button brento-stepper__decrement"
        variant="outlined"
        disabled={stepperValue === 0}
        onClick={() => handleDecrement()}
      >
        <RemoveIcon />
      </Button>
      <InputBase
        value={stepperValue}
        type="number"
        variant="outlined"
        className="brento-stepper__input"
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        onChange={handleInputChange}
        disabled={!isEditable}
      ></InputBase>
      <Button
        className="brento-stepper__button brento-stepper__increment"
        variant="outlined"
        onClick={() => handleIncrement()}
      >
        <AddIcon />
      </Button>
    </div>
  );
};

export default BrentoStepper;
