import React from "react";
import "./GiftCardDetailsTable.scss";
import moment from "moment";

const formatAsDollar = (numberCents) => {
  const valueInDollars = numberCents / 100;
  return valueInDollars.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
};

const GiftCardDetailsTable = ({ giftCardData }) => {
  const {
    discountType,
    initialBalance,
    currentBalance,
    orders,
    contactName,
    contactEmail,
    contactPhone,
    recipientEmail,
    recipientDeliveryDate,
    emailSent,
    createdAt,
  } = giftCardData;

  return (
    <>
      <div className="gift-card-details-table">
        <h2 className="gift-card-details-table__section-header">
          Gift card details:
        </h2>
        <div className="gift-card-details-table__row">
          <p className="gift-card-details-table__label">Gift card type:</p>
          <p className="gift-card-details-table__value">
            {discountType === "gift-card"
              ? "Purchased by customer"
              : "Created by admin"}
          </p>
        </div>
        {createdAt && (
          <div className="gift-card-details-table__row">
            <p className="gift-card-details-table__label">Created at:</p>
            <p className="gift-card-details-table__value">
              {moment(createdAt).format("MMMM D, YYYY")}
              <span className="from-now">{moment(createdAt).fromNow()}</span>
            </p>
          </div>
        )}
        <div className="gift-card-details-table__row">
          <p className="gift-card-details-table__label">Initial Balance:</p>
          <p className="gift-card-details-table__value">
            {formatAsDollar(initialBalance)}
          </p>
        </div>
        <div className="gift-card-details-table__row">
          <p className="gift-card-details-table__label">Current Balance:</p>
          <p className="gift-card-details-table__value">
            {formatAsDollar(currentBalance)}
          </p>
        </div>
        {discountType === "gift-card" && (
          <>
            <h2 className="gift-card-details-table__section-header">
              Contact Info (From):
            </h2>
            <div className="gift-card-details-table__row">
              <p className="gift-card-details-table__label">Contact Name:</p>
              <p className="gift-card-details-table__value">{contactName}</p>
            </div>
            <div className="gift-card-details-table__row">
              <p className="gift-card-details-table__label">Contact Email:</p>
              <p className="gift-card-details-table__value">{contactEmail}</p>
            </div>
            <div className="gift-card-details-table__row">
              <p className="gift-card-details-table__label">Contact Phone:</p>
              <p className="gift-card-details-table__value">{contactPhone}</p>
            </div>
          </>
        )}
        {discountType === "gift-card" && (
          <>
            <h2 className="gift-card-details-table__section-header">
              Recipient Info (To):
            </h2>
            <div className="gift-card-details-table__row">
              <p className="gift-card-details-table__label">Recipient Email:</p>
              <p className="gift-card-details-table__value">{recipientEmail}</p>
            </div>
            <div className="gift-card-details-table__row">
              <p className="gift-card-details-table__label">
                Email Delivery Date:
              </p>
              <p className="gift-card-details-table__value">
                {moment(recipientDeliveryDate).format("MMMM D, YYYY")}
              </p>
            </div>
            <div className="gift-card-details-table__row">
              <p className="gift-card-details-table__label">Email Sent:</p>
              <p className="gift-card-details-table__value">
                {emailSent ? "Yes" : "No"}
              </p>
            </div>
          </>
        )}
      </div>
      <OrderDetailsTable orders={orders} />
    </>
  );
};

const OrderDetailsTable = ({ orders }) => {
  return (
    <div className="order-details-table">
      <h2 className="gift-card-details-table__section-header">{`Orders (${
        orders && orders.length > 0 ? orders.length : 0
      }):`}</h2>
      {orders && orders.length > 0 ? (
        <>
          <div className="order-details-table__header">
            <p className="order-details-table__header-label">Order Id:</p>
            <p className="order-details-table__header-label">
              Discount Applied:
            </p>
            <p className="order-details-table__header-label hide-sm">
              Total After Discount:
            </p>
            <p className="order-details-table__header-label hide-sm">
              Order date:
            </p>
          </div>
          <ul className="order-details-table__table">
            {orders.map((order) => {
              return <OrderDetailsRow order={order} key={order.orderId} />;
            })}
          </ul>
        </>
      ) : (
        <p className="empty-state">No orders</p>
      )}
    </div>
  );
};

const OrderDetailsRow = ({ order }) => {
  return (
    <li className="order-details-table__row">
      <p className="order-details-table__value">{order.orderId}</p>
      <p className="order-details-table__value">
        {formatAsDollar(order.discountCode.discountApplied)}
      </p>
      <p className="order-details-table__value hide-sm">
        {formatAsDollar(order.orderTotal)}
      </p>
      <p className="order-details-table__value hide-sm">
        {moment(order.createdAt).format("MMMM D, YYYY")}
      </p>
    </li>
  );
};

export default GiftCardDetailsTable;
