import React, { useEffect } from "react";
import "./Menu.scss";
import MenuHero from "../../Components/Menu/MenuHero/MenuHero";
import { getFullMenu } from "../../actions/menu";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "../../Components/Menu/MenuItem/MenuItem";
import MenuEmptyState from "../../Components/Menu/MenuEmptyState/MenuEmptyState";
import Container from "../../Components/Layout/Container";
import { Link } from "react-router-dom";
import Spinner from "../../Components/UI/Spinner/Spinner";
import Grid from "@mui/material/Grid";
import ScrollToTop from "../../Components/Layout/ScrollToTop";
import MenuItemGroup from "../../Components/Menu/MenuItemGroup/MenuItemGroup";
import { Helmet } from "react-helmet";
import MenuViewSelect from "../../Components/Menu/MenuViewSelect/MenuViewSelect";
import MenuCategories from "../../Components/Menu/MenuCategories/MenuCategories";

const Menu = () => {
  const dispatch = useDispatch();
  const { name, meals, orderByDate } = useSelector(
    (state) => state.menu.activeMenu
  );
  const menuLoading = useSelector((state) => state.menu.menuLoading);
  const testMode = useSelector((state) => state.admin.testMode);
  const prefMenuView = useSelector((state) => state.menu.prefMenuView);

  /* SHORT TERM OUT OF OFFICE FIX */
  /* Change the value below to manually adjust out-of-office */
  const outOfOffice = false;

  useEffect(() => {
    dispatch(getFullMenu(testMode));
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://getbrentobox.com/menu" />
        <title>Menu | Brento Box - Fresh Meal Prep Made Easy</title>
        <meta
          name="description"
          content="Browse our menu of delicious, healthy meals made from fresh ingredients. We update our menu each week."
        />
      </Helmet>
      <div className="menu-page">
        <ScrollToTop />
        <MenuHero
          menuTitle={name}
          orderByDate={orderByDate}
          menuLoading={menuLoading}
        />
        <Container>
          <div className="menu-page__body">
            {outOfOffice ? (
              <MenuEmptyState
                header="Happy holidays!"
                bodyText={
                  <>
                    <p className="menu-empty-state__body body-2">
                      The kitchen is closed this week for the holiday weekend.
                      We'll be back next week with a new menu on{" "}
                      <b>Wednesday, December 28th.</b>
                    </p>
                    <br />
                    <p className="menu-empty-state__body body-2">
                      <b>Digital gift cards are still available though!</b>
                      <br />
                      <a href="https://getbrentobox.com/gift-cards">
                        Order a gift card
                      </a>
                    </p>
                  </>
                }
              />
            ) : (
              <>
                <MenuViewSelect />
                {prefMenuView === "categories" ? (
                  <MenuCategories />
                ) : (
                  <>
                    <MenuItemGroup
                      categoryLabel="Signature Meals"
                      menuItems={
                        meals &&
                        meals.filter((meal) => meal.category === "meal")
                      }
                      id={"meals"}
                      menuLoading={menuLoading}
                    />
                    <MenuItemGroup
                      categoryLabel="A La Carte"
                      menuItems={
                        meals &&
                        meals.filter((meal) => meal.category === "bulk")
                      }
                      id={"bulk"}
                      menuLoading={menuLoading}
                    />
                    <MenuItemGroup
                      categoryLabel="Treats"
                      menuItems={
                        meals &&
                        meals.filter((meal) => meal.category === "extra")
                      }
                      id={"extras"}
                      menuLoading={menuLoading}
                    />
                    <MenuItemGroup
                      categoryLabel="Juices & Coffee"
                      menuItems={
                        meals &&
                        meals.filter((meal) => meal.category === "juice")
                      }
                      id={"juices-coffee"}
                      menuLoading={menuLoading}
                    />
                    <MenuItemGroup
                      categoryLabel="Merch & Gift Cards"
                      menuItems={
                        meals &&
                        meals.filter((meal) => meal.category === "merch")
                      }
                      menuLoading={menuLoading}
                      id={"merch"}
                      includeGiftCard={true}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </Container>
      </div>
    </>
  );
};

export default Menu;
