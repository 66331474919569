import React from "react";
import CategoryTemplate from "../CategoryTemplate";
import { Helmet } from "react-helmet";

const Bulk = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link
          rel="canonical"
          href="https://getbrentobox.com/menu/oats-and-bites"
        />
        <title>Menu - Treats | Brento Box - Fresh Meal Prep Made Easy</title>
        <meta
          name="description"
          content="Our tasty snacks and treats are packed with protein and other great macronutrients to help give you a quick and easy boost to your day. Add some to your weekly Brento Box order!"
        />
      </Helmet>
      <CategoryTemplate
        categoryTitle="Treats"
        categoryValue={"extra"}
        categoryTrackingId={"extras"}
      />
    </>
  );
};

export default Bulk;
