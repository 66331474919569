export const getRightNow = () => {
  let rightNow = new Date();
  // let rightNowAZ = rightNow.toLocaleDateString("en-US", {
  //   timeZone: "America/Phoenix",
  // });
  // console.log(rightNowAZ);
  // return rightNowAZ;

  return rightNow.toLocaleDateString("en-US", {
    timeZone: "America/Phoenix",
  });
};
