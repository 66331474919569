import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { provideStripeKey } from "./utils/provideStripeKey";
import LandingPage from "./Pages/LandingPage";
import CoreLogic from "./Components/CoreLogic/CoreLogic";
import Routes from "./Components/Routing/Routes";
import Layout from "./Components/Layout/Layout";
import Theme from "./Theme";
import ReactGA from "react-ga4";
import "./Reset.css";
import "./App.scss";
import "./variables/_type.scss";
import "./variables/_color.scss";

// Redux
import { Provider } from "react-redux";
import store from "./store";
import { loadUser } from "./actions/auth";
import setAuthToken from "./utils/setAuthToken";

// Provides Stripe key based on environment (prod vs. dev)
const promise = loadStripe(provideStripeKey(process.env.NODE_ENV));

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  ReactGA.initialize("G-S0L4S66L8S");

  return (
    <Provider store={store}>
      <Theme>
        <Elements stripe={promise}>
          <Router>
            <CoreLogic>
              <div className="App">
                <Layout>
                  <Switch>
                    <Route exact path="/" component={LandingPage} />
                    <Route component={Routes} />
                  </Switch>
                </Layout>
              </div>
            </CoreLogic>
          </Router>
        </Elements>
      </Theme>
    </Provider>
  );
};

export default App;
