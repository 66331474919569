import {
  GET_DISPLAY_WIDTH,
  SET_DRAWER_OPEN,
  SET_DRAWER_COMPONENT,
  SET_MODAL_OPEN,
  SET_MODAL_COMPONENT,
} from "../actions/types";

const initialState = {
  displayWidth: 0,
  drawerOpen: false,
  drawerComponent: null,
  modalOpen: false,
  modalComponent: { id: null, data: null },
};

export default function layoutReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DISPLAY_WIDTH:
      return { ...state, displayWidth: action.payload };
    case SET_DRAWER_OPEN:
      return { ...state, drawerOpen: action.payload };
    case SET_DRAWER_COMPONENT:
      return { ...state, drawerComponent: action.payload };
    case SET_MODAL_OPEN:
      return { ...state, modalOpen: action.payload };
    case SET_MODAL_COMPONENT:
      return { ...state, modalComponent: action.payload };
    default:
      return state;
  }
}
