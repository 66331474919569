import axios from "axios";

export const validateCodeNameRequest = async (testMode, value) => {
  try {
    const fullPath = testMode
      ? "/api/test-promo-code/validate-name"
      : "/api/promo-code/validate-name";
    let res = await axios.post(
      fullPath,
      { discountCode: value },
      { "Content-Type": "application/json" }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};
