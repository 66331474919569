import React from "react";
import "./Alert.scss";

const Alert = ({ type, header, content }) => {
  return (
    <div className={`alert-message alert-message--${type}`}>
      <p className="alert-message__header">{header}</p>
      <div className="alert-message__content">{content}</div>
    </div>
  );
};

export default Alert;
