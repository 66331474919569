import React, { useState } from "react";
import Container from "../../Components/Layout/Container";
import "./FAQs.scss";
import FAQContent from "./FAQContent";
import FAQ from "../../Components/FAQs/FAQ/FAQ";
import BrentoTabs from "../../Components/UI/BrentoTabs";
import BrentoTab from "../../Components/UI/BrentoTab/BrentoTab";
import ScrollToTop from "../../Components/Layout/ScrollToTop";
import { Helmet } from "react-helmet";

const FAQs = () => {
  const [activeTab, setActiveTab] = useState("general");

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://getbrentobox.com/faqs" />
        <title>
          Frequently Asked Questions (FAQs) | Brento Box - Fresh Meal Prep Made
          Easy
        </title>
        <meta
          name="description"
          content="Here are some answers to questions we get a lot regarding nutrition, ordering, and delivery of our meals."
        />
      </Helmet>
      <div className="faqs-page">
        <ScrollToTop />
        <Container>
          <div className="faqs-content">
            <div className="faqs-content__title">
              <h1 className="header-2 font-primary">
                Frequently Asked Questions
              </h1>
            </div>
            <div className="faqs-content__main">
              <BrentoTabs
                value={activeTab}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                aria-label="disabled tabs example"
              >
                <BrentoTab
                  label="General"
                  value={"general"}
                  aria-controls="panel-1"
                  id="faq-tab-1"
                />
                <BrentoTab
                  label="Delivery"
                  value={"delivery"}
                  aria-controls="panel-2"
                  id="faq-tab-2"
                />
                <BrentoTab
                  label="Payment"
                  value={"payment"}
                  aria-controls="panel-3"
                  id="faq-tab-3"
                />
              </BrentoTabs>
              <div
                hidden={activeTab !== "general"}
                role="tabpanel"
                id="panel-1"
                className="faqs-content__faq-panel"
                aria-labelledby="faq-tab-1"
              >
                {FAQContent.general.map((faq) => {
                  return (
                    <FAQ title={faq.question}>
                      <p className="body-2">{faq.answer}</p>
                    </FAQ>
                  );
                })}
              </div>
              <div
                hidden={activeTab !== "delivery"}
                role="tabpanel"
                id="panel-2"
                className="faqs-content__faq-panel"
                aria-labelledby="faq-tab-2"
              >
                {FAQContent.delivery.map((faq) => {
                  return (
                    <FAQ title={faq.question}>
                      <p className="body-2">{faq.answer}</p>
                      {faq.img ? (
                        <img src={faq.img.src} alt={faq.img.alt} />
                      ) : null}
                    </FAQ>
                  );
                })}
              </div>
              <div
                hidden={activeTab !== "payment"}
                role="tabpanel"
                id="panel-3"
                className="faqs-content__faq-panel"
                aria-labelledby="faq-tab-3"
              >
                {FAQContent.payment.map((faq) => {
                  return (
                    <FAQ title={faq.question}>
                      <p className="body-2">{faq.answer}</p>
                    </FAQ>
                  );
                })}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default FAQs;
