import React from "react";
import "./GiftCardOption.scss";
import { Link } from "react-router-dom";
import GiftCardIllo from "../../../img/Illustrations/Illustrations_GiftCard.svg";
import GiftCardImg from "../../../img/gift-card-1.png";

const GiftCardOption = () => {
  return (
    <li className="gift-card-item">
      <Link to="/gift-cards">
        <div className="gift-card-item__container">
          <div className="gift-card-item__content">
            <img src={GiftCardImg} alt="Illustration of a gift box" />
          </div>
        </div>
        <div className="gift-card-item__text-wrapper">
          <p className="title">Digital Gift Cards</p>
          <p className="eyebrow-1">Order now</p>
        </div>
      </Link>
    </li>
  );
};

export default GiftCardOption;
