import {
  GET_ANALYTICS_DATA,
  GET_ANALYTICS_DATA_LOADING,
  GET_ANALYTICS_DATA_ERROR,
  SET_ANALYTICS_FILTER_BY,
  SET_ANALYTICS_BREAKDOWN_BY,
} from "../actions/types";

const initialState = {
  analyticsData: null,
  analyticsDataLoading: true,
  analyticsDataError: null,
  analyticsFilterBy: "all-time",
  analyticsBreakdownBy: "year",
};

export default function analyticsReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_ANALYTICS_DATA:
      return {
        ...state,
        analyticsData: payload,
      };
    case GET_ANALYTICS_DATA_LOADING:
      return {
        ...state,
        analyticsDataLoading: payload,
      };
    case GET_ANALYTICS_DATA_ERROR:
      return {
        ...state,
        analyticsDataError: payload,
      };
    case SET_ANALYTICS_FILTER_BY:
      return {
        ...state,
        analyticsFilterBy: payload,
      };
    case SET_ANALYTICS_BREAKDOWN_BY:
      return {
        ...state,
        analyticsBreakdownBy: payload,
      };
    default:
      return state;
  }
}
