import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { getMeal } from "../../actions/meal";
import { useDispatch, useSelector } from "react-redux";
import Container from "../../Components/Layout/Container";
import MealDetailsHero from "../../Components/MealDetails/MealDetailsHero";
import "./MealDetails.scss";
import Spinner from "../../Components/UI/Spinner/Spinner";
import ScrollToTop from "../../Components/Layout/ScrollToTop";
import { trackViewItem } from "../../tracking/trackViewItem";

const MealDetails = () => {
  const dispatch = useDispatch();
  const meal = useSelector((state) => state.meal.meal);
  const mealLoading = useSelector((state) => state.meal.mealLoading);
  const availableMeals = useSelector((state) => state.menu.activeMenu.meals);
  const orderingAvailable = useSelector(
    (state) => state.menu.orderingAvailable
  );
  let { id } = useParams();

  useEffect(() => {
    dispatch(getMeal(id));
    if (meal) {
      trackViewItem(meal);
    }
  }, [dispatch, id]);
  return (
    <>
      <div className="meal-details">
        <ScrollToTop />
        <Container>
          {!mealLoading && availableMeals ? (
            <>
              <Helmet>
                <meta charSet="utf-8" />
                <link
                  rel="canonical"
                  href={`https://getbrentobox.com/menu/meals/${id}`}
                />
                <title>{`${meal.mealName} | Brento Box - Fresh Meal Prep Made Easy`}</title>
                <meta name="description" content={meal.description} />
              </Helmet>
              <main>
                <MealDetailsHero
                  meal={meal}
                  availableMeals={availableMeals}
                  orderingAvailable={orderingAvailable}
                />
              </main>
            </>
          ) : (
            <Spinner size="large" />
          )}
        </Container>
      </div>
    </>
  );
};

export default MealDetails;
