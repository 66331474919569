import {
  SET_PAYMENT_INTENT_ID,
  SET_GIFTCARD_PAYMENT_INTENT_ID,
  SET_PAYMENT_INTENT_EXPIRATION,
  CLEAR_PAYMENT_INTENT,
  CLEAR_GIFTCARD_PAYMENT_INTENT,
} from "../actions/types";

const initialState = localStorage.getItem("payment")
  ? JSON.parse(localStorage.getItem("payment"))
  : {
      paymentIntentId: null,
      paymentIntentExpiry: null,
      giftCardPaymentIntentId: null,
    };

export default function paymentReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_PAYMENT_INTENT_ID:
      return {
        ...state,
        paymentIntentId: payload,
      };
    case SET_GIFTCARD_PAYMENT_INTENT_ID:
      return {
        ...state,
        giftCardPaymentIntentId: payload,
      };
    case SET_PAYMENT_INTENT_EXPIRATION:
      return {
        ...state,
        paymentIntentExpiry: payload,
      };
    case CLEAR_PAYMENT_INTENT:
      return {
        ...state,
        paymentIntentId: null,
        paymentIntentExpiry: null,
      };
    case CLEAR_GIFTCARD_PAYMENT_INTENT:
      return {
        ...state,
        giftCardPaymentIntentId: null,
      };
    default: {
      return state;
    }
  }
}
