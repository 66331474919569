import React from "react";
import "./ErrorState.scss";
import ErrorStateIllo from "../../../img/Illustrations/EmptyState/Error.svg";
import BrentoButton from "../BrentoButton";
import { useHistory, Link } from "react-router-dom";

const ErrorState = () => {
  const history = useHistory();
  return (
    <div className="error-state">
      <img
        className="error-state__img"
        src={ErrorStateIllo}
        alt="A symbol indicating an error occured"
      />
      <h1 className="error-state__header">
        <span>Uh oh!</span> Something went wrong
      </h1>
      <p className="error-state__body">
        Please try again. If this problem persists, please contact us and we can
        try to assist you.
      </p>
      <div className="error-state__controls">
        <BrentoButton
          variant="contained"
          size="large"
          fullWidth="true"
          onClick={() => history.goBack()}
        >
          Go back
        </BrentoButton>
        <Link to="/contact">
          <BrentoButton variant="outlined" size="large" fullWidth="true">
            Contact Us
          </BrentoButton>
        </Link>
      </div>
    </div>
  );
};

export default ErrorState;
