import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BrentoBackButton from "../../../../Components/UI/BrentoBackButton";
import EditPromoCodeForm from "../../../../Components/Admin/EditPromoCodeForm/EditPromoCodeForm";
import { getPromoCodeAdmin } from "../../../../actions/promoCodes";
import { editPromoCodeRequest } from "./utils/editPromoCodeRequest";
import { setAlert } from "../../../../actions/alert";
import { SET_PROMO_CODE_DATA_LOADING } from "../../../../actions/types";

const EditPromoCode = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const testMode = useSelector((state) => state.admin.testMode);
  const promoCodeDataLoading = useSelector(
    (state) => state.promoCodes.promoCodeDataLoading
  );
  const promoCodeData = useSelector((state) => state.promoCodes.promoCodeData);
  const [promoCodeEditSuccessful, setPromoCodeEditSuccessful] = useState(false);
  const {
    match: { params },
  } = props;

  const finalizePromoCodeCreation = () => {
    setPromoCodeEditSuccessful(true);
  };

  useEffect(() => {
    dispatch({ type: SET_PROMO_CODE_DATA_LOADING, payload: true });
    dispatch(getPromoCodeAdmin(testMode, params.id));
  }, []);

  useEffect(() => {
    if (promoCodeEditSuccessful) {
      dispatch(
        setAlert(
          "Promo code created successfully",
          `Promo code ${promoCodeData.discountCode} has been created successfully`,
          "success"
        )
      );
      history.push("/admin/discount");
    }
  });

  return (
    <>
      {promoCodeDataLoading ? (
        <p>Loading...</p>
      ) : (
        <div className="admin-create-promo-code">
          <div class="admin-create-promo-code__header">
            <BrentoBackButton />
            <p className="eyebrow-1">Edit Promo Code:</p>
            <h1 className="header-3">{promoCodeData.discountCode}</h1>
            <EditPromoCodeForm
              promoCodeId={params.id}
              editPromoCodeRequest={editPromoCodeRequest}
              promoCodeEditSuccessful={promoCodeEditSuccessful}
              setPromoCodeEditSuccessful={setPromoCodeEditSuccessful}
              finalizePromoCodeCreation={finalizePromoCodeCreation}
              promoCodeData={promoCodeData}
            />
          </div>
        </div>
      )}

      {/* <CreateGiftCardForm
        createAdminDiscountRequest={createAdminDiscountRequest}
        discountCreationSuccessful={discountCreationSuccessful}
        finalizeDiscountCreation={finalizeDiscountCreation}
        giftCardData={giftCardData}
        setGiftCardData={setGiftCardData}
      /> */}
    </>
  );
};

export default EditPromoCode;
