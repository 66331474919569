import axios from "axios";

export const handleApplyDiscountRequest = async (
  discountCode,
  cartItems,
  emailAddress,
  paymentIntentId,
  testMode
) => {
  let requestBody = {
    discountCode: discountCode.toUpperCase(),
    cartItems: cartItems,
    emailAddress: emailAddress,
  };
  try {
    let res = await axios({
      method: "post",
      data: requestBody,
      url: testMode
        ? `/api/test-payment/apply-discount/${paymentIntentId}`
        : `/api/payment/apply-discount/${paymentIntentId}`,
      // headers: { "Content-Type": "application/json" },
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};
