import React, { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import withStyles from '@mui/styles/withStyles';
import "./MenuItemDietaryTag.scss";

const BrentoTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: "#FFF",
    boxShadow: theme.shadows[1],
    fontSize: 16,
  },
  arrow: {
    color: theme.palette.primary.main,
  },
}))(Tooltip);

const MenuItemDietaryTag = ({ acronym, fullName }) => {
  return (
    <BrentoTooltip title={fullName} arrow placement="top">
      <p className="menu-item__dietary-tag">{acronym}</p>
    </BrentoTooltip>
  );
};

export default MenuItemDietaryTag;
