import axios from "axios";
import { determineMenuStatus } from "../utils/determineMenuStatus";
import moment from "moment";
import {
  GET_FULL_MENU,
  GET_MENU_DATA,
  MENU_LOADING,
  MENU_DATA_LOADING,
  GET_MENU_ERROR,
  GET_MENU_DATA_ERROR,
  UPDATE_MENU,
  SET_ORDERING_AVAILABLE,
  SET_PREF_MENU_VIEW,
} from "./types";

import { setAlert } from "./alert";

// Get the full menu from the database
export const getFullMenu = (testMode) => async (dispatch) => {
  try {
    const requestPath = testMode ? "/api/test-menu/" : "/api/menu";
    const res = await axios.get(requestPath);

    dispatch({
      type: GET_FULL_MENU,
      payload: res.data,
    });
    dispatch({ type: MENU_LOADING, payload: false });
  } catch (err) {
    dispatch({
      type: GET_MENU_ERROR,
    });
  }
};

// Get the unpopulated data object of the menu from the db
export const getMenuData = (testMode) => async (dispatch) => {
  dispatch({ type: MENU_DATA_LOADING, payload: true });
  try {
    const requestPath = testMode ? "/api/test-menu/data" : "/api/menu/data";
    const res = await axios.get(requestPath);

    dispatch({
      type: GET_MENU_DATA,
      payload: res.data,
    });
    dispatch({ type: MENU_DATA_LOADING, payload: false });
  } catch (err) {
    dispatch({
      type: GET_MENU_DATA_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
    dispatch({ type: MENU_DATA_LOADING, payload: false });
  }
};

// Update the menu with a new menu object
export const updateMenu = (updatedMenuObject, testMode) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  let requestObject = updatedMenuObject;
  requestObject.newMenuData.orderByDate = moment(
    updatedMenuObject.newMenuData.orderByDate
  ).toISOString();

  requestObject.newMenuData.orderStartDate = moment(
    updatedMenuObject.newMenuData.orderStartDate
  ).toISOString();

  try {
    const requestPath = testMode ? "/api/test-menu" : "/api/menu";
    const res = await axios.post(requestPath, requestObject, config);

    dispatch({
      type: UPDATE_MENU,
      payload: res.data,
    });
    dispatch(
      setAlert(
        "Menu updated successfully at:",
        `${moment().format("dddd, MMMM Do YYYY, h:mm:ss a")}`,
        "success"
      )
    );
    dispatch({ type: MENU_LOADING, payload: false });
  } catch (err) {
    dispatch({
      type: GET_MENU_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

export const setPrefMenuView = (menuView) => (dispatch) => {
  localStorage.setItem("prefMenuView", menuView);
  dispatch({ type: SET_PREF_MENU_VIEW, payload: menuView });
};

// Set the menu status to determine which content and styles to display
export const setOrderingStatus =
  (menuStartDate, menuOrderByDate) => async (dispatch) => {
    let isOrderingAvailable = determineMenuStatus(
      menuStartDate,
      menuOrderByDate
    );
    try {
      dispatch({
        type: SET_ORDERING_AVAILABLE,
        payload: isOrderingAvailable,
      });
    } catch (err) {
      console.log(err);
    }
  };
