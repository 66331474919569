export const calculateNewBalance = (
  currentBalance,
  discountApplied,
  totalAfterDiscount,
  minimumFeeCharged
) => {
  let discountAppliedCents = discountApplied * 100;
  let newBalance = currentBalance - discountAppliedCents;
  if (minimumFeeCharged) {
    newBalance = newBalance + 50;
  }
  const newBalanceUSD = newBalance / 100;
  return newBalanceUSD.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
  // const orderTotalInCents = Math.round(orderTotal * 100);
  // let newBalance;
  // if (0 >= newBalance) {
  //   newBalance = 0;
  // } else if (currentBalance > orderTotal) {
  //   newBalance = currentBalance - orderTotalInCents + 5;
  //   console.log(newBalance);
  // } else {
  //   newBalance = currentBalance - orderTotalInCents;
  // }
  // const newBalanceUSD = newBalance / 100;
  // return newBalanceUSD.toLocaleString("en-US", {
  //   style: "currency",
  //   currency: "USD",
  // });
};
