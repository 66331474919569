import React, { useState } from "react";
import { TextField } from "@mui/material";
import BrentoLoadingButton from "../../../../UI/BrentoLoadingButton";
import { verifyEmailRequest } from "./utils/verifyEmailRequest";
import { useSelector } from "react-redux";
import "./PromoCodeEmail.scss";

const PromoCodeEmail = ({ data }) => {
  const { greetingMessage, discountCode, discountType, _id, percentOff } = data;
  const testMode = useSelector((state) => state.admin.testMode);
  const [promoVerifyValue, setPromoVerifyValue] = useState("");
  const [promoVerifyLoading, setPromoVerifyLoading] = useState(false);
  const [promoVerifyError, setPromoVerifyError] = useState(false);

  const handleVerifyEmail = async () => {
    try {
      setPromoVerifyLoading(true);
      const verifyStatus = await verifyEmailRequest(
        promoVerifyValue,
        discountCode,
        _id,
        testMode
      );
      if (verifyStatus) {
        setPromoVerifyLoading(false);
      }
    } catch (err) {
      setPromoVerifyError(true);
    }
  };

  const handleSetEmailValue = (e) => {
    setPromoVerifyValue(e.target.value);
  };

  return (
    <div className="promo-code-email-modal">
      <p className="header-5">{discountCode}</p>
      <p className="promo-code-email-modal__greeting body-2">
        {greetingMessage}
      </p>
      {discountType === "percent-off" && (
        <p className="promo-code-email-modal__instructions body-2">
          Get <span>{percentOff}%</span> off your order by entering and
          verifying your email below.
        </p>
      )}
      <TextField
        sx={{ marginBottom: "1rem" }}
        fullWidth
        variant="outlined"
        label="Enter your email"
        onChange={handleSetEmailValue}
      />
      <BrentoLoadingButton
        pending={promoVerifyLoading}
        onClick={() => handleVerifyEmail()}
        fullWidth
      >
        Submit
      </BrentoLoadingButton>
    </div>
  );
};

export default PromoCodeEmail;
