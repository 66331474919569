import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Container from "../../../Components/Layout/Container";
import AnimatedCheckmark from "../../../Components/Checkout/ThankYou/AnimatedCheckmark/AnimatedCheckmark";
import BrentoButton from "../../../Components/UI/BrentoButton";
import IncludeInstructions from "../../../Components/Checkout/ExpressCheckout/ExpressThankYou/IncludeInstructions/IncludeInstructions";
import { useHistory } from "react-router-dom";

import "./ExpressThankYou.scss";

const ExpressThankYou = () => {
  const history = useHistory();
  const orderConfirmationData = useSelector(
    (state) => state.orders.orderConfirmationData
  );
  const { orderId, orderTotal, _id, specialInstructions } =
    orderConfirmationData;

  useEffect(() => {
    console.log(orderConfirmationData);
    if (_id === null) {
      history.push("/");
    }
  });

  return (
    <>
      <Container>
        <div className="express-thank-you">
          <div className="express-thank-you__header">
            <h1 className="express-thank-you__title">
              <span>Express</span> Checkout
            </h1>
          </div>
          <div className="express-thank-you__page-content">
            <div className="express-thank-you__main">
              <div className="express-thank-you__message">
                <div className="express-thank-you__checkmark">
                  <AnimatedCheckmark />
                </div>
                <div className="express-thank-you__message-content">
                  <p className="express-thank-you__message-header">
                    Thank you!
                  </p>
                  <p className="express-thank-you__message-body">
                    Your order has been placed. You should receive a
                    confirmation email shortly. Hang on to your order number for
                    reference.
                  </p>
                </div>
              </div>
              <div className="express-thank-you__order-details">
                <p className="express-thank-you__order-detail">
                  <span>Order Number: </span>
                  {orderId}
                </p>
                <p className="express-thank-you__order-detail">
                  <span>Order Total: </span>${(orderTotal / 100).toFixed(2)}
                </p>
              </div>
              <IncludeInstructions
                orderNumber={orderId}
                orderId={_id}
                specialInstructions={specialInstructions}
              />
            </div>
            <div className="express-thank-you__questions">
              <h2>Questions or concerns about your order?</h2>
              <BrentoButton
                variant="outlined"
                label="Contact us"
                onClick={() => history.push("/contact")}
              >
                Contact us
              </BrentoButton>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default ExpressThankYou;
