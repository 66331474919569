import React from "react";
import CategoryTemplate from "../CategoryTemplate";
import { Helmet } from "react-helmet";

const Bulk = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://getbrentobox.com/merch-and-more" />
        <title>
          Menu - Merch and More | Brento Box - Fresh Meal Prep Made Easy
        </title>
        <meta
          name="description"
          content="We've got lots of snazzy swag that you can add to your weekly order, like our Brento Box branded coolers and t-shirts."
        />
      </Helmet>
      <CategoryTemplate
        categoryTitle="Merch & More"
        categoryValue={"merch"}
        categoryTrackingId={"merch"}
      />
    </>
  );
};

export default Bulk;
