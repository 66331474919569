import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SET_ADD_ZIP_CODE_INPUT_OPEN } from "../../../../actions/types";
import BrentoLoadingButton from "../../../UI/BrentoLoadingButton";
import BrentoButton from "../../../UI/BrentoButton";
import BrentoTextField from "../../../UI/BrentoTextField/BrentoTextField";
import { IconButton } from "@mui/material";
import { addNewZipCode } from "../../../../actions/zipCodes";
import CloseIcon from "@mui/icons-material/Close";

import "./AddZipCode.scss";

const AddZipCode = () => {
  const zipCodeInputOpen = useSelector(
    (state) => state.zipCode.zipCodeInputOpen
  );
  const dispatch = useDispatch();

  const handleToggleInput = (isOpen) => {
    dispatch({ type: SET_ADD_ZIP_CODE_INPUT_OPEN, payload: isOpen });
  };

  return (
    <div className="add-zip-code">
      {zipCodeInputOpen ? (
        <AddZipCodeInput handleToggleInput={handleToggleInput} />
      ) : (
        <BrentoButton onClick={() => handleToggleInput(true)}>
          Add Zip Code
        </BrentoButton>
      )}
    </div>
  );
};

const AddZipCodeInput = ({ handleToggleInput }) => {
  const [inputValue, setInputValue] = useState("");
  const [submitAttempt, setSubmitAttempt] = useState(null);
  const dispatch = useDispatch();
  const addZipCodeLoading = useSelector(
    (state) => state.zipCode.addZipCodeLoading
  );
  const addZipCodeError = useSelector((state) => state.zipCode.addZipCodeError);

  const handleInputValue = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmitNewZipCode = (submitValue) => {
    setSubmitAttempt(submitValue);
    dispatch(addNewZipCode(submitValue));
  };

  useEffect(() => {
    console.log(addZipCodeError);
  }, [addZipCodeError]);

  return (
    <div className="add-zip-code-input">
      <p>{inputValue}</p>
      <div className="add-zip-code-input__header">
        <p className="header-5">Add zip code</p>
        <IconButton onClick={() => handleToggleInput()}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="add-zip-code-input__form">
        <BrentoTextField
          label="Enter zip code"
          fullWidth={true}
          onChange={(e) => handleInputValue(e)}
          value={inputValue}
          error={addZipCodeError && inputValue === submitAttempt ? true : false}
        />
        <BrentoLoadingButton
          pending={addZipCodeLoading}
          onClick={() => handleSubmitNewZipCode(inputValue)}
        >
          Submit
        </BrentoLoadingButton>
      </div>
      {addZipCodeError && inputValue === submitAttempt && (
        <p className="add-zip-code-input__error">{addZipCodeError}</p>
      )}
    </div>
  );
};

export default AddZipCode;
