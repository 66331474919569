import React, { useEffect } from "react";
import MealManager from "../../Components/Admin/MealManager/MealManager";
import { getAllMeals } from "../../actions/meal";
import { getMenuData } from "../../actions/menu";
import { useSelector, useDispatch } from "react-redux";

const Meals = () => {
  const dispatch = useDispatch();
  // const [selectedMeal, setSelectedMeal] = useState(null);

  const selectedMeal = useSelector((state) => state.meal.selectedMeal);
  const testMode = useSelector((state) => state.admin.testMode);

  useEffect(() => {
    dispatch(getAllMeals());
    dispatch(getMenuData(testMode));
  }, [dispatch]);

  return (
    <>
      <MealManager
        getAllMeals={() => getAllMeals()}
        selectedMeal={selectedMeal}
      />
    </>
  );
};

export default Meals;
