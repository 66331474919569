import React from "react";
import "./MealDetailsDietaryChip.scss";

const MealDetailsDietaryChip = ({ label }) => {
  return (
    <div className="dietary-chip">
      <CheckmarkIcon />
      <p className="dietary-chip__label">{label}</p>
    </div>
  );
};

const CheckmarkIcon = () => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 24 24" className="dietary-chip__icon">
      <polyline className="st0" points="4.5,11.5 9.5,16.5 20.5,5.5 " />
    </svg>
  );
};

export default MealDetailsDietaryChip;
