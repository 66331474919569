import React from "react";

const instagram = () => {
  return (
    <svg className="social-icon--instagram" x="0px" y="0px" viewBox="0 0 72 72">
      <linearGradient id="instagram-gradient" x2="0.5" y2="1">
        <stop offset="0%" stopColor="#fec564" />
        <stop offset="25%" stopColor="#5258cf" />
        <stop offset="50%" stopColor="#893dc2" />
        <stop offset="75%" stopColor="#d9317a" />
        <stop offset="100%" stopColor="#f77638" />
      </linearGradient>
      <circle cx="36" cy="36" r="36" />
      <path
        d="M36,0c19.9,0,36,16.1,36,36S55.9,72,36,72S0,55.9,0,36S16.1,0,36,0z M36,13.5c-6.1,0-6.9,0-9.3,0.1
	c-2.4,0.1-4,0.5-5.5,1c-1.5,0.6-2.7,1.3-4,2.6s-2,2.5-2.6,4c-0.6,1.4-0.9,3.1-1,5.5c-0.1,2.4-0.1,3.2-0.1,9.3s0,6.9,0.1,9.3
	c0.1,2.4,0.5,4,1,5.5c0.6,1.5,1.3,2.7,2.6,4c1.3,1.3,2.5,2,4,2.6c1.4,0.6,3.1,0.9,5.5,1c2.4,0.1,3.2,0.1,9.3,0.1s6.9,0,9.3-0.1
	c2.4-0.1,4-0.5,5.5-1c1.5-0.6,2.7-1.3,4-2.6c1.3-1.3,2-2.5,2.6-4c0.6-1.4,0.9-3.1,1-5.5c0.1-2.4,0.1-3.2,0.1-9.3s0-6.9-0.1-9.3
	c-0.1-2.4-0.5-4-1-5.5c-0.6-1.5-1.3-2.7-2.6-4c-1.3-1.3-2.5-2-4-2.6c-1.4-0.6-3.1-0.9-5.5-1C42.9,13.5,42.1,13.5,36,13.5z M36,17.6
	c6,0,6.7,0,9.1,0.1c2.2,0.1,3.4,0.5,4.2,0.8c1.1,0.4,1.8,0.9,2.6,1.7c0.8,0.8,1.3,1.5,1.7,2.6c0.3,0.8,0.7,2,0.8,4.2
	c0.1,2.4,0.1,3.1,0.1,9.1s0,6.7-0.1,9.1c-0.1,2.2-0.5,3.4-0.8,4.2c-0.4,1.1-0.9,1.8-1.7,2.6c-0.8,0.8-1.5,1.3-2.6,1.7
	c-0.8,0.3-2,0.7-4.2,0.8c-2.4,0.1-3.1,0.1-9.1,0.1s-6.7,0-9.1-0.1c-2.2-0.1-3.4-0.5-4.2-0.8c-1.1-0.4-1.8-0.9-2.6-1.7
	c-0.8-0.8-1.3-1.5-1.7-2.6c-0.3-0.8-0.7-2-0.8-4.2c-0.1-2.4-0.1-3.1-0.1-9.1s0-6.7,0.1-9.1c0.1-2.2,0.5-3.4,0.8-4.2
	c0.4-1.1,0.9-1.8,1.7-2.6c0.8-0.8,1.5-1.3,2.6-1.7c0.8-0.3,2-0.7,4.2-0.8C29.3,17.6,30,17.6,36,17.6z M36,24.4
	c-6.4,0-11.6,5.2-11.6,11.6S29.6,47.6,36,47.6S47.6,42.4,47.6,36S42.4,24.4,36,24.4z M36,43.5c-4.1,0-7.5-3.4-7.5-7.5
	s3.4-7.5,7.5-7.5s7.5,3.4,7.5,7.5S40.1,43.5,36,43.5z M50.7,24c0,1.5-1.2,2.7-2.7,2.7c-1.5,0-2.7-1.2-2.7-2.7s1.2-2.7,2.7-2.7
	C49.5,21.3,50.7,22.5,50.7,24z"
      />
    </svg>
  );
};

export default instagram;
