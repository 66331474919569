import React from "react";
import CategoryTemplate from "../CategoryTemplate";
import { Helmet } from "react-helmet";

const Bulk = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link
          rel="canonical"
          href="https://getbrentobox.com/menu/juices-and-coffee"
        />
        <title>
          Menu - Juices & Coffee | Brento Box - Fresh Meal Prep Made Easy
        </title>
        <meta
          name="description"
          content="Brento Box has partnered with AZ Liquids, a great local company based out of Phoenix. Add some delicious and nutritious beverages to your meal prep order."
        />
      </Helmet>
      <CategoryTemplate
        categoryTitle="Juices & Coffee"
        categoryValue={"juice"}
        categoryTrackingId={"juices-coffee"}
      />
    </>
  );
};

export default Bulk;
