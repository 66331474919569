import React from "react";

const DisplayPrice = ({ price, showDecimal = true }) => {
  const formatPrice = (priceNumber) => {
    if (isNaN(price)) {
      return ["--", "--"];
    } else {
      let priceString = priceNumber.toString();

      if (priceString.includes(".")) {
        let splitString = priceString.split(".");
        if (splitString[1].length < 2) {
          splitString[1] += "0";
        }
        console.log(splitString);
        return splitString;
      } else {
        priceString += ".00";
        return priceString.split(".");
      }
    }
  };

  return (
    <p className="display-price">
      <span className="display-price__dollar-sign">$</span>
      <span className="display-price__dollars">{formatPrice(price)[0]}</span>

      <span className="display-price__cents">
        {showDecimal ? `.${formatPrice(price)[1]}` : `${formatPrice(price)[1]}`}
      </span>
    </p>
  );
};

export default DisplayPrice;
