import React from "react";

const BrentoIcon = ({ icon }) => {
  return (
    <svg
      focusable="false"
      className="brento-icon"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 32 32"
    >
      {icon === "leftArrow" ? (
        <polyline fill="none" points="21.5,2.5 8.5,15.5 21.5,28.5 " />
      ) : icon === "rightArrow" ? (
        <polyline fill="none" points="10.5,28.5 23.5,15.5 10.5,2.5 " />
      ) : icon === "checkmark" ? (
        <polyline class="st0" points="2.5,16.5 10.5,24.5 29.5,5.5 " />
      ) : null}
    </svg>
  );
};

export default BrentoIcon;
