import React from "react";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Checkbox,
  TextField,
  InputLabel,
  RadioGroup,
  Radio,
  MenuItem,
  InputAdornment,
  Select,
} from "@mui/material";

const PromoCodeStatus = ({ formik }) => {
  return (
    <div className="create-promo-code-form__field-group">
      <h2>Promo code status</h2>
      <FormControl>
        <FormLabel id="promo-code-status-select">
          Update promo code status
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="promo-code-status-select"
          name="isActive"
          value={formik.values.isActive}
          onChange={formik.handleChange}
        >
          <FormControlLabel value="true" control={<Radio />} label="Active" />
          <FormControlLabel
            value="false"
            control={<Radio />}
            label="Inactive"
          />
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default PromoCodeStatus;
