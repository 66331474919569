import React from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIcon from "@mui/icons-material/Phone";
import InstagramIcon from "@mui/icons-material/Instagram";
import "./ContactContent.scss";
import { useSelector } from "react-redux";
import { variables } from "../../../variables/variables";

const ContactContent = () => {
  const displayWidth = useSelector((state) => state.layout.displayWidth);

  return (
    <div className="contact-fields">
      {displayWidth < variables.breakpoints.med ? (
        <MobileContactLinks />
      ) : (
        <ContactLinks />
      )}
    </div>
  );
};

const MobileContactLinks = () => {
  return (
    <>
      <a href="mailto:getbrentobox@gmail.com">
        <div className="contact-field">
          <MailOutlineIcon fontSize="large" />
          <p>GetBrentoBox@Gmail.com</p>
        </div>
      </a>
      <a href="tel:6232803972">
        <div className="contact-field">
          <PhoneIcon fontSize="large" />
          <p>623-280-3972</p>
        </div>
      </a>
      <a href="https://instagram.com/getbrentobox">
        <div className="contact-field">
          <InstagramIcon fontSize="large" />
          <p>@GetBrentoBox</p>
        </div>
      </a>
    </>
  );
};

const ContactLinks = () => {
  return (
    <>
      <div className="primary-contacts">
        <div className="contact-field">
          <MailOutlineIcon fontSize="large" />
          <p>GetBrentoBox@Gmail.com</p>
        </div>
        <div className="contact-field">
          <PhoneIcon fontSize="large" />
          <p>623-280-3972</p>
        </div>
      </div>
      <a href="https://instagram.com/getbrentobox">
        <div className="contact-field">
          <InstagramIcon fontSize="large" />
          <p>@GetBrentoBox</p>
        </div>
      </a>
    </>
  );
};

export default ContactContent;
