import ReactGA from "react-ga4";
import { formatAllLineItems } from "./utils/formatAllLineItems";

export const trackPurchase = (
  cartItems,
  total,
  discountCode,
  paymentIntentId,
  paymentType = "Credit Card"
) => {
  let overallTotal;

  if (discountCode) {
    overallTotal = discountCode.discountApplied + total;
  } else {
    overallTotal = total;
  }

  let eventArgs = {
    transaction_id: paymentIntentId,
    currency: "USD",
    value: overallTotal / 100,
    shipping: 5,
    payment_type: paymentType,
    items: formatAllLineItems(cartItems, true),
  };

  if (discountCode) {
    if (discountCode.discountCode) {
      eventArgs.coupon = discountCode.discountCode;
    } else {
      eventArgs.coupon = discountCode;
    }
  }
  ReactGA.gtag("event", "purchase", eventArgs);
};

// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=10902621433338295728-NA&client_type=gtag#purchase

// gtag("event", "purchase", {
//   transaction_id: "T_12345",
//   value: 25.42,
//   tax: 4.9,
//   shipping: 5.99,
//   currency: "USD",
//   coupon: "SUMMER_SALE",
//   items: [
//     {
//       item_id: "SKU_12345",
//       item_name: "Stan and Friends Tee",
//       affiliation: "Google Merchandise Store",
//       coupon: "SUMMER_FUN",
//       discount: 2.22,
//       index: 0,
//       item_brand: "Google",
//       item_category: "Apparel",
//       item_category2: "Adult",
//       item_category3: "Shirts",
//       item_category4: "Crew",
//       item_category5: "Short sleeve",
//       item_list_id: "related_products",
//       item_list_name: "Related Products",
//       item_variant: "green",
//       location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
//       price: 9.99,
//       quantity: 1,
//     },
//     {
//       item_id: "SKU_12346",
//       item_name: "Google Grey Women's Tee",
//       affiliation: "Google Merchandise Store",
//       coupon: "SUMMER_FUN",
//       discount: 3.33,
//       index: 1,
//       item_brand: "Google",
//       item_category: "Apparel",
//       item_category2: "Adult",
//       item_category3: "Shirts",
//       item_category4: "Crew",
//       item_category5: "Short sleeve",
//       item_list_id: "related_products",
//       item_list_name: "Related Products",
//       item_variant: "gray",
//       location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
//       price: 20.99,
//       promotion_id: "P_12345",
//       promotion_name: "Summer Sale",
//       quantity: 1,
//     },
//   ],
// });
