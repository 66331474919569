import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { updateInstructionsRequest } from "./utils/updateInstructionsRequest";
import BrentoButton from "../../../../UI/BrentoButton";
import { setOrderDeliveryInstructions } from "../../../../../actions/orders";
import BrentoTextField from "../../../../UI/BrentoTextField/BrentoTextField";
import BrentoLoadingButton from "../../../../UI/BrentoLoadingButton";
import Alert from "../../../../UI/Alert/Alert";
import "./IncludeInstructions.scss";

const IncludeInstructions = ({ orderNumber, orderId, specialInstructions }) => {
  const [updatedInstructions, setUpdatedInstructions] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const dispatch = useDispatch();
  const testMode = useSelector((state) => state.admin.testMode);
  // const orderConfirmationData = useSelector(
  //   (state) => state.admin.orderConfirmationData
  // );

  useEffect(() => {
    if (specialInstructions) {
      setUpdatedInstructions(updatedInstructions);
    }
  }, []);

  const handleUpdateInstructions = (e) => {
    setUpdatedInstructions(e.target.value);
  };

  const handleSubmitInstructions = async (e) => {
    setSubmitLoading(true);
    try {
      let res = await updateInstructionsRequest(
        updatedInstructions,
        orderId,
        testMode
      );
      console.log(res);
      setSubmitLoading(false);
      if (res.data.specialInstructions === updatedInstructions) {
        setSubmitSuccess(true);
        setShowAlert(true);
        dispatch(setOrderDeliveryInstructions(updatedInstructions));
      }
    } catch (err) {
      console.log(err);
      setSubmitLoading(false);
      setSubmitError(true);
      setShowAlert(true);
    }
  };

  return (
    <div className="express-thank-you__instructions">
      {(!editMode && specialInstructions) || submitSuccess ? (
        <CompletedState
          setSubmitSuccess={setSubmitSuccess}
          specialInstructions={specialInstructions}
          setEditMode={setEditMode}
          setShowAlert={setShowAlert}
          showAlert={showAlert}
        />
      ) : (
        <>
          {editMode ? (
            <>
              <p className="express-thank-you__instructions-header">
                Update delivery instructions:
              </p>
            </>
          ) : (
            <p className="express-thank-you__instructions-header">
              <span>(Optional)</span> Include delivery instructions:
            </p>
          )}

          <BrentoTextField
            fullWidth={true}
            multiline={true}
            rows={6}
            disabled={submitSuccess}
            variant="outlined"
            value={updatedInstructions}
            onChange={handleUpdateInstructions}
            placeholder="Enter special instructions for your delivery driver, such as directions or gate codes."
          />
          {editMode ? (
            <EditModeControls
              specialInstructions={updatedInstructions}
              handleSubmitInstructions={handleSubmitInstructions}
              setEditMode={setEditMode}
              submitLoading={submitLoading}
              setSubmitSuccess={setSubmitSuccess}
            />
          ) : (
            <BrentoLoadingButton
              fullWidth={true}
              disabled={updatedInstructions.length < 1}
              label="Submit delivery instructions"
              pending={submitLoading}
              key={`${updatedInstructions.length < 1}`}
              onClick={handleSubmitInstructions}
            >
              Submit delivery instructions
            </BrentoLoadingButton>
          )}

          <div>
            {!submitLoading && submitError && showAlert ? (
              <Alert
                type="error"
                header="Unable to update your delivery instructions"
                content="Sorry, something went wrong. Please try again. If this problem persists, please contact us."
              />
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

const CompletedState = ({
  setSubmitSuccess,
  setEditMode,
  specialInstructions,
  setShowAlert,
  showAlert,
}) => {
  const handleEditInstructions = (e) => {
    setSubmitSuccess(false);
    setEditMode(true);
    setShowAlert(false);
  };
  return (
    <>
      <div className="ety__complete">
        <h3>Delivery instructions:</h3>
        <p>"{specialInstructions}"</p>
        <button
          className="ety__complete-edit-btn"
          onClick={(e) => handleEditInstructions(e)}
          label="Edit delivery instructions"
        >
          Edit
        </button>
      </div>
      {showAlert && (
        <Alert
          type="success"
          header="Instructions updated successfully"
          content="Your delivery instructions have been successfully added to your order."
        />
      )}
    </>
  );
};

const EditModeControls = ({
  specialInstructions,
  handleSubmitInstructions,
  setEditMode,
  setSubmitSuccess,
  submitLoading,
}) => {
  const handleCancelEdit = () => {
    setSubmitSuccess(true);
    setEditMode(false);
  };
  return (
    <div className="ety__edit-controls">
      <BrentoButton
        fullWidth={true}
        size="large"
        label="Cancel"
        variant="outlined"
        onClick={handleCancelEdit}
      >
        Cancel
      </BrentoButton>
      <BrentoLoadingButton
        fullWidth={true}
        disabled={specialInstructions.length < 1}
        size="large"
        label="Submit delivery instructions"
        pending={submitLoading}
        key={`${specialInstructions.length < 1}`}
        onClick={handleSubmitInstructions}
      >
        Confirm update
      </BrentoLoadingButton>
    </div>
  );
};

export default IncludeInstructions;
