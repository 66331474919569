import {
  GET_CART_ITEMS,
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CHANGE_CART_QUANTITY,
  INCREASE_CART_QUANTITY,
  SET_CART_EXPIRATION_DATE,
  CLEAR_ALL_CART_ITEMS,
  SET_CART_INDICATOR_ACTIVE,
  SET_CART_DISCOUNT_CODE,
  CLEAR_CART_DISCOUNT_CODE,
} from "../actions/types";

const initialState = localStorage.getItem("cart")
  ? JSON.parse(localStorage.getItem("cart"))
  : {
      cartItems: [],
      cartLoading: true,
      cartExpirationDate: null,
      cartIndicatorActive: false,
      cartDiscountCode: null,
    };

export default function cartReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_CART_ITEMS:
      return {
        ...state,
        cartLoading: false,
      };
    case ADD_TO_CART:
      return {
        ...state,
        cartItems: [...state.cartItems, payload],
      };
    case REMOVE_FROM_CART:
      return {
        ...state,
        cartItems: state.cartItems.filter(
          (cartItem) => cartItem.meal._id !== payload
        ),
      };
    case CHANGE_CART_QUANTITY:
      return {
        ...state,
        cartItems: state.cartItems.map((cartItem) => {
          if (cartItem.meal._id !== payload.id) {
            return cartItem;
          }
          return {
            meal: { ...cartItem.meal },
            qty: payload.qty,
          };
        }),
      };
    case INCREASE_CART_QUANTITY:
      return {
        ...state,
        cartItems: state.cartItems.map((cartItem) => {
          if (cartItem.meal._id !== payload.id) {
            return cartItem;
          }
          return {
            ...cartItem,
            qty: cartItem.qty + payload.qty,
          };
        }),
      };
    case SET_CART_EXPIRATION_DATE:
      return {
        ...state,
        cartExpirationDate: payload,
      };
    case CLEAR_ALL_CART_ITEMS:
      return {
        ...state,
        cartItems: [],
        cartLoading: true,
        cartExpirationDate: null,
      };
    case SET_CART_INDICATOR_ACTIVE:
      return {
        ...state,
        cartIndicatorActive: payload,
      };
    case SET_CART_DISCOUNT_CODE:
      return {
        ...state,
        cartDiscountCode: payload,
      };
    case CLEAR_CART_DISCOUNT_CODE:
      return {
        ...state,
        cartDiscountCode: null,
      };
    default:
      return state;
  }
}
