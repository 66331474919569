import React, { useEffect } from "react";
import {
  getAllGiftCards,
  setGiftCardManagerView,
} from "../../../actions/giftCards";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { variables } from "../../../variables/variables";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./GiftCardManager.scss";
import GiftCardTable from "./GiftCardTable/GiftCardTable";

const GiftCardManager = () => {
  const dispatch = useDispatch();
  const testMode = useSelector((state) => state.admin.testMode);
  const giftCards = useSelector((state) => state.giftCards.giftCards);
  const isMobile = useSelector(
    (state) => state.layout.displayWidth < variables.breakpoints.lrg
  );
  const giftCardManagerView = useSelector(
    (state) => state.giftCards.giftCardManagerView
  );
  const giftCardsLoading = useSelector(
    (state) => state.giftCards.giftCardsLoading
  );

  const handleChangeView = (event, newValue) => {
    dispatch(setGiftCardManagerView(newValue));
  };

  useEffect(() => {
    dispatch(getAllGiftCards(testMode));
    console.log(giftCards);
  }, [dispatch, testMode]);

  return (
    <>
      {giftCardsLoading ? (
        <p>Loading...</p>
      ) : (
        <>
          <div className="gift-card-manager">
            <div className="gift-card-manager__header">
              <h2 className="header-5">Gift cards</h2>
              <div className="gift-card-manager__controls">
                <div className="gift-card-manager__tabs">
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="gift-card-manager-radio"
                      name="radio-buttons-group"
                      row={!isMobile}
                      value={giftCardManagerView}
                      onChange={handleChangeView}
                    >
                      <FormControlLabel
                        value="customer"
                        control={<Radio />}
                        label="Customer purchased"
                        aria-controls="panel-1"
                      />
                      <FormControlLabel
                        value="admin-created"
                        control={<Radio />}
                        label="Admin created"
                        aria-controls="panel-2"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div>
            <>
              {giftCards && giftCards.length > 0 ? (
                giftCardManagerView === "customer" ? (
                  <div id="panel-1">
                    <GiftCardTable
                      showCreateButton
                      tableTitle="Customer purchased"
                      giftCards={giftCards.filter(
                        (giftCard) => giftCard.discountType === "gift-card"
                      )}
                    />
                  </div>
                ) : (
                  <div id="panel-2">
                    <GiftCardTable
                      tableTitle="Admin created"
                      showCreateButton
                      giftCards={giftCards.filter(
                        (giftCard) =>
                          giftCard.discountType === "admin-gift-card"
                      )}
                    />
                  </div>
                )
              ) : (
                <p>No gift cards found</p>
              )}
            </>
          </div>
        </>
      )}
    </>
  );
};

const GiftCardItem = ({
  giftCardId,
  discountCode,
  initialBalance,
  currentBalance,
  emailSent,
  deliveryDate,
}) => {
  const formatAsDollar = (numberCents) => {
    const valueInDollars = numberCents / 100;
    return valueInDollars.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };
  return (
    <li className="gift-card-item" key={discountCode}>
      <Link
        className="gift-card-item__link"
        to={`/admin/gift-card/${giftCardId}`}
      >
        <p className="gift-card-item__discount-code">{discountCode}</p>
      </Link>
      <p className="gift-card-item__field">{formatAsDollar(initialBalance)}</p>
      <p className="gift-card-item__field">{formatAsDollar(currentBalance)}</p>
      <p className="gift-card-item__field">
        {emailSent ? (
          <span className="yes">Yes</span>
        ) : (
          <span className="no">No</span>
        )}
      </p>
    </li>
  );
};

export default GiftCardManager;
