import React from "react";
import "./MealDetailsUnavailable.scss";

const MealDetailsUnavailable = ({ type }) => {
  return type === "not-on-menu" ? (
    <div className="meal-details-unavailable">
      <p className="meal-details-unavailable__header">
        This meal is unavailable
      </p>
      <p className="meal-details-unavailable__body">
        This meal is currently unavailable. Check back next week to see if it's
        on the menu!
      </p>
    </div>
  ) : (
    <div className="meal-details-unavailable">
      <p className="meal-details-unavailable__header">
        Ordering is unavailable
      </p>
      <p className="meal-details-unavailable__body">
        Sorry, orders are closed for this week. Check back on Tuesday for our
        upcoming menu!
      </p>
    </div>
  );
};

export default MealDetailsUnavailable;
