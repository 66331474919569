import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const BrentoCheckbox = ({ checked, onChange, label, disabled, name }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          checked={checked}
          onChange={onChange}
          name={name}
        />
      }
      label={label}
      disabled={disabled}
    />
  );
};

export default BrentoCheckbox;
