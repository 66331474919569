import {
  GET_FULL_MENU,
  GET_MENU_DATA,
  GET_MENU_ERROR,
  GET_MENU_DATA_ERROR,
  MENU_LOADING,
  MENU_DATA_LOADING,
  UPDATE_MENU,
  SET_ORDERING_AVAILABLE,
  SET_PREF_MENU_VIEW,
} from "../actions/types";

const initialState = {
  activeMenu: {},
  menuLoading: true,
  menuDataLoading: true,
  menuError: false,
  prefMenuView: localStorage.getItem("prefMenuView")
    ? localStorage.getItem("prefMenuView")
    : "categories",
  menuDataError: false,
  orderingAvailable: false,
};

export default function menuReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_FULL_MENU:
      return {
        ...state,
        activeMenu: payload,
      };
    case GET_MENU_DATA:
      return {
        ...state,
        activeMenu: payload,
      };
    case UPDATE_MENU:
      return {
        ...state,
        activeMenu: payload,
      };
    case MENU_LOADING:
      return {
        ...state,
        menuLoading: payload,
      };
    case MENU_DATA_LOADING:
      return {
        ...state,
        menuDataLoading: payload,
      };
    case GET_MENU_ERROR:
      return {
        ...state,
        menuError: payload,
      };
    case GET_MENU_DATA_ERROR:
      return {
        ...state,
        menuDataError: payload,
      };
    case SET_ORDERING_AVAILABLE:
      return {
        ...state,
        orderingAvailable: payload,
      };
    case SET_PREF_MENU_VIEW:
      return {
        ...state,
        prefMenuView: payload,
      };
    default:
      return state;
  }
}
