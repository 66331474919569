import { determineMenuStatus } from "./determineMenuStatus";

export const shouldAvailabilityUpdate = (
  currentStatus,
  orderStartDate,
  orderByDate
) => {
  if (currentStatus !== determineMenuStatus(orderStartDate, orderByDate)) {
    return true;
  } else {
    return false;
  }
};
