import React from "react";
import { Link } from "react-router-dom";
import "./CategoryLink.scss";

const CategoryLink = ({ categoryTitle, categoryURL, categoryImage }) => {
  return (
    <>
      <Link to={categoryURL} className={`menu-category-link`}>
        <img
          src={categoryImage}
          alt={categoryTitle}
          className="menu-category-link__image"
        />
        <div className="menu-category-link__content">
          <p>{categoryTitle}</p>
        </div>
      </Link>
    </>
  );
};

export default CategoryLink;
