import React, { useState } from "react";
import { validateCodeNameRequest } from "../utils/validateCodeNameRequest";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Checkbox,
  TextField,
  InputLabel,
  RadioGroup,
  Radio,
  MenuItem,
  InputAdornment,
  Select,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import Spinner from "../../../UI/Spinner/Spinner";

const PromoCodeEssentials = ({ formik, testMode, formMode = "create" }) => {
  const [codeNameLoading, setCodeNameLoading] = useState(false);
  const [codeNameError, setCodeNameError] = useState(false);
  const [codeNameValid, setCodeNameValid] = useState(false);

  const validateCodeNameField = async (value) => {
    console.log("Validating code...");
    try {
      let isCodeNameValid = await validateCodeNameRequest(testMode, value);
      console.log(isCodeNameValid);
      if (isCodeNameValid.isValid) {
        setCodeNameLoading(false);
        setCodeNameError(false);
        setCodeNameValid(true);
      } else {
        setCodeNameLoading(false);
        setCodeNameError(true);
        setCodeNameValid(false);
        formik.setFieldError(
          "discountCode",
          "A promo code already exists with that name"
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeDiscountCode = async (value) => {
    setCodeNameValid(false);
    formik.setFieldValue("discountCode", `${value}`, true);
  };

  const handleDiscountCodeBlur = async () => {
    formik.setFieldTouched("discountCode", true, true);
    if (!codeNameValid && formik.values.discountCode.length > 0) {
      validateCodeNameField(formik.values.discountCode);
    }
  };

  const handleSelectUsage = (value) => {
    formik.setFieldValue("usageType", value);
    if (value === "single-use") {
      formik.setFieldValue("maxUseCount", 1);
    } else if (value === "multiple-use") {
      formik.setFieldValue("maxUseCount", 2);
    } else {
      formik.setFieldValue("emailRequired", true);
    }
  };

  return (
    <div className="create-promo-code-form__field-group">
      <h2>Promo code details</h2>
      {formMode === "create" && (
        <TextField
          fullWidth
          name="discountCode"
          placeholder="I-love-brento-23"
          disabled={formMode === "edit"}
          label="Enter a unique promo code"
          onChange={(e) => handleChangeDiscountCode(e.target.value)}
          onBlur={() => handleDiscountCodeBlur()}
          value={formik.values.discountCode}
          sx={{ paddingBottom: "1rem" }}
          error={
            formik.touched.discountCode && formik.errors.discountCode
              ? true
              : false
          }
          helperText={formik.touched.discountCode && formik.errors.discountCode}
          inputProps={{ style: { textTransform: "uppercase" } }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {codeNameLoading ? (
                  <Spinner size="small" />
                ) : codeNameValid && !formik.errors.discountCode ? (
                  <CheckIcon color="success" />
                ) : null}
              </InputAdornment>
            ),
          }}
        ></TextField>
      )}
      <div className="field-row">
        <FormControl disabled={formMode === "edit"}>
          <FormLabel id="promo-code-audience-select">
            Who is this promo code for?
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="promo-code-audience-select"
            name="discountAudience"
            value={formik.values.discountAudience}
            onChange={formik.handleChange}
          >
            <FormControlLabel
              value="customer"
              control={<Radio />}
              label="Customer"
            />
            <FormControlLabel
              value="team-member-vendor"
              control={<Radio />}
              label="Team member or vendor"
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div className="field-row">
        <div className="field-row__right">
          <FormControl fullWidth>
            <InputLabel id="promo-code-usage-label">How many uses?</InputLabel>
            <Select
              value={formik.values.usageType}
              labelId="promo-code-usage-label"
              id="promo-code-usage-select"
              onChange={(e) => handleSelectUsage(e.target.value)}
              label="How many uses?"
            >
              <MenuItem value="single-use">One time only</MenuItem>
              <MenuItem value="multiple-use">Multiple uses</MenuItem>
              <MenuItem value="unlimited-use">Unlimited uses</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="field-row__left">
          {formik.values.usageType !== "unlimited-use" && (
            <TextField
              fullWidth
              type="number"
              name="maxUseCount"
              value={formik.values.maxUseCount}
              disabled={formik.values.usageType === "single-use"}
              onChange={formik.handleChange}
              label="Max number of uses"
              error={formik.touched.maxUseCount && formik.errors.maxUseCount}
            ></TextField>
          )}
        </div>
      </div>
      <div className="field-row">
        <div className="field-row__right">
          <FormControl>
            <FormLabel>Select discount benefits</FormLabel>
            <FormGroup row>
              <FormControlLabel
                onChange={formik.handleChange}
                name="isPercentOff"
                control={<Checkbox checked={formik.values.isPercentOff} />}
                label="Percentage off order"
              />
              <FormControlLabel
                onChange={formik.handleChange}
                name="isFreeShipping"
                control={<Checkbox checked={formik.values.isFreeShipping} />}
                label="Free shipping"
              />
              <FormControlLabel
                onChange={formik.handleChange}
                name="isFreeProcessing"
                control={<Checkbox checked={formik.values.isFreeProcessing} />}
                label="Free processing"
              />
            </FormGroup>
          </FormControl>
        </div>
        <div className="field-row__left">
          {formik.values.isPercentOff && (
            <TextField
              fullWidth
              name="percentOff"
              type="number"
              value={formik.values.percentOff}
              label="Percent off"
              onChange={formik.handleChange}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              error={
                formik.touched.percentOff && formik.errors.percentOff
                  ? true
                  : false
              }
              helperText={formik.errors.percentOff && formik.errors.percentOff}
            ></TextField>
          )}
        </div>
      </div>
    </div>
  );
};

export default PromoCodeEssentials;
