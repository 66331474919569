import React from "react";
import "./AboutContent.scss";
import BrentImage from "../../../img/brent-headshot-closer.jpg";
import VideoHero from "../VideoHero/VideoHero";

const AboutContent = () => {
  return (
    <div className="about-content">
      <div className="about-content__text">
        <VideoHero />
        <h2 className="about-content__text-opener header-3">
          Simplify your life, reach your goals, enjoy what you eat.
        </h2>
        <p className="body-1">
          At Brento Box, we're on a mission to provide healthy meal prep to the
          Phoenix community to help people of all walks of life look and feel
          their best. We take the guesswork out of your meal planning for the
          week. Enjoy fresh, healthy and delicious meals delivered to your door
          every Sunday.
        </p>
        <p className="body-1">
          We've designed our meals to help you reach your goals without feeling
          like you're on a diet. We fully believe that you can and should enjoy
          what you eat. By creating healthier versions of meals everyone enjoys
          and keeping our meals around 500 calories, you can reach your goals
          while enjoying every bite.
        </p>
        <p className="body-1">
          <b>Healthy eating has changed my life, let us help change yours!</b>
        </p>
        <div className="about-content__img">
          <img
            className="about-content__headshot"
            src={BrentImage}
            alt="Brent Imbornoni"
          />
          <p className="about-content__caption">
            <span className="about-content__name eyebrow-1">
              Brent Imbornoni
            </span>
            Founder
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutContent;
