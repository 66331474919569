import React, { useState } from "react";
import { useFormik } from "formik";
import "./CreatePromoCodeForm.scss";
import PromoCodeEssentials from "./fields/PromoCodeEssentials";
import TeamMemberEmails from "./fields/TeamMemberEmails";
import BrentoLoadingButton from "../../UI/BrentoLoadingButton";
import { validationSchema } from "./Validation";
import { useSelector } from "react-redux";

const CreatePromoCodeForm = ({
  createPromoCodeRequest,
  promoCodeCreationSuccessful,
  finalizePromoCodeCreation,
  promoCodeData,
  setPromoCodeData,
}) => {
  const [submitPending, setSubmitPending] = useState(false);

  const testMode = useSelector((state) => state.admin.testMode);

  const formik = useFormik({
    initialValues: {
      discountCode: "",
      discountAudience: "customer",
      isFreeShipping: false,
      isFreeProcessing: false,
      isPercentOff: true,
      percentOff: 10,
      usageType: "single-use",
      maxUseCount: 1,
      teamMemberEmails: [""],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("Submitting!");
      setSubmitPending(true);
      try {
        handleFullSubmit(values);
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handlePreSubmit = () => {
    formik.setTouched({
      discountCode: true,
      maxUseCount: true,
      greetingMessage: true,
      percentOff: true,
      teamMemberEmails: true,
    });
    formik.handleSubmit();
  };

  const handleFullSubmit = async (formValues) => {
    formValues.discountCode = formValues.discountCode.toLocaleUpperCase();
    let promoCodeResponse = await createPromoCodeRequest(testMode, {
      ...formValues,
    });
    console.log(promoCodeResponse);
    if (
      promoCodeResponse &&
      promoCodeResponse.response &&
      promoCodeResponse.response.data.errors.length > 0
    ) {
      setSubmitPending(false);
      setPromoCodeData(null);
      return promoCodeResponse;
    } else {
      setSubmitPending(false);
      setPromoCodeData(promoCodeResponse);
      finalizePromoCodeCreation(promoCodeResponse);
      return promoCodeResponse;
    }
  };

  return (
    <form className="create-promo-code-form" onSubmit={formik.handleSubmit}>
      <PromoCodeEssentials formik={formik} testMode={testMode} />
      {formik.values.discountAudience === "team-member-vendor" && (
        <TeamMemberEmails formik={formik} />
      )}
      <div>
        <BrentoLoadingButton onClick={handlePreSubmit} pending={submitPending}>
          Create Promo Code
        </BrentoLoadingButton>
      </div>
    </form>
  );
};

export default CreatePromoCodeForm;
