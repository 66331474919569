import React from "react";
import CheckoutFieldset from "../../CheckoutFieldset";
import { stateValueOptions } from "../../../CheckoutForm/stateValueOptions";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import BrentoTextField from "../../../../UI/BrentoTextField/BrentoTextField";
import BrentoSelect from "../../../../UI/BrentoSelect/BrentoSelect";

const BillingAddress = ({ formik }) => {
  return (
    <>
      <CheckoutFieldset label="Billing Address">
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="Select billing address"
            name="useDifferentBillingAddress"
            value={formik.values.useDifferentBillingAddress}
            onChange={formik.handleChange}
          >
            <FormControlLabel
              value="false"
              control={<Radio color="primary" />}
              label="Same as delivery address"
            />
            <FormControlLabel
              value="true"
              control={<Radio color="primary" />}
              label="Use a different billing address"
            />
          </RadioGroup>
        </FormControl>
      </CheckoutFieldset>
      {formik.values.useDifferentBillingAddress === "true" ? (
        <BillingAddressFields formik={formik} />
      ) : null}
    </>
  );
};

const BillingAddressFields = ({ formik }) => {
  const handleZipCodeBlur = () => {
    formik.setFieldTouched("billingZipCode", true, true);
  };
  return (
    <>
      <CheckoutFieldset>
        <BrentoTextField
          label="Address Line 1"
          variant="outlined"
          name="billingAddressLine1"
          value={formik.values.billingAddressLine1}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          fullWidth={true}
          error={
            formik.errors.billingAddressLine1 &&
            formik.touched.billingAddressLine1
          }
          helperText={
            formik.touched.billingAddressLine1 &&
            formik.errors.billingAddressLine1
          }
        />
        <BrentoTextField
          label="Address Line 2"
          name="billingAddressLine2"
          value={formik.values.billingAddressLine2}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          variant="outlined"
          fullWidth={true}
        />
        <div className="checkout-form__city-state-row">
          <BrentoTextField
            label="City"
            name="billingCity"
            value={formik.values.billingCity}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            variant="outlined"
            fullWidth={true}
            error={
              formik.touched.billingCity && formik.errors.billingCity
                ? true
                : false
            }
            helperText={formik.touched.billingCity && formik.errors.billingCity}
          />
          <BrentoSelect
            label="State"
            name="billingState"
            value={formik.values.billingState}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            options={stateValueOptions}
            native={true}
            error={
              formik.touched.billingState && formik.errors.billingState
                ? true
                : false
            }
            formHelperText={
              formik.errors.billingState && formik.errors.billingState
            }
          />
        </div>
        <BrentoTextField
          label="ZIP Code"
          type="number"
          name="billingZipCode"
          value={formik.values.billingZipCode}
          onChange={formik.handleChange}
          onBlur={() => handleZipCodeBlur()}
          variant="outlined"
          fullWidth={true}
          error={
            formik.touched.billingZipCode && formik.errors.billingZipCode
              ? true
              : false
          }
          helperText={
            formik.touched.billingZipCode && formik.errors.billingZipCode
          }
        />
      </CheckoutFieldset>
    </>
  );
};

export default BillingAddress;
