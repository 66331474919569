import React from "react";
import Spinner from "../../../../../../UI/Spinner/Spinner";

const PromoCodeSuccess = ({
  discountCodeDetails,
  handleRemoveDiscountCode,
  removeDiscountLoading,
}) => {
  return (
    <div className="apply-promo-details">
      <div className="apply-promo-details__primary">
        <div className="apply-promo-details__header">
          <h3>
            Discount code
            <span>{` ${discountCodeDetails.discountCode} `}</span>
            added
          </h3>
        </div>
        <div className="apply-promo-details__contents">
          <ul>
            {discountCodeDetails.isPercentOff && (
              <PromoCodeBenefit
                content={`${discountCodeDetails.percentOff}% off order subtotal`}
              />
            )}
            {discountCodeDetails.isFreeShipping && (
              <PromoCodeBenefit content={`Free delivery`} />
            )}
            {discountCodeDetails.isFreeProcessing && (
              <PromoCodeBenefit content={`Free processing`} />
            )}
          </ul>
        </div>
        {removeDiscountLoading ? (
          <div className="apply-promo-details__removing">
            <Spinner />
            <p>Removing promo code...</p>
          </div>
        ) : (
          <button
            className="remove-code"
            onClick={() => handleRemoveDiscountCode()}
            type="button"
          >
            Remove promo code
          </button>
        )}
      </div>
    </div>
  );
};

const PromoCodeBenefit = ({ content }) => {
  return <li className="apply-promo-details__benefit">{content}</li>;
};

export default PromoCodeSuccess;
