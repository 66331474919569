import React from "react";
import "./AnalyticsTable.scss";

const AnalyticsTable = ({
  analyticsData,
  filterBy,
  breakdownBy,
  dateValues,
  dataNodes,
}) => {
  console.log(dataNodes);

  return (
    <div className="analytics-table">
      <TableHeader column={breakdownBy} />
      {dataNodes[breakdownBy] &&
        dataNodes[breakdownBy].map((node) => <TableRow node={node} />)}
    </div>
  );
};

const TableRow = ({ node }) => {
  return (
    <div className="analytics-table__row">
      <p className="row-label">{node.name}</p>
      <p className="row-value">
        {Number(node.data.totalRevenue / 100).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}
      </p>
      <div className="additional-values">
        <p className="row-value">{node.data.totalOrders}</p>
        <p className="row-value">{node.data.totalMeals}</p>
      </div>
    </div>
  );
};

const TableHeader = ({ column }) => {
  return (
    <div className="analytics-table__header">
      <p className="header-label">{column}</p>
      <p className="row-value">Revenue</p>
      <div className="additional-values">
        <p className="row-value">Total Orders</p>
        <p className="row-value">Total Meals</p>
      </div>
    </div>
  );
};

export default AnalyticsTable;
