import axios from "axios";

export const giftCardPaymentIntentRequest = async (
  formValues,
  paymentIntentId,
  testMode
) => {
  console.log("Test mode in PI Request? " + testMode);
  try {
    let baseUrl = testMode ? "test-payment" : "payment";
    let endUrl = paymentIntentId
      ? `update-discount-payment-intent/${paymentIntentId}`
      : "create-discount-payment-intent";
    let completeUrl = `/api/${baseUrl}/${endUrl}`;

    let res = await axios.post(completeUrl, formValues, {
      headers: { "Content-Type": "application/json" },
    });
    return res.data;
  } catch (err) {
    console.log(err);
  }

  // const createPaymentIntent = async (cartItems) => {
  //   try {
  //     let res = await axios.post(
  //       "/api/payment/create-payment-intent",
  //       cartItems,
  //       {
  //         headers: { "Content-Type": "application/json" },
  //       }
  //     );
  //     setStripeClientSecret(res.data.stripeClientSecret);
  //     setOrderAttemptDetails(res.data.orderDetails);
  //     setCheckoutLoading(false);
  //   } catch (err) {
  //     console.log("An error is going down!");
  //     setCheckoutError(err);
  //     setCheckoutLoading(false);
  //   }
  // };
};
