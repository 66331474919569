import ReactGA from "react-ga4";
import { formatLineItem } from "./utils/formatLineItem";

export const trackSelectItem = (meal, listId, listName) => {
  let eventArgs = {
    currency: "USD",
    value: meal.price,
    item_list_id: listId,
    item_list_name: listName,
    // payment_type: "Credit Card",
    items: [formatLineItem(meal, 1, 0)],
  };

  ReactGA.gtag("event", "select_item", eventArgs);
};
