import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { useDispatch } from "react-redux";
import { setDrawerComponent } from "../../actions/layout";
import TestModeToggle from "./TestModeToggle/TestModeToggle";
import { getAnalyticsData } from "../../actions/analytics";
import Analytics from "./Analytics/Analytics";
import QuickLinks from "./AdminDashboard/QuickLinks/QuickLinks";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const AdminDashboard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAnalyticsData());
    dispatch(setDrawerComponent(null));
  }, []);

  return (
    <div>
      <h1>Admin Dashboard</h1>
      <p>Welcome Brent!</p>
      {process.env.NODE_ENV === "development" && <TestModeToggle />}
      <Analytics />
      <QuickLinks />
    </div>
  );
};

export default AdminDashboard;
