import React from "react";
import Instagram from "./IconSVGs/instagram";
import Facebook from "./IconSVGs/facebook";
import Youtube from "./IconSVGs/youtube";
import Yelp from "./IconSVGs/yelp";
import "./SocialIcons.scss";

const SocialIcons = () => {
  return (
    <nav className="social-icons">
      <a href="https://www.instagram.com/getbrentobox/" className="social-icon">
        <Instagram />
      </a>
      <a href="https://www.facebook.com/getbrentobox" className="social-icon">
        <Facebook />
      </a>
      <a
        href="https://www.youtube.com/channel/UCWTGld_sZDm7wg-SZlcK6Tw"
        className="social-icon"
      >
        <Youtube />
      </a>
      <a
        href="https://www.yelp.com/biz/brento-box-phoenix"
        className="social-icon"
      >
        <Yelp />
      </a>
    </nav>
  );
};

export default SocialIcons;
