import React from "react";
import "./TestModeIndicator.scss";
import { Link } from "react-router-dom";

const TestModeIndicator = () => {
  return (
    <div className="test-mode-indicator">
      <p className="test-mode-indicator__label">TEST MODE</p>
      <div className="test-mode-indicator__links">
        <Link to="/">Home</Link>
        <Link to="/admin">Admin</Link>
      </div>
    </div>
  );
};

export default TestModeIndicator;
