import React from "react";
import BrentoTextField from "../../../UI/BrentoTextField/BrentoTextField";
import { FormLabel } from "@mui/material";

const RecipientInfoFields = ({ formik, moment }) => {
  return (
    <div className="gift-card-checkout__field-group">
      <h2 className="header-5">Recipient information</h2>
      <p className="body-2">
        Complete the fields below to let us know who you'd like us to send the
        card to, when we send it, and a special message that you would like to
        include in the email.
      </p>
      <div className="gift-card-checkout__field">
        <FormLabel>Where should we send the gift card?</FormLabel>
        <BrentoTextField
          fullWidth={true}
          label="Recipient email address"
          value={formik.values.recipientEmail}
          name="recipientEmail"
          onChange={formik.handleChange}
          type="email"
          error={
            formik.touched.recipientEmail && formik.errors.recipientEmail
              ? true
              : false
          }
          helperText={
            formik.touched.recipientEmail && formik.errors.recipientEmail
          }
        ></BrentoTextField>
      </div>
      <div className="gift-card-checkout__field">
        <FormLabel>When should we send the email?</FormLabel>
        <BrentoTextField
          fullWidth={true}
          name="recipientDeliveryDate"
          label="Delivery Date"
          type="date"
          inputProps={{ min: moment(Date.now()).format("YYYY-MM-DD") }}
          variant="outlined"
          value={formik.values.recipientDeliveryDate}
          onChange={formik.handleChange}
          margin="normal"
          error={
            formik.touched.recipientDeliveryDate &&
            formik.errors.recipientDeliveryDate
              ? true
              : false
          }
          helperText={
            formik.touched.recipientDeliveryDate &&
            formik.errors.recipientDeliveryDate
          }
        />
      </div>
      <div className="gift-card-checkout__field">
        <FormLabel>
          Add a special message to be included in the email sent to the above
          address:
        </FormLabel>
        <BrentoTextField
          value={formik.values.specialMessage}
          name="specialMessage"
          onChange={formik.handleChange}
          fullWidth={true}
          multiline={true}
          rows={6}
          error={
            formik.touched.specialMessage && formik.errors.specialMessage
              ? true
              : false
          }
          helperText={
            formik.touched.specialMessage && formik.errors.specialMessage
          }
        />
        <p className="character-count">{`${formik.values.specialMessage.length}/250 characters remaining`}</p>
      </div>
    </div>
  );
};

export default RecipientInfoFields;
